import React from 'react';
import { css } from 'styled-components/macro';
import { useStoreState } from 'app/state/store/hooks';
import LinearProgress from '@material-ui/core/LinearProgress';
import { TabNavigation } from 'app/modules/search-results-module/common/TabNav';
import { TabContent } from 'app/modules/search-results-module/common/TabContent';
import { SearchWarning } from 'app/modules/search-results-module/common/SearchWarning';

export const labelcss = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(61, 61, 61, 0.6);

  > svg {
    margin-right: 10px;
  }
`;

interface TabContainerProps {
  loading: boolean;
  resultType: string;
  setResultType: (type: string) => void;
}

export function TabContainer(props: TabContainerProps) {
  const searchTerm = useStoreState((state) => state.syncSearch.value);

  return (
    <React.Fragment>
      {searchTerm.length > 3 && (
        <React.Fragment>
          <TabNavigation
            resultType={props.resultType}
            setResultType={props.setResultType}
          />
          <LinearProgress
            css={`
              && {
                display: ${props.loading ? 'block' : 'none'};
              }
            `}
          />
          <TabContent selected={props.resultType} />
        </React.Fragment>
      )}
      {searchTerm.length < 4 && <SearchWarning />}
    </React.Fragment>
  );
}
