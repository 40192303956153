import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { SearchField } from 'app/components/Filter/common/Search/common/SearchField';
import { SearchComponentLayoutProps } from 'app/components/Filter/common/Search/data';
import { SearchResults } from 'app/components/Filter/common/Search/common/SearchResults';
import {
  containercss,
  linearprogresscss,
} from 'app/components/Filter/common/Search/style';

export function SearchComponentLayout(props: SearchComponentLayoutProps) {
  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={props.onClickAway}>
      <div ref={props.targetRef} css={containercss}>
        <SearchField
          value={props.value}
          setValue={props.setValue}
          onEnterPress={props.onEnterPress}
        />
        <div css={linearprogresscss(props.width, props.loading)}>
          <LinearProgress />
        </div>
        {props.value.length > 3 && !props.stayOpen && (
          <SearchResults
            results={props.searchData}
            resultType={props.resultType}
            width={props.width}
            handleLoadMore={props.loadMore}
            hasMoreOfType={props.hasMoreOfType}
            handleResultClick={props.close}
            changeResultType={props.setResultType}
          />
        )}
      </div>
    </ClickAwayListener>
  );
}
