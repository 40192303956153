import { css } from 'styled-components/macro';
import { createTheme } from '@material-ui/core/styles';

export const createStyles = (fromActive: boolean, toActive: boolean) => {
  return {
    container: css`
      width: 100%;
    `,
    formControl: css`
      display: flex;
      flex-direction: column;
      width: 100%;
    `,
    label: css`
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      line-height: 15px;
      color: rgba(61, 61, 61, 0.6);
      margin-bottom: 4px;
    `,
    from: css`
      border-radius: 32px;
      background-color: #ededf6;
      margin-right: 16px;
      height: 36px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #2e4063;
      text-transform: none;
      box-shadow: none;

      justify-content: flex-start;
      border: ${fromActive ? '1px solid #2E4063' : 'unset'};

      :hover {
        box-shadow: none;
        background-color: #ededf6;
        border: 1px solid #2e4063;
      }
    `,
    to: css`
      border-radius: 32px;
      background-color: #ededf6;
      height: 36px;
      justify-content: flex-start;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #2e4063;
      text-transform: none;
      box-shadow: none;
      border: ${toActive ? '1px solid #2E4063' : 'unset'};

      :hover {
        box-shadow: none;
        background-color: #ededf6;
        border: 1px solid #2e4063;
      }
    `,
    inputContainer: css`
      margin-top: 4px;
      display: flex;
      justify-content: flex-start;
      width: 100%;
    `,
    calendar: css`
      //border: 4px solid red;

      && .MuiPickersToolbar-toolbar {
        display: none;
      }
    `,
    reset: css`
      margin: -16px -12px -12px auto;
      float: right;
      color: #2e4063;
    `,
  };
};

export const theme = createTheme({
  overrides: {
    // @ts-ignore
    MuiPickersBasePicker: {
      pickerView: {
        width: '100%',
      },
    },
    MuiPickersYear: {
      modeDesktop: {
        flexBasis: '14%',
      },
      yearButton: {
        width: '48px',
        height: '48px',
        margin: '7.5px 0',
        borderRadius: '52px',
        color: '#2E4063',
        fontFamily: 'Inter',
        fontSize: '14px',
      },
    },
    MuiTypography: {
      subtitle1: {
        fontSize: '14px',
        fontWeight: 'bold',
        lineHeight: '20px',
      },
    },
    MuiPickersCalendarHeader: {
      root: {
        paddingLeft: 'unset',
      },
      monthTitleContainer: {
        alignItems: 'center',
        marginRight: 'unset',
        minWidth: '150px',
      },
      yearSelectionSwitcher: {
        marginLeft: '10px',
      },
    },
    MuiSvgIcon: {
      root: {
        fill: '#2E4063',
      },
    },
    MuiPickersCalendarView: {
      viewTransitionContainer: {
        '&::-webkit-scrollbar': {
          width: '4px',
          background: '#ededf6',
        },
        '&::-webkit-scrollbar-track': {
          borderRadius: '4px',
          background: '#ededf6',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '4px',
          background: '#2e4063',
        },
      },
    },
    MuiPickersArrowSwitcher: {
      previousMonthButtonMargin: {
        marginRight: '12px',
        marginLeft: '12px',
      },
    },
    MuiPickersCalendar: {
      root: {
        minHeight: '255px',
        '&::-webkit-scrollbar': {
          width: '4px',
          background: '#ededf6',
        },
        '&::-webkit-scrollbar-track': {
          borderRadius: '4px',
          background: '#ededf6',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '4px',
          background: '#2e4063',
        },
        // overflowX: 'visible',
      },
      daysHeader: {
        justifyContent: 'space-between',
      },
      week: {
        justifyContent: 'space-between',
      },
      weekDayLabel: {
        width: '48px',
        height: '48px',
        fontFamily: 'Inter',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#2E4063',
      },
    },
    MuiPickersDay: {
      root: {
        width: '48px',
        height: '48px',
        fontFamily: 'Inter',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#2E4063',
        '&:focus&$selected': {
          backgroundColor: '#2E4063',
        },
        '&$selected': {
          backgroundColor: '#2E4063',
        },
        '&$selected&:hover': {
          backgroundColor: '#2E4063',
        },
      },
    },
    Mui: {
      root: {
        '&$selected': {
          backgroundColor: '#2E4063',
        },
      },
    },
  },
});
