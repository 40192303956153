import React, { FunctionComponent, PropsWithChildren } from 'react';
import { components } from 'react-select';
import { css } from 'styled-components/macro';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

interface LabelProps extends PropsWithChildren<any> {
  marginLeft?: number;
}
export const Label: FunctionComponent<LabelProps> = (props: LabelProps) => (
  <label
    css={css`
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      display: flex;
      align-items: center;
      letter-spacing: 0.4px;
      color: #626262;
      margin-left: ${props.marginLeft}px;
    `}
  >
    {props.children}
  </label>
);

export const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <Checkbox
        id="checkOption"
        color="default"
        checked={props.isSelected}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
      <label htmlFor="checkOption">{props.label}</label>
    </components.Option>
  );
};

export const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDown htmlColor="#828894" fontSize="small" />
    </components.DropdownIndicator>
  );
};

export const MultiValueRemove = (props: any) => {
  return (
    <components.MultiValueRemove {...props}>
      <CloseIcon htmlColor="white" fontSize="inherit" />
    </components.MultiValueRemove>
  );
};

export const customStyles = {
  control: () => ({
    // none of react-select's styles are passed to <Control />
    display: 'flex',
    background: '#ededf6',
    borderRadius: 18,
    heigth: 36,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: 6,
  }),
  input: (provided: any) => ({
    ...provided,
    padding: '0px 8px',
    margin: 0,
    paddingBottom: 0,
    paddingTop: 0,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    marginLeft: 10,
  }),
  multiValue: () => ({
    display: 'flex',
    borderRadius: 12,
    background: '#30C2B0',
    color: 'white',
    margin: 2,
    paddingLeft: 4,
    paddingRight: 4,
  }),
  multiValueLabel: () => ({
    color: 'white',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    color: 'white',
    ':hover': {
      color: 'white',
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: 'transparent',
    color: '#2E4063',
    cursor: 'default',
    display: 'block',
    fontSize: 'inherit',
    padding: '8px 15px',
    width: '100%',
    boxSizing: 'border-box',
    ':hover': {
      ...provided[':hover'],
      //   backgroundColor: '#2E4063',
      //   color: 'white',
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: 16,
    boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.12)',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  singleValue: (provided: any) => ({
    ...provided,
    backgroundColor: 'transparent',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.25px',
    color: '#2E4063',
    marginLeft: '10px',
    textTransform: 'capitalize',
  }),
};
