import React from 'react';
import 'styled-components/macro';
import Tooltip from '@material-ui/core/Tooltip';
import InputBase from '@material-ui/core/InputBase';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { SearchWarning } from 'app/modules/search-results-module/common/SearchWarning';

type Props = {
  value: string;
  setValue: Function;
  onEnterPress?: Function;
};

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const StyledInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      fontSize: 14,
      width: '100%',
      borderRadius: 8,
      color: '#2E4063',
      fontWeight: 'bold',
      padding: '8px 12px',
      backgroundColor: '#ededf6',
    },
  })
)(InputBase);

export const SearchField = (props: Props) => {
  function onKeyDown(
    event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    if (props.onEnterPress) {
      let isEnterPressed = false;
      if (event.key !== undefined) {
        isEnterPressed = isEnterPressed || event.key === 'Enter';
      } else if (event['keyCode'] !== undefined) {
        // todo: keyCode is deprecated replace with currentday alternative
        isEnterPressed = isEnterPressed || event['keyCode'] === 13;
      }
      if (isEnterPressed) {
        props.onEnterPress();
      }
    }
  }

  const showTooltip = props.onEnterPress && props.value.length < 4;

  return (
    <LightTooltip
      open={showTooltip}
      disableHoverListener
      disableFocusListener
      disableTouchListener
      placement="bottom-start"
      title={<SearchWarning />}
    >
      <StyledInput
        fullWidth
        autoFocus
        value={props.value}
        onKeyDown={onKeyDown}
        data-cy="search-field"
        placeholder="Search for..."
        onChange={(e) => props.setValue(e.target.value)}
      />
    </LightTooltip>
  );
};
