/// <reference types="styled-components/cssprop" />

import React from 'react';
import ReactDOM from 'react-dom';
import { App } from 'app';
import 'index.css';
import '../node_modules/react-vis/dist/style.css';

import reportWebVitals from 'reportWebVitals';

import { unregister } from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

unregister();
reportWebVitals();
