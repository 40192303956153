import { emailValidation } from 'app/utils/emailValidation';
import axios, { AxiosResponse, AxiosError } from 'axios';
import React from 'react';

export const useEmailsignup = () => {
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');

  function handleSubmit() {
    if (!emailValidation(email)) {
      setMessage('Please provide a valid email address');
    } else {
      axios
        .post(
          `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_HUB_PORTAL_ID}/${process.env.REACT_APP_HUB_WEBSHOP_NEWSLETTER_FORM_ID}`,
          {
            portalId: process.env.REACT_APP_HUB_PORTAL_ID,
            formGuid: process.env.REACT_APP_HUB_WEBSHOP_NEWSLETTER_FORM_ID,
            fields: [
              {
                name: 'email',
                value: email,
              },
            ],
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response: AxiosResponse) => {
          if (response.status === 200) {
            setEmail('');
            setMessage('Thank you for subscribing!');
          } else {
            setMessage('Something went wrong, please try again later');
          }
        })
        .catch((error: AxiosError) => {
          console.log(error.response);
          setMessage('Something went wrong, please try again later');
        });
    }
  }
  return { handleSubmit, message, email, setEmail, setMessage };
};
