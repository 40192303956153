import { css } from 'styled-components/macro';

interface Props {
  newsLetterDialogState: boolean;
}
import Bg from './asset/newsLetterbg.svg';
export const modalOverlaycss = (newsLetterDialogState: boolean) => css`
  position: fixed;
  left: 0;
  /* bottom: 0; */
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.1);
  /* opacity: 0.1; */
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  display: ${newsLetterDialogState ? 'block' : 'none'};
`;

export const subscribeButtoncss = css`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  border-radius: 0px 25px 25px 0px;
  font-weight: 700;
  color: #f7f7f7;
  font-size: 14px;
  background: inherit;
`;

export const modalCardcss = (newsLetterDialogState: boolean) => css`
  width: 73.5vw;
  /* width: 1232px; */
  /* height: 240px; */
  border-radius: 16px;
  background: #fff;
  display: flex;
  align-items: center;
  gap: 4rem;
  /* justify-content: space-between; */
  overflow: hidden;
  transition: bottom ease 0.5s;
  position: fixed;
  left: 13.25vw;
  padding-right: 3rem;

  /* top: 11vw; */

  bottom: ${newsLetterDialogState ? '8vh' : '-100vh'};
  z-index: 10001;

  @media (max-width: 800px) {
    width: 70.2vw;
    left: 14.9vw;
  }
  @media (max-width: 600px) {
    width: 83vw;
    left: 8.5vw;
  }

  > div:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0% 3%;
    position: relative;
    width: 50%;
    background-image: url(${Bg});
    /* background-color: pink; */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 1rem;
    overflow-y: auto;
    height: 100%;

    /* background-position: center; */
    @media (max-width: 800px) {
      padding: 1% 3%;
    }
    .logo {
      position: absolute;
      top: 1rem;
      left: 2rem;
    }
    /* .newsletterimg {
      svg {
        margin-top: 1rem;
      }
    } */
  }
  > div:nth-child(2) {
    .close {
      position: absolute;
      right: 1rem;
      top: 1rem;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 36px;
    text-align: center;
    color: #2e4063;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    @media (max-width: 600px) {
      font-size: 28px;
    }
  }

  p {
    font-size: 18px;
    color: #2e4063;
    text-align: center;
    margin: 0;
    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  input {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    border-radius: 25px 0px 0px 25px;
    padding: 0 1.1rem;
    background: inherit;
  }

  /* button {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    border-radius: 0px 25px 25px 0px;
    font-weight: 700;
    color: #f7f7f7;
    font-size: 14px;
    background: inherit;
  } */
`;
