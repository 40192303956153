import React from 'react';
import 'styled-components/macro';
import IconSearch from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';

type Props = {
  setValue: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
};

const StyledInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      fontSize: 12,
      color: theme.palette.common.black,
      '&::placeholder': {
        textOverflow: 'ellipsis !important',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '12px',
        letterSpacing: '0px',
        // color:  '#626262'
        color: '#000000',
      },
    },
  })
)(InputBase);

export const SearchField = (props: Props) => {
  return (
    <div
      css={`
        width: 100%;
        border-radius: 20px;
        height: 36px;
        display: flex;
        padding-left: 16px;
        padding-right: 16px;
        align-items: center;
        justify-content: space-between;
        background-color: #ededf6;
      `}
    >
      <StyledInput
        data-cy="search-field"
        // autoFocus
        fullWidth
        onChange={props.setValue}
        inputProps={{
          id: 'filter-search-input',
        }}
        // inputProps={{ classes: {input: this.props.classes['input']} }}
        placeholder="Search for..."
      />
      <IconSearch
        css={`
          width: 24px;
          height: 24px;
          font-weight: bold;
          color: #2e4063;
        `}
      />
    </div>
  );
};
