/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import get from 'lodash/get';
import find from 'lodash/find';
import { Path } from 'app/const/Path';
import { useAuth0 } from '@auth0/auth0-react';
import { formatLocale } from 'app/utils/formatLocale';
import { PricingPlanModel } from 'app/interfaces/User';
import { createTheme } from '@material-ui/core/styles';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { AddCircleOutline, DeleteOutlined } from '@material-ui/icons';
import { useStoreActions, useStoreState } from 'app/state/store/hooks';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { LinkCell } from 'app/components/Charts/table/common/cells/LinkCell';
import { BookmarkButton } from 'app/components/Filter/common/BookmarkButton';
import { ExpandableRows } from 'app/components/Charts/table/common/rows/ExpandableRows';
import { MultiValuesCell } from 'app/components/Charts/table/common/cells/MultiValuesCell';
import {
  ClickAwayListener,
  IconButton,
  Popper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  MUIDataTableOptions,
  MUIDataTableColumnDef,
  MUIDataTableIsRowCheck,
  DisplayData,
  MUIDataTableMeta,
  MUIDataTableExpandButton,
} from 'mui-datatables';

type RenderCustomComponent<P> = (props: P) => React.ReactNode;

export interface DataTableProps {
  options: MUIDataTableOptions;
  title: string | React.ReactNode;
  columns: MUIDataTableColumnDef[];
  data: TableData;
  components?: {
    ExpandButton:
      | RenderCustomComponent<MUIDataTableExpandButton>
      | React.ReactNode;
  };
}

export type TableData = Array<object | number[] | string[]>;

export const tableTheme = createTheme({
  overrides: {
    MuiPaper: {
      root: {
        width: '100%',
      },
    },
    MuiToolbar: {
      root: {
        padding: '0px !important',
        display: 'flex !important',
        '@media (max-width:600px)': {
          justifyContent: 'space-between',
          '& > div': {
            flex: '0 0 auto',
          },
        },
      },
    },
    MuiTypography: {
      h6: {
        fontWeight: 600,
        color: '#2E4063',
        fontFamily: 'Inter',
        fontSize: '20px',
      },
      body2: {
        fontSize: '12px',
        fontFamily: 'Inter',
        color: '#b6b6b6 !important',
      },
    },
    MuiSvgIcon: {
      root: {
        fill: '#2E4063',
      },
    },
    MuiTableBody: {
      root: {
        backgroundColor: '#fff',
        fontFamily: 'Inter',
      },
    },
    MuiTableCell: {
      root: {
        fontFamily: 'Inter !important',
      },
      head: {
        padding: '10px 16px',
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '1rem',
        borderTop: '2px solid #ebebeb',
        borderBottom: '2px solid #ebebeb',
        whiteSpace: 'nowrap',
        '&:first-of-type': {
          paddingLeft: '0px !important',
        },
      },
      body: {
        fontFamily: 'Inter',
        color: '#2E4063',
        fontSize: '14px',
        '&:first-of-type': {
          paddingLeft: '0px !important',
        },
      },
      footer: {
        borderBottomStyle: 'none',
        '@media (max-width:600px)': {
          padding: '0px !important',
        },
      },
    },
    MuiButton: {
      root: {
        fontFamily: 'Inter !important',
      },
    },
    MuiInputBase: {
      input: {
        color: '#b6b6b6',
      },
    },
    MuiTablePagination: {
      selectIcon: {
        fill: '#b6b6b6',
      },
    },
    MuiPopover: {
      paper: {
        width: 'fit-content',
      },
    },
    MUIDataTableHeadCell: {
      root: {
        '&:last-of-type': {
          width: '150px',
        },
      },
      data: {
        color: 'rgba(61, 61, 61, 0.6)',
        fontWeight: 'bold',
      },
      sortActive: {
        color: 'rgba(61, 61, 61, 0.6)',
        fontWeight: 'bold',
      },
      sortAction: {
        alignItems: 'center',
      },
      contentWrapper: {
        display: 'inline-flex',
      },
    },
    MUIDataTableToolbar: {
      actions: {
        display: 'flex',
        flexDirection: 'row',
        flex: 'initial',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
      },
    },
    MuiDialog: {
      paper: {
        maxWidth: '496px !important',
        borderRadius: '16px',
      },
    },
    MuiDialogTitle: {
      root: {
        paddingTop: '8px !important',
        marginTop: '0px !important',
      },
    },
    MUIDataTableSelectCell: {
      expandDisabled: {
        // Soft hide the button.
        visibility: 'hidden',
      },
    },
  },
});

const DATE_MISSING = 'Date missing';

// Activities

export const ActivitiesDataTableColumns: MUIDataTableColumnDef[] = [
  {
    name: 'Planned start date',
    options: {
      setCellProps: () => ({ style: { minWidth: '120px', maxWidth: '150px' } }),
      customBodyRender: (value) => {
        return <>{value || DATE_MISSING}</>;
      },
    },
  },
  {
    name: 'Planned end date',
    options: {
      setCellProps: () => ({ style: { minWidth: '120px', maxWidth: '150px' } }),
      customBodyRender: (value) => {
        return <>{value || DATE_MISSING}</>;
      },
    },
  },
  { name: 'Status' },
  {
    name: 'Activity title',
    options: {
      customBodyRender: (value) => {
        const link = Path.detail.activity.replace(
          ':param',
          encodeURIComponent(value[0])
        );
        return <LinkCell link={link} value={value[1]} />;
      },
    },
  },
  {
    name: 'Country(s)',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        const updValue = typeof value === 'object' ? value : [];
        return <MultiValuesCell value={updValue} />;
      },
    },
  },
  {
    name: '',
    options: {
      customHeadLabelRender: () => '',
      customBodyRender: (value, tableMeta) => {
        return (
          <BookmarkButton
            overridePath={`/app/activity/${tableMeta.rowData[3][0]}`}
            overrideName={tableMeta.rowData[3][1]}
          />
        );
      },
    },
  },
];

export const ActivitiesDataTableOptions: MUIDataTableOptions = {
  print: true,
  elevation: 0,
  search: true,
  filter: false,
  download: true,
  rowHover: false,
  pagination: true,
  viewColumns: true,
  fixedHeader: false,
  responsive: 'standard',
  selectableRows: 'none',
  selectableRowsHeader: false,
};

// Countries

export const CountriesDataTableColumns = (
  transactionTypeName: string
): MUIDataTableColumnDef[] => [
  {
    name: 'Code',
  },
  {
    name: 'Country',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        const link = `${Path.detail.country.replace(
          ':param',
          encodeURIComponent(value[1])
        )}/projects`;
        return <LinkCell link={link} value={value[1]} />;
      },
    },
  },
  {
    name: transactionTypeName,
    options: {
      customBodyRender: (value) => (
        <span>{new Intl.NumberFormat('nl-NL').format(value)}</span>
      ),
    },
  },
];

export const CountriesDataTableOptions: MUIDataTableOptions = {
  print: true,
  elevation: 0,
  search: true,
  filter: false,
  download: true,
  rowHover: false,
  pagination: true,
  viewColumns: true,
  responsive: 'standard',
  selectableRows: 'none',
  selectableRowsHeader: false,
};

// Organisations

export const OrganisationsDataTableColumns: MUIDataTableColumnDef[] = [
  { name: 'IATI ref' },
  {
    name: 'Organisation',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        const label = value[1] === '' ? value[0] : value[1];
        const link = `${Path.detail.organisation.replace(
          ':param',
          encodeURIComponent(value[0])
        )}/projects`;
        return <LinkCell link={link} value={label} />;
      },
    },
  },
  {
    name: 'Budget',
    options: {
      customBodyRender: (value) => <span>{formatLocale(value as number)}</span>,
    },
  },
];

export const OrganisationsDataTableOptions: MUIDataTableOptions = {
  print: true,
  elevation: 0,
  search: true,
  filter: false,
  download: true,
  rowHover: false,
  pagination: true,
  viewColumns: true,
  responsive: 'standard',
  selectableRows: 'none',
  selectableRowsHeader: false,
};

// Donors

export const DonorsDataTableColumns: MUIDataTableColumnDef[] = [
  {
    name: 'IATI ref',
  },
  {
    name: 'Donor',
    options: {
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        const label = value[1] === '' ? value[0] : value[1];
        const link = `${Path.detail.donor.replace(
          ':param',
          encodeURIComponent(value[0])
        )}/projects`;
        return <LinkCell link={link} value={label} />;
      },
    },
  },
  {
    name: 'Budget',
    options: {
      customBodyRender: (value) => <span>{formatLocale(value as number)}</span>,
    },
  },
];

export const DonorsDataTableOptions: MUIDataTableOptions = {
  print: true,
  elevation: 0,
  search: true,
  filter: false,
  download: true,
  rowHover: false,
  pagination: true,
  viewColumns: true,
  responsive: 'standard',
  selectableRows: 'none',
  selectableRowsHeader: false,
};

// Publishers

export const PublishersDataTableColumns = (
  transactionTypeName: string
): MUIDataTableColumnDef[] => [
  { name: 'IATI ref' },
  {
    name: 'Publisher',
    options: {
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        const label = value[1] === '' ? value[0] : value[1];
        const link = `${Path.detail.publisher.replace(
          ':param',
          encodeURIComponent(value[0])
        )}/projects`;
        return <LinkCell link={link} value={label} />;
      },
    },
  },
  {
    name: transactionTypeName,
    options: {
      customBodyRender: (value) => <span>{formatLocale(value as number)}</span>,
    },
  },
];

export const PublishersDataTableOptions: MUIDataTableOptions = {
  print: true,
  elevation: 0,
  search: true,
  filter: false,
  download: true,
  rowHover: false,
  pagination: true,
  viewColumns: true,
  responsive: 'standard',
  selectableRows: 'none',
  selectableRowsHeader: false,
};

// Sectors

export const SectorsDataTableColumns: MUIDataTableColumnDef[] = [
  {
    name: 'code',
    label: 'Code',
    options: { sort: false },
  },
  {
    name: 'title',
    label: 'Category / DAC3 / DAC5',
    options: {
      sort: false,
    },
  },
  {
    name: 'size',
    label: 'Budget',
    options: {
      sort: false,
      customBodyRender: (value) => <span>{formatLocale(value as number)}</span>,
    },
  },
  {
    name: 'sum',
    label: 'Sum of budgets',
    options: {
      sort: false,
      customBodyRender: (value) => <span>{formatLocale(value as number)}</span>,
    },
  },
];

export const SectorsDataTableOptions: MUIDataTableOptions = {
  print: true,
  elevation: 0,
  search: true,
  filter: false,
  download: true,
  rowHover: true,
  serverSide: true,
  pagination: true,
  viewColumns: true,
  expandableRows: true,
  responsive: 'standard',
  selectableRows: 'none',
  expandableRowsHeader: false,
  selectableRowsHeader: false,
  expandableRowsOnClick: true,
  renderExpandableRow: (
    rowData: string[],
    rowMeta: { dataIndex: number; rowIndex: number }
  ) => {
    // @ts-ignore
    const childData = SectorsDataTableMockData[rowMeta.rowIndex].children;
    if (childData) {
      return <ExpandableRows data={childData} level={1} />;
    }
  },
  isRowExpandable: (dataIndex: number, expandedRows?: MUIDataTableIsRowCheck) =>
    // @ts-ignore
    SectorsDataTableMockData[dataIndex].children,
};

// Org members

const MemberRoleDropdownComp = (props: {
  userId: string;
  value: 'Admin' | 'Member';
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const changeUserRoleAction = useStoreActions(
    (actions) => actions.changeUserRole.authPostFetch
  );
  const roles = useStoreState((state) => get(state.roles, 'data.items', []));
  const isAdmin = useStoreState(
    (reduxState) =>
      get(reduxState.user.data, 'user.app_metadata.role', '') === 'Admin'
  );

  return (
    <div
      css={`
        width: 100px;
      `}
    >
      <button
        css={`
          width: 100%;
          padding: 7px 0;
          text-align: start;
          border-style: none;
          position: relative;
          background-color: transparent;
          cursor: ${isAdmin ? 'pointer' : 'default'};

          > svg {
            top: 3px;
            right: 3px;
            position: absolute;

            > path {
              fill: #2e4063;
            }
          }
        `}
        type="button"
        onClick={isAdmin ? handleClick : undefined}
      >
        <Typography
          variant="h5"
          css={`
            color: #2e4063;
            font-size: 14px;
            font-weight: bold;
          `}
        >
          {props.value}
        </Typography>
        {isAdmin && open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </button>
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <div
            css={`
              width: 100px;
              padding: 7px 0;
              cursor: pointer;
              font-size: 14px;
              background: #fff;
              font-weight: bold;
              border-radius: 8px;
              text-align: center;
              box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.12);
            `}
            onClick={() => {
              const newValue = props.value === 'Admin' ? 'Member' : 'Admin';
              const roleId = get(find(roles, { name: newValue }), '_id', '');
              if (roleId) {
                getAccessTokenSilently().then((token: string) => {
                  changeUserRoleAction({
                    values: {
                      token,
                      userId: props.userId,
                      roleId,
                      roleName: newValue,
                    },
                  });
                  setAnchorEl(null);
                });
              }
            }}
          >
            {props.value === 'Admin' ? 'Member' : 'Admin'}
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

const MemberRowDeleteComp = (props: {
  userId: string;
  handleRemoveUsers: (value: string[]) => void;
}) => {
  return (
    <IconButton
      aria-label="delete"
      id="delete-member-button"
      css={`
        visibility: hidden;
      `}
      onClick={() => {
        props.handleRemoveUsers([props.userId]);
      }}
    >
      <DeleteOutlined />
    </IconButton>
  );
};

export function OrgMembersColumns(props: {
  handleRemoveUsers: (value: string[]) => void;
}): MUIDataTableColumnDef[] {
  return [
    {
      name: 'user_id',
      options: {
        display: undefined,
      },
    },
    {
      name: 'email',
      options: {
        display: undefined,
      },
    },
    {
      name: 'picture',
      options: {
        display: undefined,
      },
    },
    {
      name: 'name',
      label: '',
      options: {
        setCellProps: () => ({
          style: { minWidth: '120px', maxWidth: '250px' },
        }),
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
          const { rowData } = tableMeta;
          return (
            <div
              css={`
                gap: 10px;
                display: flex;
                flex-direction: row;
                align-items: center;
              `}
            >
              {rowData[2] ? (
                <img
                  alt="user"
                  width={48}
                  height={48}
                  src={rowData[2]}
                  css={`
                    border-radius: 50%;
                  `}
                />
              ) : (
                <div
                  css={`
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    background: #a1aebd;
                  `}
                />
              )}
              <div
                css={`
                  gap: 5px;
                  display: flex;
                  flex-direction: column;

                  h5 {
                    font-size: 14px;

                    &:first-child {
                      font-weight: bold;
                    }
                  }
                `}
              >
                <div
                  css={`
                    gap: 8px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  `}
                >
                  <Typography variant="h5">{rowData[3]}</Typography>
                  {!rowData[4] && (
                    <div
                      css={`
                        color: #a1aebd;
                        font-size: 12px;
                        padding: 3px 10px;
                        border-radius: 31px;
                        border: 1px solid #a1aebd;
                      `}
                    >
                      Pending
                    </div>
                  )}
                </div>
                <Typography variant="h5">{rowData[1]}</Typography>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: 'email_verified',
      options: {
        display: undefined,
      },
    },
    {
      name: 'role',
      label: '',
      options: {
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
          const { rowData } = tableMeta;
          return (
            <div
              css={`
                display: flex;
                flex-direction: row;
                justify-content: center;
              `}
            >
              <MemberRoleDropdownComp value={value} userId={rowData[0]} />
            </div>
          );
        },
      },
    },
    {
      name: '',
      label: '',
      options: {
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
          const { rowData } = tableMeta;
          return (
            <div
              css={`
                display: flex;
                flex-direction: row;
                justify-content: center;
              `}
            >
              <MemberRowDeleteComp
                userId={rowData[0]}
                handleRemoveUsers={props.handleRemoveUsers}
              />
            </div>
          );
        },
      },
    },
  ];
}

interface OrgMembersOptionsProps {
  data: any;
  handleAddClick: () => void;
  pricingPlan?: PricingPlanModel;
  handleSearchChange: (value: string) => void;
  handleRemoveUsers: (userIds: string[]) => void;
}

function OrgMembersToolbar(props: OrgMembersOptionsProps) {
  let errorText = '';
  if (!props.pricingPlan || props.pricingPlan.name === 'Free') {
    errorText =
      'Free plan only available for 1 user, if you have more than one user in your organisation please upgrade to Standard plan or Premium plan';
  } else if (
    props.pricingPlan &&
    props.pricingPlan.licenses === props.data.length
  ) {
    errorText =
      'You have reached the maximum number of users on your plan. Please upgrade your plan to add more users';
  }
  return (
    <Tooltip title={errorText.length > 0 ? errorText : 'Add user'}>
      <span>
        <IconButton
          disabled={errorText.length > 0}
          aria-label="Add user to organisation"
          style={{ order: -1, opacity: errorText.length > 0 ? 0.5 : 1 }}
          onClick={() => props.handleAddClick()}
        >
          <AddCircleOutline />
        </IconButton>
      </span>
    </Tooltip>
  );
}

export function OrgMembersOptions(
  props: OrgMembersOptionsProps
): MUIDataTableOptions {
  return {
    search: true,
    print: false,
    elevation: 0,
    filter: false,
    download: false,
    rowHover: true,
    serverSide: true,
    pagination: true,
    viewColumns: false,
    responsive: 'standard',
    sort: false,
    // onRowsDelete: (
    //   rowsDeleted: {
    //     lookup: { [dataIndex: number]: boolean };
    //     data: Array<{ index: number; dataIndex: number }>;
    //   },
    //   newTableData: any[]
    // ) => {
    //   const userIds: string[] = [];
    //   selectedRows.data.forEach((row) => {
    //     const user = props.data[row.dataIndex];
    //     if (user && user.user_id) {
    //       userIds.push(user.user_id);
    //     }
    //   });
    //   if (files.length > 0) {
    //     props.handleRemoveUsers(userIds);
    //   }
    // },
    customToolbar: () => <OrgMembersToolbar {...props} />,
    customToolbarSelect: (
      selectedRows: {
        data: Array<{ index: number; dataIndex: number }>;
        lookup: { [key: number]: boolean };
      },
      displayData: DisplayData,
      setSelectedRows: (rows: number[]) => void
    ) => {
      const userIds: string[] = [];
      selectedRows.data.forEach((row) => {
        const user = props.data[row.dataIndex];
        if (user && user.user_id) {
          userIds.push(user.user_id);
        }
      });
      return (
        <React.Fragment>
          <div>
            <Typography variant="h6">
              {selectedRows.data.length} member(s) selected
            </Typography>
          </div>
          <div>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={() => {
                  if (userIds.length > 0) {
                    props.handleRemoveUsers(userIds);
                    setSelectedRows([]);
                  }
                }}
              >
                <DeleteOutlined />
              </IconButton>
            </Tooltip>
          </div>
        </React.Fragment>
      );
    },
    onSearchChange: (value: string | null) =>
      props.handleSearchChange(value || ''),
  };
}
