/* eslint-disable no-param-reassign */
import { action, Action } from 'easy-peasy';

interface KeyValue {
  key: string;
  value: boolean;
}

export interface FiltersUpdatedModel {
  value: {
    countries: boolean;
    regions: boolean;
    sectors: boolean;
    donors: boolean;
    organisations: boolean;
    publishers: boolean;
    activitystatus: boolean;
    activityscope: boolean;
    documentlinkcategory: boolean;
    hierarchy: boolean;
    humanitarianscopevocab: boolean;
    humanitarianscope: boolean;
    iativersion: boolean;
    otheridentifiertype: boolean;
    tag: boolean;
    defaultaidtype: boolean;
    defaultaidtypecategory: boolean;
    defaultaidtypevocabulary: boolean;
    currency: boolean;
    defaultfinancetype: boolean;
    defaultflowtype: boolean;
    language: boolean;
    defaulttiedstatus: boolean;
    transactionreceiverorg: boolean;
    reportingorgtype: boolean;
    collaborationtype: boolean;
    sectorvocabulary: boolean;
    policymarker: boolean;
    transactionflowtype: boolean;
    transactiontiedstatus: boolean;
    transactiontype: boolean;
    transactionvaluecurrency: boolean;
  };
  setValue: Action<FiltersUpdatedModel, KeyValue>;
  clear: Action<FiltersUpdatedModel>;
}

const filtersUpdated: FiltersUpdatedModel = {
  value: {
    countries: false,
    regions: false,
    sectors: false,
    donors: false,
    organisations: false,
    publishers: false,
    activitystatus: false,
    activityscope: false,
    documentlinkcategory: false,
    hierarchy: false,
    humanitarianscopevocab: false,
    humanitarianscope: false,
    iativersion: false,
    otheridentifiertype: false,
    tag: false,
    defaultaidtype: false,
    defaultaidtypecategory: false,
    defaultaidtypevocabulary: false,
    currency: false,
    defaultfinancetype: false,
    defaultflowtype: false,
    language: false,
    defaulttiedstatus: false,
    transactionreceiverorg: false,
    reportingorgtype: false,
    collaborationtype: false,
    sectorvocabulary: false,
    policymarker: false,
    transactionflowtype: false,
    transactiontiedstatus: false,
    transactiontype: false,
    transactionvaluecurrency: false,
  },
  setValue: action((state, payload: KeyValue) => {
    //@ts-ignore
    state.value[payload.key] = payload.value;
  }),
  clear: action((state) => {
    state.value = {
      countries: false,
      regions: false,
      sectors: false,
      donors: false,
      organisations: false,
      publishers: false,
      activitystatus: false,
      activityscope: false,
      documentlinkcategory: false,
      hierarchy: false,
      humanitarianscopevocab: false,
      humanitarianscope: false,
      iativersion: false,
      otheridentifiertype: false,
      tag: false,
      defaultaidtype: false,
      defaultaidtypecategory: false,
      defaultaidtypevocabulary: false,
      currency: false,
      defaultfinancetype: false,
      defaultflowtype: false,
      language: false,
      defaulttiedstatus: false,
      transactionreceiverorg: false,
      reportingorgtype: false,
      collaborationtype: false,
      sectorvocabulary: false,
      policymarker: false,
      transactionflowtype: false,
      transactiontiedstatus: false,
      transactiontype: false,
      transactionvaluecurrency: false,
    };
  }),
};

export default filtersUpdated;
