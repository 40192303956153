import React from 'react';
import { Path } from 'app/const/Path';
import { useRecoilState } from 'recoil';
import { NavLink } from 'react-router-dom';
import { LogoAida } from 'app/assets/LogoAida';
import IconClose from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { useEmailsignup } from 'app/hooks/useEmailSignup';
import { NewsLetterDialogState } from 'app/state/recoil/atoms';
import { logolinkcss } from 'app/modules/web-shop/components/appbar/style';
import { ReactComponent as NewsLetterImg } from 'app/components/Dialogs/NewsLetterDialog/asset/newsLetterImg.svg';
import {
  modalCardcss,
  modalOverlaycss,
  subscribeButtoncss,
} from 'app/components/Dialogs/NewsLetterDialog/style';

export default function NewsLetterDialog() {
  const [dialogState, setDialogState] = useRecoilState(NewsLetterDialogState);
  const { email, handleSubmit, message, setEmail, setMessage } =
    useEmailsignup();

  return (
    <>
      <div
        onClick={() => setDialogState({ ...dialogState, open: false })}
        css={modalOverlaycss(dialogState.open)}
      />
      <div css={modalCardcss(dialogState.open)}>
        <div>
          <div className="logo">
            <NavLink to={Path.webshop.home} css={logolinkcss}>
              <IconButton edge="start" color="inherit" aria-label="Go to home">
                <LogoAida />
              </IconButton>
              <div
                css={`
                  font-weight: 700;
                  font-size: 31.0444px;
                `}
              >
                AIDA
              </div>
            </NavLink>
          </div>
          <div className="newsletterimg">
            <NewsLetterImg />
          </div>
        </div>

        <div>
          <IconButton
            className="close"
            aria-label="close"
            onClick={() => setDialogState({ ...dialogState, open: false })}
            data-cy="news-letter-dialog-closebtn"
          >
            <IconClose
              css={`
                fill: #2e4063;
              `}
            />
          </IconButton>
          <h2>Like Data emails?</h2>
          <p>Join our Newsletter for your daily dose of data!</p>
          <div
            css={`
              display: flex;
              /* justify-content: center; */
              width: 356px;
              height: 32px;
              background: #2e4063;
              border: 1.5px solid #2e4063;
              border-radius: 25px;
              margin: auto;
              margin-top: 1.3rem;
              @media (max-width: 600px) {
                width: 17.125rem;
              }
            `}
          >
            <div
              css={`
                background: #ffffff;
                border-radius: 25px 0px 0px 25px;
                flex-basis: 60%;
              `}
            >
              <input
                type="text"
                value={email}
                placeholder="Email Address"
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (message) {
                    setMessage('');
                  }
                }}
              />
            </div>
            <div
              css={`
                flex-basis: 40%;
                border-radius: 0px 25px 25px 0px;
                background: #2e4063;
              `}
            >
              <button
                type="button"
                onClick={handleSubmit}
                css={subscribeButtoncss}
              >
                Subscribe
              </button>
            </div>
          </div>
          <h5
            css={`
              text-align: center;
            `}
          >
            {message}
          </h5>
        </div>
      </div>
    </>
  );
}
