import React from 'react';
import find from 'lodash/find';
import { useRecoilState } from 'recoil';
import { FilterProps } from 'app/components/FilterCard';
import { filterCardTabState } from 'app/state/recoil/atoms';
import { MainFiltersCard } from 'app/components/FilterCard/MainFilters/common/MainFilterCard';
import { AlphabeticallyGroupedOptions } from 'app/components/FilterCard/MainFilters/common/AlphabeticallyGroupedOptions';

export function OrganisationsFilter(props: FilterProps) {
  const [tabs] = useRecoilState(filterCardTabState);
  const currentTabIndex = find(tabs, 'active')?.index;

  function renderOptions() {
    switch (currentTabIndex) {
      case 0:
        return {}; // TODO: recent
      case 1:
        return (
          <AlphabeticallyGroupedOptions
            {...props}
            key="DonorsFilter-container"
            shownOptions={props.shownOptions}
            selectedOptions={props.selectedOptions.donors}
            setSelectedOptions={(options: any) => {
              props.setSelectedOptions({
                ...props.selectedOptions,
                donors: options,
              });
            }}
          />
        );
      case 2:
        return (
          <AlphabeticallyGroupedOptions
            {...props}
            key="OrganisationsFilter-container"
            shownOptions={props.shownOptions}
            selectedOptions={props.selectedOptions.organisations}
            setSelectedOptions={(options: any) => {
              props.setSelectedOptions({
                ...props.selectedOptions,
                organisations: options,
              });
            }}
          />
        );
      case 3:
        return (
          <AlphabeticallyGroupedOptions
            {...props}
            key="PublishersFilter-container"
            shownOptions={props.shownOptions}
            selectedOptions={props.selectedOptions.publishers}
            setSelectedOptions={(options: any) => {
              props.setSelectedOptions({
                ...props.selectedOptions,
                publishers: options,
              });
            }}
          />
        );
      default:
        return {};
    }
  }

  let selectedOptions = props.selectedOptions.donors;
  let options = props.options.donors;

  if (currentTabIndex === 2) {
    selectedOptions = props.selectedOptions.organisations;
    options = props.options.organisations;
  } else if (currentTabIndex === 3) {
    selectedOptions = props.selectedOptions.publishers;
    options = props.options.publishers;
  }

  return (
    <MainFiltersCard
      {...props}
      organisations
      title="Organisations"
      currentTabIndex={currentTabIndex}
      selectedOptions={selectedOptions}
      options={options}
      allSelectedOptions={props.selectedOptions}
      setSelectedOptions={(opts: any) => {
        const updatedSelectedOptions = { ...props.selectedOptions };
        if (currentTabIndex === 1) {
          updatedSelectedOptions.donors = opts;
        } else if (currentTabIndex === 2) {
          updatedSelectedOptions.organisations = opts;
        } else {
          updatedSelectedOptions.publishers = opts;
        }
        props.setSelectedOptions(updatedSelectedOptions);
      }}
    >
      {renderOptions()}
    </MainFiltersCard>
  );
}
