import React from 'react';
import TextField from '@material-ui/core/TextField';

interface Props {
  label: string;
  value: string;
  helperText?: React.ReactNode;
  setValue: (value: string) => void;
  type: 'text' | 'email' | 'password';
}

export function OnboardingTextInput(props: Props) {
  return (
    <TextField
      fullWidth
      size="small"
      id={props.label}
      type={props.type}
      variant="outlined"
      label={props.label}
      value={props.value}
      helperText={props.helperText}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        props.setValue(event.target.value)
      }
    />
  );
}
