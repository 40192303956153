import * as React from 'react';

export function LogoAida(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) {
  return (
    <svg width={24} height={24} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1 6.161a6.01 6.01 0 00.023-.538c0-3.105-2.386-5.623-5.33-5.623S6.462 2.518 6.462 5.623c0 .181.008.36.024.536-3.406.092-6.14 2.7-6.163 5.913h23.38c-.023-3.27-2.856-5.916-6.348-5.916-.085 0-.17.002-.256.005z"
        fill="#2E4063"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.926 17.84a6.004 6.004 0 00-.024.537c0 3.106 2.387 5.623 5.33 5.623 2.945 0 5.331-2.517 5.331-5.623 0-.18-.008-.36-.023-.535 3.405-.092 6.139-2.7 6.162-5.914H.322c.024 3.271 2.857 5.916 6.349 5.916.085 0 .17-.002.255-.005z"
        fill="#2E4063"
      />
      <path
        d="M0 12.159h6.686l3.741-2.957 3.697 5.406 2.861-2.449H24"
        stroke="#fff"
        strokeWidth={2}
      />
    </svg>
  );
}

export function BigLogoAida(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) {
  return (
    <svg width="35" height="36" {...props}>
      <path
        fill="#2E4063"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.7395 9.12755C24.763 8.86534 24.7751 8.5996 24.7751 8.33088C24.7751 3.72986 21.2482 0 16.8975 0C12.5469 0 9.01995 3.72986 9.01995 8.33088C9.01995 8.59846 9.03188 8.86309 9.0552 9.12421C4.33327 9.25185 0.485564 12.6617 0 17.0133H9.00309L14.2586 12.8485L15.1005 12.1814L15.7058 13.0687L20.5397 20.155L23.9195 17.2545L24.2005 17.0133H24.5708H34.4474C33.9524 12.5776 29.9643 9.12051 25.1177 9.12051C24.991 9.12051 24.865 9.12287 24.7395 9.12755ZM34.3787 19.0133H24.9411L20.9944 22.4003L20.1466 23.1278L19.517 22.2049L14.6589 15.0832L9.97236 18.7971L9.69947 19.0133H9.35128H0.0649104C0.779094 23.2207 4.65466 26.4358 9.32853 26.4358C9.45479 26.4358 9.58047 26.4334 9.70553 26.4288C9.68203 26.6908 9.67001 26.9565 9.67001 27.225C9.67001 31.8261 13.1969 35.5559 17.5476 35.5559C21.8982 35.5559 25.4251 31.8261 25.4251 27.225C25.4251 26.9577 25.4132 26.6933 25.39 26.4324C29.9403 26.3092 33.6788 23.1381 34.3787 19.0133Z"
      />
    </svg>
  );
}
