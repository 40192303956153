import { APIModel } from 'app/state/api';
import { ApiCallModel } from 'app/state/api/interfaces';

// main
export const countries: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const regions: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const sectors: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const donors: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const organisations: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const publishers: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const activitystatus: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
// activity
export const activityscope: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const documentlinkcategory: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const hierarchy: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const humanitarianscopevocab: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const humanitarianscope: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const iativersion: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const otheridentifiertype: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const tag: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
// default
export const defaultaidtype: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const defaultaidtypecategory: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const defaultaidtypevocabulary: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const currency: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const defaultfinancetype: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const defaultflowtype: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const language: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const defaulttiedstatus: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const transactionreceiverorg: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const reportingorgtype: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const collaborationtype: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const sectorvocabulary: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const policymarker: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const transactionflowtype: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const transactiontiedstatus: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const transactiontype: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
export const transactionvaluecurrency: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/filter-group-options`),
};
