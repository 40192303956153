/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import get from 'lodash/get';
import { useRecoilState } from 'recoil';
import { Paper } from 'app/components/Paper';
import { TriangleIcon } from 'app/assets/TriangleIcon';
import { useLocation, useParams } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { getDetailPageFilter } from 'app/utils/getDetailPageFilter';
import { useStoreState, useStoreActions } from 'app/state/store/hooks';
import {
  tabHasActiveMoreFilter,
  tabHasFilterActive,
} from 'app/components/Filter/common/utils';
import { getAPIFormattedFilters } from 'app/utils/getAPIFormattedFilters';
import {
  filterPopupAtom,
  selectedFilterAtom,
  transactionTypeAtom,
} from 'app/state/recoil/atoms';
import {
  FilterItem,
  FilterItemProps,
} from 'app/components/Filter/common/FilterItem';
import {
  FilterDividerStyle,
  FilterItemContainer,
} from 'app/components/Filter/style';
import {
  Popover,
  ClickAwayListener,
  useMediaQuery,
  Divider,
} from '@material-ui/core';
import { FilterCard } from 'app/components/FilterCard';

const useStyles = makeStyles(() =>
  createStyles({
    popover: {
      height: '100%',
      top: '20px !important',
      '@media (max-width: 768px)': {
        width: '100%',
      },
    },
    paper: {
      boxShadow: 'unset',
      overflow: 'visible',
      borderRadius: '16px',
      background: 'transparent',
    },
  })
);

const filterGroups = [
  { label: 'activity', disabled: false },
  { label: 'default', disabled: false },
  { label: 'organisation', disabled: false },
  { label: 'sector', disabled: false },
  { label: 'transaction', disabled: false },
];

export function MoreFiltersDropdown() {
  const params = useParams();
  const classes = useStyles();
  const location = useLocation();
  const mobile = useMediaQuery('(max-width: 600px)');
  const [transactionType] = useRecoilState(transactionTypeAtom);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [selectedFilters] = useRecoilState(selectedFilterAtom);
  const [moreTabHasActiveFilter, setMoreTabHasActiveFilter] = React.useState(
    tabHasActiveMoreFilter(selectedFilters)
  );

  const [currentFilterOpen, setCurrentFilterOpen] =
    useRecoilState(filterPopupAtom);

  const filterOptionsData = useStoreState((peasystate) => ({
    locations: {
      countries: get(peasystate.filterOptions.countries, 'data.data', []),
      regions: get(peasystate.filterOptions.regions, 'data.data', []),
    },
    countries: get(peasystate.filterOptions.countries, 'data.data', []),
    regions: get(peasystate.filterOptions.regions, 'data.data', []),
    budget: get(peasystate.filterOptions, 'budget', []),
    transactionvalue: get(peasystate.filterOptions, 'transactionvalue', []),
    sectors: [
      ...get(peasystate.filterOptions.sectors, 'data.data.dac3', []),
      ...get(peasystate.filterOptions.sectors, 'data.data.dac5', []),
    ],
    donors: get(peasystate.filterOptions.donors, 'data.data', []),
    // organisations: get(peasystate.filterOptions.organisations, 'data.data', []),
    publishers: get(peasystate.filterOptions.publishers, 'data.data', []),
    organisations: {
      donors: get(peasystate.filterOptions.donors, 'data.data', []),
      organisations: get(
        peasystate.filterOptions.organisations,
        'data.data',
        []
      ),
      publishers: get(peasystate.filterOptions.publishers, 'data.data', []),
    },
    period: get(peasystate.filterOptions, 'period', []),
    activitystatus: get(
      peasystate.filterOptions.activitystatus,
      'data.data',
      []
    ),
    activity: {
      activityscope: get(
        peasystate.filterOptions.activityscope,
        'data.data',
        []
      ),
      documentlinkcategory: get(
        peasystate.filterOptions.documentlinkcategory,
        'data.data',
        []
      ),
      hierarchy: get(peasystate.filterOptions.hierarchy, 'data.data', []),
      humanitarianscopevocab: get(
        peasystate.filterOptions.humanitarianscopevocab,
        'data.data',
        []
      ),
      humanitarianscope: get(
        peasystate.filterOptions.humanitarianscope,
        'data.data',
        []
      ),
      iativersion: get(peasystate.filterOptions.iativersion, 'data.data', []),
      otheridentifiertype: get(
        peasystate.filterOptions.otheridentifiertype,
        'data.data',
        []
      ),
      tag: get(peasystate.filterOptions.tag, 'data.data', []),
    },
    default: {
      defaultaidtype: get(
        peasystate.filterOptions.defaultaidtype,
        'data.data',
        []
      ),
      defaultaidtypecategory: get(
        peasystate.filterOptions.defaultaidtypecategory,
        'data.data',
        []
      ),
      defaultaidtypevocabulary: get(
        peasystate.filterOptions.defaultaidtypevocabulary,
        'data.data',
        []
      ),
      currency: get(peasystate.filterOptions.currency, 'data.data', []),
      defaultfinancetype: get(
        peasystate.filterOptions.defaultfinancetype,
        'data.data',
        []
      ),
      defaultflowtype: get(
        peasystate.filterOptions.defaultflowtype,
        'data.data',
        []
      ),
      language: get(peasystate.filterOptions.language, 'data.data', []),
      defaulttiedstatus: get(
        peasystate.filterOptions.defaulttiedstatus,
        'data.data',
        []
      ),
    },
    organisation: {
      transactionreceiverorg: get(
        peasystate.filterOptions.transactionreceiverorg,
        'data.data',
        []
      ),
      reportingorgtype: get(
        peasystate.filterOptions.reportingorgtype,
        'data.data',
        []
      ),
      collaborationtype: get(
        peasystate.filterOptions.collaborationtype,
        'data.data',
        []
      ),
    },
    sector: {
      sectorvocabulary: get(
        peasystate.filterOptions.sectorvocabulary,
        'data.data',
        []
      ),
      policymarker: get(peasystate.filterOptions.policymarker, 'data.data', []),
    },
    transaction: {
      transactionflowtype: get(
        peasystate.filterOptions.transactionflowtype,
        'data.data',
        []
      ),
      transactiontiedstatus: get(
        peasystate.filterOptions.transactiontiedstatus,
        'data.data',
        []
      ),
      transactiontype: get(
        peasystate.filterOptions.transactiontype,
        'data.data',
        []
      ),
      transactionvaluecurrency: get(
        peasystate.filterOptions.transactionvaluecurrency,
        'data.data',
        []
      ),
    },
  }));
  const filterOptionsDataLoading = useStoreState((peasystate) => ({
    countries: peasystate.filterOptions.countries.loading,
    regions: peasystate.filterOptions.regions.loading,
    budget: false,
    sectors: peasystate.filterOptions.sectors.loading,
    donors: peasystate.filterOptions.donors.loading,
    organisations: peasystate.filterOptions.organisations.loading,
    publishers: peasystate.filterOptions.publishers.loading,
    period: false,
    activitystatus: peasystate.filterOptions.activitystatus.loading,
    activityscope: peasystate.filterOptions.activityscope.loading,
    documentlinkcategory: peasystate.filterOptions.documentlinkcategory.loading,
    hierarchy: peasystate.filterOptions.hierarchy.loading,
    humanitarianscopevocab:
      peasystate.filterOptions.humanitarianscopevocab.loading,
    humanitarianscope: peasystate.filterOptions.humanitarianscope.loading,
    iativersion: peasystate.filterOptions.iativersion.loading,
    otheridentifiertype: peasystate.filterOptions.otheridentifiertype.loading,
    tag: peasystate.filterOptions.tag.loading,
    defaultaidtype: peasystate.filterOptions.defaultaidtype.loading,
    defaultaidtypecategory:
      peasystate.filterOptions.defaultaidtypecategory.loading,
    defaultaidtypevocabulary:
      peasystate.filterOptions.defaultaidtypevocabulary.loading,
    currency: peasystate.filterOptions.currency.loading,
    defaultfinancetype: peasystate.filterOptions.defaultfinancetype.loading,
    defaultflowtype: peasystate.filterOptions.defaultflowtype.loading,
    language: peasystate.filterOptions.language.loading,
    defaulttiedstatus: peasystate.filterOptions.defaulttiedstatus.loading,
    transactionreceiverorg:
      peasystate.filterOptions.transactionreceiverorg.loading,
    reportingorgtype: peasystate.filterOptions.reportingorgtype.loading,
    collaborationtype: peasystate.filterOptions.collaborationtype.loading,
    sectorvocabulary: peasystate.filterOptions.sectorvocabulary.loading,
    policymarker: peasystate.filterOptions.policymarker.loading,
    transactionflowtype: peasystate.filterOptions.transactionflowtype.loading,
    transactiontiedstatus:
      peasystate.filterOptions.transactiontiedstatus.loading,
    transactiontype: peasystate.filterOptions.transactiontype.loading,
    transactionvaluecurrency:
      peasystate.filterOptions.transactionvaluecurrency.loading,
  }));
  const filterGroupDataActions = useStoreActions((actions) => ({
    // main filters
    countries: actions.filterOptions.countries.fetch,
    regions: actions.filterOptions.regions.fetch,
    sectors: actions.filterOptions.sectors.fetch,
    donors: actions.filterOptions.donors.fetch,
    organisations: actions.filterOptions.organisations.fetch,
    publishers: actions.filterOptions.publishers.fetch,
    activitystatus: actions.filterOptions.activitystatus.fetch,
    // more filters
    activityscope: actions.filterOptions.activityscope.fetch,
    documentlinkcategory: actions.filterOptions.documentlinkcategory.fetch,
    hierarchy: actions.filterOptions.hierarchy.fetch,
    humanitarianscopevocab: actions.filterOptions.humanitarianscopevocab.fetch,
    humanitarianscope: actions.filterOptions.humanitarianscope.fetch,
    iativersion: actions.filterOptions.iativersion.fetch,
    otheridentifiertype: actions.filterOptions.otheridentifiertype.fetch,
    tag: actions.filterOptions.tag.fetch,
    defaultaidtype: actions.filterOptions.defaultaidtype.fetch,
    defaultaidtypecategory: actions.filterOptions.defaultaidtypecategory.fetch,
    defaultaidtypevocabulary:
      actions.filterOptions.defaultaidtypevocabulary.fetch,
    currency: actions.filterOptions.currency.fetch,
    defaultfinancetype: actions.filterOptions.defaultfinancetype.fetch,
    defaultflowtype: actions.filterOptions.defaultflowtype.fetch,
    language: actions.filterOptions.language.fetch,
    defaulttiedstatus: actions.filterOptions.defaulttiedstatus.fetch,
    transactionreceiverorg: actions.filterOptions.transactionreceiverorg.fetch,
    reportingorgtype: actions.filterOptions.reportingorgtype.fetch,
    collaborationtype: actions.filterOptions.collaborationtype.fetch,
    sectorvocabulary: actions.filterOptions.sectorvocabulary.fetch,
    policymarker: actions.filterOptions.policymarker.fetch,
    transactionflowtype: actions.filterOptions.transactionflowtype.fetch,
    transactiontiedstatus: actions.filterOptions.transactiontiedstatus.fetch,
    transactiontype: actions.filterOptions.transactiontype.fetch,
    transactionvaluecurrency:
      actions.filterOptions.transactionvaluecurrency.fetch,
  }));
  const filtersUpdatedData = useStoreState(
    (rstate) => rstate.filtersUpdated.value
  );
  const filtersUpdatedAction = useStoreActions(
    (actions) => actions.filtersUpdated.setValue
  );

  function handleClick(event: React.MouseEvent<HTMLDivElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
    setCurrentFilterOpen('');
  }

  function onFilterGroupClick(group: string, isAdditionalFilter?: boolean) {
    if (
      get(filterGroupDataActions, group, undefined) &&
      !get(filtersUpdatedData, group, false)
    ) {
      get(
        filterGroupDataActions,
        group,
        console.log
      )({
        values: {
          filter_type: group,
          filters: {
            ...getAPIFormattedFilters(selectedFilters),
            ...getDetailPageFilter(location, params),
          },
        },
      });
      filtersUpdatedAction({
        key: group,
        value: true,
      });
    }
    if (!isAdditionalFilter) {
      setCurrentFilterOpen(group);
    }
  }

  React.useEffect(() => {
    handleClose();
    setMoreTabHasActiveFilter(tabHasActiveMoreFilter(selectedFilters));
  }, [selectedFilters]);

  const filterCardoptions = get(filterOptionsData, currentFilterOpen, []);

  return (
    <React.Fragment>
      <div css={FilterItemContainer(false, moreTabHasActiveFilter)}>
        <div
          onClick={handleClick}
          css={`
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 18px;
            display: flex;
            align-items: center;
            text-transform: capitalize;
            user-select: none;
            position: relative;

            > svg {
              margin-left: 7px;

              > path {
                fill: #2e4063;
              }
            }
          `}
        >
          More filters <TriangleIcon />
          <Popover
            css="overflow: visible;"
            classes={{ root: classes.popover, paper: classes.paper }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            elevation={0}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={() => handleClose()}
            >
              <div
                css={`
                  overflow: visible;

                  > div:first-of-type {
                    border-radius: 25px;
                  }
                `}
              >
                <Paper
                  noMargin
                  width={!mobile ? 426 : window.innerWidth - 40}
                  overflow
                >
                  <div
                    css={`
                      height: 44px;
                      display: flex;
                      overflow: auto;
                    `}
                  >
                    {filterGroups.map((item: FilterItemProps) => {
                      const filterGroupOpen =
                        item.label === transactionType.name
                          ? 'transactionvalue'
                          : item.label;
                      return (
                        <div
                          css={FilterItemContainer(
                            currentFilterOpen === filterGroupOpen,
                            tabHasFilterActive(filterGroupOpen, selectedFilters)
                          )}
                          style={
                            item.locked || item.disabled
                              ? {
                                  opacity: 0.5,
                                  pointerEvents: 'none',
                                }
                              : {}
                          }
                          key={`filter-item-${filterGroupOpen}`}
                          onClick={() => {
                            onFilterGroupClick(filterGroupOpen);
                          }}
                        >
                          <FilterItem
                            label={
                              item.label === 'activitystatus'
                                ? 'activity status'
                                : item.label
                            }
                          />
                          <Divider
                            css={FilterDividerStyle}
                            orientation="vertical"
                          />
                        </div>
                      );
                    })}
                  </div>
                </Paper>
                {currentFilterOpen !== '' &&
                  (currentFilterOpen === 'activity' ||
                    currentFilterOpen === 'default' ||
                    currentFilterOpen === 'organisation' ||
                    currentFilterOpen === 'sector' ||
                    currentFilterOpen === 'transaction') && (
                    <div
                      css={`
                        margin-top: 5px;
                        overflow: visible;
                      `}
                    >
                      <FilterCard
                        options={filterCardoptions}
                        loading={get(
                          filterOptionsDataLoading,
                          currentFilterOpen,
                          false
                        )}
                        loadFilterOptions={onFilterGroupClick}
                        filterOptionsDataLoading={filterOptionsDataLoading}
                      />
                    </div>
                  )}
              </div>
            </ClickAwayListener>
          </Popover>
        </div>
        <Divider css={FilterDividerStyle} orientation="vertical" />
      </div>
    </React.Fragment>
  );
}
