import { css } from 'styled-components/macro';

export const styles = {
  img: css`
    width: 48px;
    height: 48px;
    margin-bottom: 12px;
  `,
  title: css`
    margin-bottom: 12px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0;
  `,
  subTitle: css`
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0;
    color: rgba(61, 61, 61, 0.6);
    margin-left: 5px;
  `,
  button: css`
    margin-bottom: 12px;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0;
  `,
  secondaryButton: css`
    color: #2e4063;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0;
    display: flex;
    align-items: center;

    > svg {
      margin-right: 10px;
    }
  `,
};
