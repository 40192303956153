import React from 'react';
import get from 'lodash/get';
import { useDebounce } from 'react-use';
import { DataTable } from 'app/components/Charts/table';
import { useStoreActions, useStoreState } from 'app/state/store/hooks';
import {
  ActivitiesDataTableColumns,
  ActivitiesDataTableOptions,
} from 'app/components/Charts/table/data';
import { MUIDataTableOptions } from 'mui-datatables';

interface ActivitiesTableModuleProps {
  tableOptions?: MUIDataTableOptions;
}

export function ActivitiesTableModule(props: ActivitiesTableModuleProps) {
  const didMountRef = React.useRef(false);
  const [tablePage, setTablePage] = React.useState(0);
  const [tableRows, setTableRows] = React.useState(10);
  const [tableSortBy, setTableSortBy] = React.useState<{
    name: string;
    direction: 'asc' | 'desc';
  }>({
    name: 'Planned start date',
    direction: 'desc',
  });

  const searchTerm = useStoreState((state) => state.syncSearch.value);
  const action = useStoreActions((actions) => actions.activities.fetch);
  const actionData = useStoreState((actions) => actions.activities.data);

  const tableData = get(actionData, 'data', []);
  const tableCount = get(actionData, 'count', []);
  const tableOptions = props.tableOptions
    ? props.tableOptions
    : ActivitiesDataTableOptions;

  function callAction(page: number, rows: number, sort: string, q?: string) {
    action({
      values: {
        filters: q && q.length > 0 ? q : {},
        page,
        rows,
        sort,
      },
    });
  }

  React.useEffect(() => {
    let doCall = true;
    if (!didMountRef.current) {
      doCall = tableData.length === 0;
      didMountRef.current = true;
    }
    if (doCall) {
      callAction(
        tablePage,
        tableRows,
        `${tableSortBy.name} ${tableSortBy.direction}`,
        searchTerm
      );
    }
  }, [tablePage, tableRows, tableSortBy]);

  useDebounce(
    () => {
      if (searchTerm && searchTerm.length > 3) {
        callAction(
          tablePage,
          tableRows,
          `${tableSortBy.name} ${tableSortBy.direction}`,
          searchTerm
        );
      }
    },
    500,
    [searchTerm]
  );

  return (
    <DataTable
      data={tableData}
      columns={ActivitiesDataTableColumns}
      options={{
        ...tableOptions,
        serverSide: true,
        sortOrder: tableSortBy,
        count: tableCount,
        onChangePage: setTablePage,
        onChangeRowsPerPage: setTableRows,
        onColumnSortChange: (
          changedColumn: string,
          direction: 'asc' | 'desc'
        ) =>
          setTableSortBy({
            name: changedColumn,
            direction,
          }),
      }}
      title={`AIDA has found ${new Intl.NumberFormat('nl-NL').format(
        tableCount
      )} activities for you`}
    />
  );
}
