/* eslint-disable @typescript-eslint/camelcase */
import get from 'lodash/get';
import { useRecoilState } from 'recoil';
import { useAuth0 } from '@auth0/auth0-react';
import { useMount, useUpdateEffect } from 'react-use';
import { selectedFilterAtom } from 'app/state/recoil/atoms';
import { useStoreActions, useStoreState } from 'app/state/store/hooks';
import { getAPIFormattedFilters } from 'app/utils/getAPIFormattedFilters';

export function useInitialLoad() {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const countriesfilterOptionsAction = useStoreActions(
    (actions) => actions.filterOptions.countries.fetch
  );
  const regionsfilterOptionsAction = useStoreActions(
    (actions) => actions.filterOptions.regions.fetch
  );
  const sectorsfilterOptionsAction = useStoreActions(
    (actions) => actions.filterOptions.sectors.fetch
  );
  const donorsfilterOptionsAction = useStoreActions(
    (actions) => actions.filterOptions.donors.fetch
  );
  const organisationsfilterOptionsAction = useStoreActions(
    (actions) => actions.filterOptions.organisations.fetch
  );
  const publishersfilterOptionsAction = useStoreActions(
    (actions) => actions.filterOptions.publishers.fetch
  );
  const activitystatusfilterOptionsAction = useStoreActions(
    (actions) => actions.filterOptions.activitystatus.fetch
  );
  const filtersUpdatedClear = useStoreActions(
    (actions) => actions.filtersUpdated.clear
  );
  const loadBookmarks = useStoreActions(
    (actions) => actions.getBookmarks.authGetFetch
  );

  const [selectedFilters] = useRecoilState(selectedFilterAtom);
  const countriesfilterOptionsData = useStoreState(
    (state) => state.filterOptions.countries.data
  );

  function filterOptionsCall() {
    countriesfilterOptionsAction({
      values: {
        filter_type: 'countries',
        filters: getAPIFormattedFilters(selectedFilters),
      },
    });
    regionsfilterOptionsAction({
      values: {
        filter_type: 'regions',
        filters: getAPIFormattedFilters(selectedFilters),
      },
    });
    sectorsfilterOptionsAction({
      values: {
        filter_type: 'sectors',
        filters: getAPIFormattedFilters(selectedFilters),
      },
    });
    donorsfilterOptionsAction({
      values: {
        filter_type: 'donors',
        filters: getAPIFormattedFilters(selectedFilters),
      },
    });
    organisationsfilterOptionsAction({
      values: {
        filter_type: 'organisations',
        filters: getAPIFormattedFilters(selectedFilters),
      },
    });
    publishersfilterOptionsAction({
      values: {
        filter_type: 'publishers',
        filters: getAPIFormattedFilters(selectedFilters),
      },
    });
    activitystatusfilterOptionsAction({
      values: {
        filter_type: 'activitystatus',
        filters: getAPIFormattedFilters(selectedFilters),
      },
    });
  }

  useMount(() => {
    if (get(countriesfilterOptionsData, 'count', 0) === 0) {
      filterOptionsCall();
    }
  });

  useUpdateEffect(() => {
    filtersUpdatedClear();
  }, [selectedFilters]);

  useUpdateEffect(() => {
    if (isAuthenticated && user && user.sub) {
      getAccessTokenSilently().then((token: string) => {
        loadBookmarks({
          values: {
            token,
            userID: user.sub,
          },
        });
      });
    }
  }, [isAuthenticated]);

  return null;
}
