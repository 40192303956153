import { APIModel } from 'app/state/api';
import { ApiCallModel } from 'app/state/api/interfaces';

const registryOrganisation: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_REGISTRY_API}/organisation-information/`
  ),
};

export default registryOrganisation;
