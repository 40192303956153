import React from 'react';
import { useRecoilState } from 'recoil';
import { getCookie } from 'app/utils/getCookie';
import { NewsLetterDialogState } from 'app/state/recoil/atoms';
import NewsLetterDialog from 'app/components/Dialogs/NewsLetterDialog';
import { ChangePhotoDialog } from 'app/components/Dialogs/ChangePhotoDialog';
import { ChangeEmailDialog } from 'app/components/Dialogs/ChangeEmailDialog';
import { ChangePasswordDialog } from 'app/components/Dialogs/ChangePasswordDialog';
import { PasswordSecurityDialog } from 'app/components/Dialogs/PasswordSecurityDialog';
import { ContinueExploringDialog } from 'app/components/Dialogs/ContinueExploringDialog';

export function AppDialogs() {
  const [dialogState, setDialogState] = useRecoilState(NewsLetterDialogState);

  React.useEffect(() => {
    const getNewsLetterCookie = getCookie('newsLetter');
    if (getNewsLetterCookie === undefined) {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 5);
      const newsLetterCookie = `newsLetter=email; expires=${currentDate}; path=/;`;
      document.cookie = newsLetterCookie;

      setTimeout(() => {
        setDialogState({ ...dialogState, open: true });
      }, 90000);
    }
  }, []);
  return (
    <React.Fragment>
      <ChangeEmailDialog />
      <ChangePhotoDialog />
      <ChangePasswordDialog />
      <PasswordSecurityDialog />
      <ContinueExploringDialog />
      <NewsLetterDialog />
    </React.Fragment>
  );
}
