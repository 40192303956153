import React from 'react';
import { useHistory } from 'react-router-dom';

function sendPageView(location) {
  window.gtag('event', 'page_view', {
    page_title: document.title,
    page_location: window.location.href,
    page_path: location.pathname,
    send_to: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  });
}

function refreshHubspotWidget() {
  if (window.HubSpotConversations && window.HubSpotConversations.widget) {
  window.HubSpotConversations.widget.refresh();
  }
}

export function useGA() {
  const history = useHistory();

  React.useEffect(() => {
    if (
      window.location.hostname === 'aida.tools' ||
      window.location.hostname === 'www.aida.tools'
    ) {
      return history.listen(sendPageView);
    }
  }, [history]);

  React.useEffect(() => {
    return history.listen(refreshHubspotWidget);
  }, [history]);

  return null;
}
