/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { css } from 'styled-components/macro';
import { Typography } from '@material-ui/core';

interface NavItemParams {
  name: string;
  count: number;
  index?: number;
  active: boolean;
  onClick: () => void;
}

const containercss = (active: boolean) => css`
  cursor: pointer;
  margin-right: 40px;
  padding-bottom: 8px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  transition: border-bottom-color 0.1s linear;
  border-bottom-color: ${active ? '#2e4063' : 'transparent'};

  &:hover {
    border-bottom-color: #2e4063;
  }
`;

const typographycss = css`
  color: #2e4063;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  font-style: normal;
  text-transform: capitalize;

  &:hover {
    opacity: 0.8;
  }
`;

export const SearchResultNavItem = (props: NavItemParams) => {
  return (
    <div
      onClick={props.onClick}
      css={containercss(props.active)}
      data-cy={`search-nav-item-${props.index}`}
    >
      <Typography css={typographycss}>
        {props.count} {props.name}
      </Typography>
    </div>
  );
};
