import React from 'react';
import get from 'lodash/get';
import find from 'lodash/find';
import { Path } from 'app/const/Path';
import { useRecoilState } from 'recoil';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { useLocation, useHistory } from 'react-router-dom';
import { AddBookmarkIcon } from 'app/assets/AddBookmarkIcon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import createStyles from '@material-ui/core/styles/createStyles';
import { BookmarkItem } from 'app/modules/bookmarks-module/data';
import { useStoreActions, useStoreState } from 'app/state/store/hooks';
import {
  ContinueExploringDialogState,
  selectedFilterAtom,
} from 'app/state/recoil/atoms';

interface BookmarkButtonProps {
  overridePath?: string;
  overrideName?: string;
}

const muiStyles = makeStyles(() =>
  createStyles({
    snackbarRoot: {
      borderRadius: 12,
      backgroundColor: '#2E4063',
    },
  })
);

export function BookmarkButton(props: BookmarkButtonProps) {
  const history = useHistory();
  const location = useLocation();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [selectedFilters] = useRecoilState(selectedFilterAtom);
  const setDialogState = useRecoilState(ContinueExploringDialogState)[1];

  const [snackbarText, setSnackbarText] = React.useState('');
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const classes = muiStyles();

  const bookmarks = useStoreState(
    (state) => get(state.getBookmarks, 'data.data', []) as BookmarkItem[]
  );
  const createBookmark = useStoreActions(
    (actions) => actions.createBookmark.authPostFetch
  );
  const loadBookmarks = useStoreActions(
    (actions) => actions.getBookmarks.authGetFetch
  );
  const createBookmarkClear = useStoreActions(
    (actions) => actions.createBookmark.clear
  );
  const createBookmarkSuccess = useStoreState(
    (peasystate) => peasystate.createBookmark.success
  );
  const deleteBookmark = useStoreActions(
    (actions) => actions.deleteBookmark.authPostFetch
  );
  const deleteBookmarkClear = useStoreActions(
    (actions) => actions.deleteBookmark.clear
  );
  const deleteBookmarkSuccess = useStoreState(
    (state) => state.deleteBookmark.success
  );

  let isBookmarked = find(bookmarks, {
    path: location.pathname
      .replace('/description', '')
      .replace('/performance', '')
      .replace('/financial', '')
      .replace('/projects', '')
      .replace('/donors', '')
      .replace('/publishers', '')
      .replace('/sectors', '')
      .replace('/organisations', ''),
  });

  if (props.overridePath) {
    isBookmarked = find(bookmarks, {
      path: props.overridePath,
    });
  }

  function onCreateBookmark() {
    if (isAuthenticated && user && user.sub) {
      getAccessTokenSilently().then((token: string) => {
        const filterStringArray: string[] = [];
        Object.keys(selectedFilters).forEach((field: string) => {
          const values = get(selectedFilters, `["${field}"]`, []);
          if (values.length > 0) {
            if (field === 'period') {
              filterStringArray.push(
                `${field} = ${values[0].startDate} to ${values[0].endDate}`
              );
            } else if (field === 'transactionvalue') {
              filterStringArray.push(`${field} = ${values[0]} to ${values[1]}`);
            } else {
              filterStringArray.push(`${field} = (${values.join(' OR ')})`);
            }
          }
        });
        createBookmark({
          values: {
            token,
            userID: user.sub,
            path:
              props.overridePath ||
              `${location.pathname
                .replace('/description', '')
                .replace('/performance', '')
                .replace('/financial', '')
                .replace('/projects', '')
                .replace('/donors', '')
                .replace('/publishers', '')
                .replace('/sectors', '')
                .replace('/organisations', '')}${location.search}`,
            name:
              props.overrideName ||
              `${document.title
                .replace('AIDA - ', '')
                .replace(' - description', '')
                .replace(' - performance', '')
                .replace(' - financial', '')
                .replace(' activities', '')
                .replace(' donors', '')
                .replace(' publishers', '')
                .replace(' sectors', '')
                .replace(' organisations', '')}${
                filterStringArray.length > 0
                  ? ` | ${filterStringArray.join(' AND ')}`
                  : ''
              }`,
          },
        });
      });
    } else {
      setDialogState({
        open: true,
        redirect: location.pathname,
      });
    }
  }

  function onDeleteBookmark() {
    if (isAuthenticated && user && user.sub && isBookmarked) {
      getAccessTokenSilently().then((token: string) => {
        deleteBookmark({
          values: {
            token,
            userID: user.sub,
            id: isBookmarked ? isBookmarked._id : '',
          },
        });
      });
    }
  }

  React.useEffect(() => {
    if (
      (createBookmarkSuccess || deleteBookmarkSuccess) &&
      isAuthenticated &&
      user &&
      user.sub
    ) {
      if (createBookmarkSuccess) {
        setSnackbarText('Bookmark added!');
      }
      if (deleteBookmarkSuccess) {
        setSnackbarText('Bookmark deleted!');
      }
      setOpenSnackbar(true);
      getAccessTokenSilently().then((token: string) => {
        loadBookmarks({
          values: {
            token,
            userID: user.sub,
          },
        });
      });
      createBookmarkClear();
      deleteBookmarkClear();
    }
  }, [createBookmarkSuccess, deleteBookmarkSuccess]);

  return (
    <React.Fragment>
      <IconButton
        aria-label="create bookmark"
        onClick={isBookmarked ? onDeleteBookmark : onCreateBookmark}
        css={`
          padding: 6px;
          * {
            fill: #2e4063;
          }
        `}
      >
        {isBookmarked ? <BookmarkIcon /> : <AddBookmarkIcon />}
      </IconButton>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={5000}
        className={classes.snackbarRoot}
        onClose={() => setOpenSnackbar(false)}
      >
        <SnackbarContent
          message={snackbarText}
          className={classes.snackbarRoot}
          action={
            snackbarText === 'Bookmark added!' && (
              <Button
                onClick={() => {
                  setOpenSnackbar(false);
                  history.push(Path.bookmarks);
                }}
                css={`
                  color: #51dbca;
                  text-transform: capitalize;
                `}
              >
                view
              </Button>
            )
          }
        />
      </Snackbar>
    </React.Fragment>
  );
}
