import React from 'react';
import { Paper } from 'app/components/Paper';
import { css } from 'styled-components/macro';
import { BottomControls } from 'app/components/FilterCard/common/BottomControls';
import { useRecoilState } from 'recoil';
import { filterPopupAtom, selectedFilterAtom } from 'app/state/recoil/atoms';
import { Grid, useMediaQuery } from '@material-ui/core';

interface MoreFiltersCardProps {
  header: string;
  selectedOptions?: any;
}

const HeaderStyle = css`
  width: 100%;
  height: 23px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #b6b6b6;
  border-bottom: 0.5px solid #b6b6b6;
  margin-bottom: 8px;
`;

export const MoreFiltersCard: React.FunctionComponent<MoreFiltersCardProps> = (
  props
) => {
  const mobile = useMediaQuery('(max-width: 600px)');
  const [currentOpenFilter, setCurrentOpenFilter] =
    useRecoilState(filterPopupAtom);
  const [selectedFilters, setSelectedFilters] =
    useRecoilState(selectedFilterAtom);

  function handleApplyClick() {
    let filterObj = {};
    switch (currentOpenFilter) {
      case 'activity':
        filterObj = {
          activityscope: props.selectedOptions.activityscope,
          documentlinkcategory: props.selectedOptions.documentlinkcategory,
          hierarchy: props.selectedOptions.hierarchy,
          humanitarianscopevocab: props.selectedOptions.humanitarianscopevocab,
          humanitarian: props.selectedOptions.humanitarian,
          humanitarianscope: props.selectedOptions.humanitarianscope,
          iativersion: props.selectedOptions.iativersion,
          otheridentifiertype: props.selectedOptions.otheridentifiertype,
          tag: props.selectedOptions.tag,
        };
        break;
      case 'default':
        filterObj = {
          defaultaidtype: props.selectedOptions.defaultaidtype,
          defaultaidtypecategory: props.selectedOptions.defaultaidtypecategory,
          defaultaidtypevocabulary:
            props.selectedOptions.defaultaidtypevocabulary,
          currency: props.selectedOptions.currency,
          defaultfinancetype: props.selectedOptions.defaultfinancetype,
          defaultflowtype: props.selectedOptions.defaultflowtype,
          language: props.selectedOptions.language,
          defaulttiedstatus: props.selectedOptions.defaulttiedstatus,
        };
        break;
      case 'organisation':
        filterObj = {
          transactionreceiverorg: props.selectedOptions.transactionreceiverorg,
          reportingorgtype: props.selectedOptions.reportingorgtype,
          collaborationtype: props.selectedOptions.collaborationtype,
          secondaryreporter: props.selectedOptions.secondaryreporter,
        };
        break;
      case 'sector':
        filterObj = {
          sectorvocabulary: props.selectedOptions.sectorvocabulary,
          policymarker: props.selectedOptions.policymarker,
        };
        break;
      case 'transaction':
        filterObj = {
          transactionflowtype: props.selectedOptions.transactionflowtype,
          transactiontiedstatus: props.selectedOptions.transactiontiedstatus,
          transactionhumanitarian:
            props.selectedOptions.transactionhumanitarian,
          transactiontype: props.selectedOptions.transactiontype,
          transactionvaluecurrency:
            props.selectedOptions.transactionvaluecurrency,
        };
        break;
      default:
        filterObj = {};
    }
    setSelectedFilters({
      ...selectedFilters,
      ...filterObj,
    });
    setCurrentOpenFilter('');
  }

  function handleCancelClick() {
    setCurrentOpenFilter('');
  }

  return (
    <Paper
      padding
      noMargin
      width={!mobile ? 608 : window.innerWidth - 40}
      overflow
    >
      <div css={HeaderStyle}>{props.header}</div>
      <Grid
        container
        spacing={2}
        css={`
          margin-top: 0px;
          margin-bottom: 9px;
        `}
      >
        {props.children}
      </Grid>
      <BottomControls
        handleApplyClick={handleApplyClick}
        handleCancelClick={handleCancelClick}
        selectedOptions={props.selectedOptions}
      />
    </Paper>
  );
};
