import React, { ChangeEvent, FunctionComponent } from 'react';
import { useRecoilState } from 'recoil';
import filter from 'lodash/filter';
import { useUpdateEffect } from 'react-use';
import { Paper } from 'app/components/Paper';
import { BottomControls } from 'app/components/FilterCard/common/BottomControls';
import { filterPopupAtom, selectedFilterAtom } from 'app/state/recoil/atoms';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { css } from 'styled-components/macro';
import { TopControls } from './TopControls';
import { OptionsModel } from './FilterOptionItem';

export interface MainFiltersCardProps {
  title?: string;
  options: OptionsModel[];
  selectedOptions: any;
  allSelectedOptions?: any;
  setSelectedOptions: any;
  shownOptions: any;
  setShownOptions: any;
  shouldSelectAll: boolean;
  setShouldSelectAll: any;
  removeTopControls?: boolean;
  loading: boolean;
  sector?: boolean;
  locations?: boolean;
  organisations?: boolean;
  dontDisplaySelected?: boolean;
  disableApply?: boolean;
  currentTabIndex?: number;
}

export const MainFiltersCard: FunctionComponent<MainFiltersCardProps> = (
  props
) => {
  const mobile = useMediaQuery('(max-width: 600px)');
  const [currentOpenFilter, setCurrentOpenFilter] =
    useRecoilState(filterPopupAtom);
  const [selectedFilters, setSelectedFilters] =
    useRecoilState(selectedFilterAtom);

  React.useEffect(() => {
    if (props.options.length === 0) {
      props.setShouldSelectAll(false);
    } else {
      props.setShouldSelectAll(
        props.options.length === props.selectedOptions.length
      );
    }
  }, [props.options, props.selectedOptions]);

  useUpdateEffect(() => {
    props.setShownOptions(props.options);
  }, [props.options]);

  useUpdateEffect(() => {
    const searchInput = document.getElementById(
      'filter-search-input'
    ) as HTMLInputElement;
    if (searchInput && searchInput.value !== null && searchInput.value !== '') {
      props.setShownOptions(
        filter(
          props.options,
          (option: OptionsModel) =>
            option.name
              .toLowerCase()
              .indexOf((searchInput.value || '').toLowerCase()) > -1
        )
      );
    }
  }, [props.currentTabIndex]);

  function handleResetClick() {
    props.setSelectedOptions([]);
  }

  function handleSelectAllClick(e: ChangeEvent<HTMLInputElement>) {
    props.setShouldSelectAll(e.target.checked);
    if (e.target.checked) {
      props.setSelectedOptions(
        props.options.map((option: OptionsModel) => option.code)
      );
    } else {
      props.setSelectedOptions([]);
    }
  }

  function handleApplyClick() {
    let updatedSelectedFilters = { ...selectedFilters };
    if (currentOpenFilter === 'locations') {
      if (props.currentTabIndex && props.allSelectedOptions) {
        updatedSelectedFilters = {
          ...selectedFilters,
          regions: props.allSelectedOptions.regions,
          countries: props.allSelectedOptions.countries,
        };
      }
    } else if (currentOpenFilter === 'organisations') {
      if (props.currentTabIndex && props.allSelectedOptions) {
        updatedSelectedFilters = {
          ...selectedFilters,
          donors: props.allSelectedOptions.donors,
          publishers: props.allSelectedOptions.publishers,
          organisations: props.allSelectedOptions.organisations,
        };
      }
    } else {
      updatedSelectedFilters = {
        ...selectedFilters,
        [currentOpenFilter]: props.selectedOptions,
      };
    }
    setSelectedFilters(updatedSelectedFilters);
    setCurrentOpenFilter('');
  }

  function handleCancelClick() {
    setCurrentOpenFilter('');
  }

  function handleSearchInput(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    props.setShownOptions(
      filter(
        props.options,
        (option: OptionsModel) =>
          option.name.toLowerCase().indexOf(event.target.value.toLowerCase()) >
          -1
      )
    );
  }

  const styles = {
    headerLabel: css`
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #2e4063;
      padding-bottom: 7px;
      border-bottom: 0.5px solid #2e4063;
      margin-bottom: 16px;
    `,
  };

  return (
    <Paper
      padding
      noMargin
      width={!mobile ? 600 : window.innerWidth - 40}
      overflow
    >
      <Typography variant="h6" css={styles.headerLabel}>
        <b>Main filters</b> - {props.title}
      </Typography>
      {!props.removeTopControls && (
        <TopControls
          handleResetClick={handleResetClick}
          handleSearch={handleSearchInput}
          handleSelectAllClick={handleSelectAllClick}
          shouldSelectAll={props.shouldSelectAll}
          sector={props.sector}
          locations={props.locations}
          organisations={props.organisations}
        />
      )}
      <Grid item lg={12}>
        <LinearProgress
          css={`
            && {
              visibility: ${props.loading ? 'visible' : 'hidden'};
            }
          `}
        />
      </Grid>
      {props.children}
      <BottomControls
        displaySelected={!props.dontDisplaySelected}
        handleApplyClick={handleApplyClick}
        handleCancelClick={handleCancelClick}
        selectedOptions={props.selectedOptions}
        disableApply={props.disableApply}
      />
    </Paper>
  );
};
