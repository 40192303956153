import React from 'react';
import find from 'lodash/find';
import { useRecoilState } from 'recoil';
import { FilterProps } from 'app/components/FilterCard';
import { filterCardTabState } from 'app/state/recoil/atoms';
import { FilterOptions } from 'app/components/FilterCard/MainFilters/RegionsFilter';
import { MainFiltersCard } from 'app/components/FilterCard/MainFilters/common/MainFilterCard';
import { AlphabeticallyGroupedOptions } from 'app/components/FilterCard/MainFilters/common/AlphabeticallyGroupedOptions';

export const LocationsFilter = (props: FilterProps) => {
  const [tabs] = useRecoilState(filterCardTabState);
  const currentTabIndex = find(tabs, 'active')?.index;

  function renderOptions() {
    switch (currentTabIndex) {
      case 0:
        return {}; // TODO: recent
      case 1:
        return (
          <AlphabeticallyGroupedOptions
            {...props}
            key="CountriesFilter-container"
            shownOptions={props.shownOptions}
            selectedOptions={props.selectedOptions.countries}
            setSelectedOptions={(options: any) => {
              props.setSelectedOptions({
                ...props.selectedOptions,
                countries: options,
              });
            }}
          />
        );
      case 2:
        return (
          <FilterOptions
            {...props}
            key="RegionsFilter-container"
            shownOptions={props.shownOptions}
            selectedOptions={props.selectedOptions.regions}
            setSelectedOptions={(options: any) => {
              props.setSelectedOptions({
                ...props.selectedOptions,
                regions: options,
              });
            }}
          />
        );
      default:
        return {};
    }
  }

  return (
    <MainFiltersCard
      {...props}
      locations
      title="Locations"
      currentTabIndex={currentTabIndex}
      selectedOptions={
        currentTabIndex === 1
          ? props.selectedOptions.countries
          : props.selectedOptions.regions
      }
      allSelectedOptions={props.selectedOptions}
      options={
        currentTabIndex === 1 ? props.options.countries : props.options.regions
      }
      setSelectedOptions={(options: any) => {
        const updatedSelectedOptions = { ...props.selectedOptions };
        if (currentTabIndex === 1) {
          updatedSelectedOptions.countries = options;
        } else {
          updatedSelectedOptions.regions = options;
        }
        props.setSelectedOptions(updatedSelectedOptions);
      }}
    >
      {renderOptions()}
    </MainFiltersCard>
  );
};
