import React from 'react';
import theme from 'app/theme';
import { Grid } from '@material-ui/core';
import remove from 'lodash/remove';
import { MainFiltersCardProps } from './MainFilterCard';

export interface OptionsModel {
  _id: string;
  name: string;
  code: string;
}

interface FilterOptionItemProps extends MainFiltersCardProps {
  label: string;
  value: string;
  selected: boolean;
}

export const FilterOptionItem = (props: FilterOptionItemProps) => {
  function handleOptionSelect(value: string, selected: boolean) {
    const updatedSelectedOptions = [...props.selectedOptions];

    if (selected) {
      remove(
        updatedSelectedOptions,
        (option: string) => option === props.value
      );
    } else {
      updatedSelectedOptions.push(value);
    }

    props.setSelectedOptions(updatedSelectedOptions);
  }

  return (
    <Grid
      item
      sm={4}
      onClick={() => handleOptionSelect(props.value, props.selected)}
      css={`
        height: fit-content;
        user-select: none;
        cursor: pointer;
        background-color: ${props.selected
          ? theme.palette.primary.main
          : 'initial'};
        color: ${props.selected
          ? theme.palette.common.white
          : theme.palette.text.primary};
        border-radius: 16px;
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 6px;
        padding-bottom: 6px;
        transition: all 150ms;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        margin-bottom: 7px;

        &:hover {
          background-color: ${props.selected
            ? theme.palette.primary.light
            : 'initial'};
          opacity: 0.8;
        }
      `}
    >
      {props.label}
    </Grid>
  );
};
