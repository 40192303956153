import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { NoMatchPage } from 'app/modules/common/no-match-page';

function RedirectComp(props: any) {
  return (
    <Redirect
      to={`/app${props.location.pathname}${
        props.location.search ? `?${props.location.search}` : ''
      }`}
    />
  );
}

export function V1Redirections() {
  return (
    <React.Fragment>
      <Route path="/activity/:param" render={RedirectComp} />
      <Route path="/country/:param" render={RedirectComp} />
      <Route path="/donor/:param" render={RedirectComp} />
      <Route path="/publisher/:param" render={RedirectComp} />
      <Route path="/organisation/:param" render={RedirectComp} />
      <Route path="/data-manager" render={RedirectComp} />
      <Route path="/data-manager/bulk-management" render={RedirectComp} />
      <Route
        render={RedirectComp}
        path="/data-manager/bulk-management/upload"
      />
      <Route
        render={RedirectComp}
        path="/validation-report/:orgRef/:uuid/:filename"
      />
      <Route path="/user-settings/:id" render={RedirectComp} />
      <Route
        path="/login"
        render={(props: any) => {
          return (
            <Redirect
              to={{
                pathname: '/app/',
                state: {
                  openMenu: true,
                },
              }}
            />
          );
        }}
      />
      <Route exact>
        <NoMatchPage />
      </Route>
    </React.Fragment>
  );
}
