/* eslint-disable import/no-cycle */
import React from 'react';
import get from 'lodash/get';
import { useRecoilState } from 'recoil';
import {
  filterPopupAtom,
  selectedFilterAtom,
  transactionTypeAtom,
} from 'app/state/recoil/atoms';

/* Main Filters */
import { BudgetFilter } from 'app/components/FilterCard/MainFilters/BudgetFilter';
import { PeriodFilter } from 'app/components/FilterCard/MainFilters/PeriodFilter';
import { DonorsFilter } from 'app/components/FilterCard/MainFilters/DonorsFilter';
import { SectorsFilter } from 'app/components/FilterCard/MainFilters/SectorsFilter';
import { RegionsFilter } from 'app/components/FilterCard/MainFilters/RegionsFilter';
import { CountriesFilter } from 'app/components/FilterCard/MainFilters/CountriesFilter';
import { PublishersFilter } from 'app/components/FilterCard/MainFilters/PublishersFilter';
import { OptionsModel } from 'app/components/FilterCard/MainFilters/common/FilterOptionItem';
import { OrganisationsFilter } from 'app/components/FilterCard/MainFilters/OrganisationsFilter';
import { AcivityStatusFilter } from 'app/components/FilterCard/MainFilters/ActivityStatusFilter';
import { TransactionValueFilter } from 'app/components/FilterCard/MainFilters/TransactionValueFilter';

/* More Filters */
import { SectorFilter } from 'app/components/FilterCard/MoreFilters/SectorFilter';
import { DefaultFilter } from 'app/components/FilterCard/MoreFilters/DefaultFilter';
import { ActivityFilter } from 'app/components/FilterCard/MoreFilters/ActivityFilter';
import { TransactionFilter } from 'app/components/FilterCard/MoreFilters/TransactionFilter';
import { OrganisationFilter } from 'app/components/FilterCard/MoreFilters/OrganisationFilter';
import { LocationsFilter } from './MainFilters/LocationsFilter';

interface FilterCardProps {
  options: any;
  loading: boolean;
  filterOptionsDataLoading: any;
  loadFilterOptions: (arg0: string, arg1: boolean) => void;
}

export interface FilterProps {
  options: any;
  loading: boolean;
  currentFilterOpen: any;
  selectedFilters: any;
  shownOptions: OptionsModel[];
  setShownOptions: any;
  selectedOptions: any;
  setSelectedOptions: any;
  shouldSelectAll: any;
  setShouldSelectAll: any;
}

function getSelectedOptions(filters: any, type: string) {
  switch (type) {
    case 'activity':
      return {
        activityscope: filters.activityscope,
        documentlinkcategory: filters.documentlinkcategory,
        hierarchy: filters.hierarchy,
        humanitarianscopevocab: filters.humanitarianscopevocab,
        humanitarian: filters.humanitarian,
        humanitarianscope: filters.humanitarianscope,
        iativersion: filters.iativersion,
        otheridentifiertype: filters.otheridentifiertype,
        tag: filters.tag,
      };
    case 'default':
      return {
        defaultaidtype: filters.defaultaidtype,
        defaultaidtypecategory: filters.defaultaidtypecategory,
        defaultaidtypevocabulary: filters.defaultaidtypevocabulary,
        currency: filters.currency,
        defaultfinancetype: filters.defaultfinancetype,
        defaultflowtype: filters.defaultflowtype,
        language: filters.language,
        defaulttiedstatus: filters.defaulttiedstatus,
      };
    case 'organisation':
      return {
        transactionreceiverorg: filters.transactionreceiverorg,
        reportingorgtype: filters.reportingorgtype,
        collaborationtype: filters.collaborationtype,
        secondaryreporter: filters.secondaryreporter,
      };
    case 'sector':
      return {
        sectorvocabulary: filters.sectorvocabulary,
        policymarker: filters.policymarker,
      };
    case 'transaction':
      return {
        transactionflowtype: filters.transactionflowtype,
        transactiontiedstatus: filters.transactiontiedstatus,
        transactionhumanitarian: filters.transactionhumanitarian,
        transactiontype: filters.transactiontype,
        transactionvaluecurrency: filters.transactionvaluecurrency,
      };
    case 'locations':
      return {
        countries: get(filters, 'countries', []),
        regions: get(filters, 'regions', []),
      };
    case 'organisations':
      return {
        donors: get(filters, 'donors', []),
        organisations: get(filters, 'organisations', []),
        publishers: get(filters, 'publishers', []),
      };
    default:
      return get(filters, type, []);
  }
}

export function FilterCard(props: FilterCardProps): JSX.Element {
  const [currentFilterOpen] = useRecoilState(filterPopupAtom);
  const [selectedFilters] = useRecoilState(selectedFilterAtom);
  const [transactionType] = useRecoilState(transactionTypeAtom);
  const [shownOptions, setShownOptions] = React.useState(props.options);
  const [selectedOptions, setSelectedOptions] = React.useState(
    getSelectedOptions(selectedFilters, currentFilterOpen)
  );
  const [shouldSelectAll, setShouldSelectAll] = React.useState(
    props.options.length === selectedOptions.length
  );

  const filterProps = {
    currentFilterOpen:
      currentFilterOpen === transactionType.name
        ? 'transactionvalue'
        : currentFilterOpen,
    selectedFilters,
    shownOptions,
    setShownOptions,
    selectedOptions,
    setSelectedOptions,
    shouldSelectAll,
    setShouldSelectAll,
    filterOptionsDataLoading: props.filterOptionsDataLoading,
  };

  React.useEffect(() => {
    if (currentFilterOpen === 'locations') {
      setShownOptions(props.options.countries);
    } else if (currentFilterOpen === 'organisations') {
      setShownOptions(props.options.donors);
    } else {
      setShownOptions(props.options);
    }
  }, [currentFilterOpen]);

  switch (currentFilterOpen) {
    case 'activitystatus':
      return (
        <AcivityStatusFilter
          options={props.options}
          loading={props.loading}
          {...filterProps}
        />
      );
    case 'budget':
      return (
        <BudgetFilter
          options={props.options}
          loading={props.loading}
          {...filterProps}
        />
      );
    case 'transactionvalue':
      return (
        <TransactionValueFilter
          options={props.options}
          loading={props.loading}
          {...filterProps}
        />
      );
    case 'locations':
      return (
        <LocationsFilter
          options={props.options}
          loading={props.loading}
          {...filterProps}
        />
      );
    case 'countries':
      return (
        <CountriesFilter
          options={props.options}
          loading={props.loading}
          {...filterProps}
        />
      );
    case 'donors':
      return (
        <DonorsFilter
          options={props.options}
          loading={props.loading}
          {...filterProps}
        />
      );
    case 'organisations':
      return (
        <OrganisationsFilter
          options={props.options}
          loading={props.loading}
          {...filterProps}
        />
      );
    case 'period':
      return (
        <PeriodFilter
          options={props.options}
          loading={props.loading}
          {...filterProps}
        />
      );
    case 'publishers':
      return (
        <PublishersFilter
          options={props.options}
          loading={props.loading}
          {...filterProps}
        />
      );
    case 'regions':
      return (
        <RegionsFilter
          options={props.options}
          loading={props.loading}
          {...filterProps}
        />
      );
    case 'sectors':
      return (
        <SectorsFilter
          options={props.options}
          loading={props.loading}
          {...filterProps}
        />
      );
    case `activity`:
      return (
        <ActivityFilter
          options={props.options}
          loading={props.loading}
          loadFilterOptions={props.loadFilterOptions}
          {...filterProps}
        />
      );
    case `default`:
      return (
        <DefaultFilter
          options={props.options}
          loading={props.loading}
          loadFilterOptions={props.loadFilterOptions}
          {...filterProps}
        />
      );
    case `organisation`:
      return (
        <OrganisationFilter
          options={props.options}
          loading={props.loading}
          loadFilterOptions={props.loadFilterOptions}
          {...filterProps}
        />
      );
    case `sector`:
      return (
        <SectorFilter
          options={props.options}
          loading={props.loading}
          loadFilterOptions={props.loadFilterOptions}
          {...filterProps}
        />
      );
    case `transaction`:
      return (
        <TransactionFilter
          options={props.options}
          loading={props.loading}
          loadFilterOptions={props.loadFilterOptions}
          {...filterProps}
        />
      );
    default:
      return <></>;
  }
}
