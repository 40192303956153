import React, { ReactNode } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { StoreProvider } from 'easy-peasy';
import theme from 'app/theme';
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline, StylesProvider } from '@material-ui/core';
import { RecoilRoot } from 'recoil';
import { store } from 'app/state/store';
import { Auth0Provider } from '@auth0/auth0-react';

type ProviderProps = {
  children?: ReactNode;
};

function Providers(props: ProviderProps) {
  return (
    <RecoilRoot>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <StoreProvider store={store}>
            <Auth0Provider
              redirectUri={`${window.location.origin}/callback`}
              domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
              clientId={process.env.REACT_APP_AUTH0_CLIENT as string}
              audience={process.env.REACT_APP_AUTH0_AUDIENCE as string}
            >
              <Router>{props.children}</Router>
            </Auth0Provider>
          </StoreProvider>
        </ThemeProvider>
      </StylesProvider>
    </RecoilRoot>
  );
}

export default Providers;
