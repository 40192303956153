import React from 'react';
import { Path } from 'app/const/Path';
import { useRecoilState } from 'recoil';
import { NavLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import MUIListItem from '@material-ui/core/ListItem';
import MUIListItemIcon from '@material-ui/core/ListItemIcon';
import MUIListItemText from '@material-ui/core/ListItemText';
import { GlobalNavItemProps } from 'app/components/Drawer/data';
import { ContinueExploringDialogState } from 'app/state/recoil/atoms';

export const DrawerItem = (props: GlobalNavItemProps) => {
  const { isAuthenticated } = useAuth0();
  const [isActive, setIsActive] = React.useState(false);
  const setDialogState = useRecoilState(ContinueExploringDialogState)[1];

  if (props.path.indexOf('http') === 0) {
    return (
      <a
        target="_blank"
        href={props.path}
        className="draweritem"
        rel="noopener noreferrer"
      >
        <MUIListItem
          button
          key={props.label}
          css={`
            border-radius: 8px;

            &:hover {
              background: #ededf6;
            }
          `}
        >
          <MUIListItemIcon>
            {!isActive ? props.icon : props.iconActive}
          </MUIListItemIcon>
          <MUIListItemText primary={props.label} />
        </MUIListItem>
      </a>
    );
  }

  return (
    <NavLink
      exact
      to={props.path}
      className="draweritem"
      activeClassName="activedraweritem"
      isActive={(match: any, location: any) => {
        let res = false;
        if (props.path === Path.home) {
          res = location.pathname === Path.home;
        } else {
          res =
            location.pathname === props.path ||
            location.pathname.indexOf(props.path) > -1;
        }
        if (res !== isActive) {
          setIsActive(res);
        }
        return res;
      }}
      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
        if (!isAuthenticated && props.needsAuth) {
          e.preventDefault();
          setDialogState({
            open: true,
            redirect: '/data-manager',
          });
        }
      }}
    >
      <MUIListItem
        button
        key={props.label}
        css={`
          border-radius: 8px;

          &:hover {
            background: #ededf6;
          }
        `}
      >
        <MUIListItemIcon>
          {!isActive ? props.icon : props.iconActive}
        </MUIListItemIcon>
        <MUIListItemText primary={props.label} />
      </MUIListItem>
    </NavLink>
  );
};
