import React from 'react';
import TagManager from 'react-gtm-module';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  dataLayerName: 'PageDataLayer',
};

TagManager.initialize(tagManagerArgs);

function sendDataLayer(location, user) {
  if (user && user.sub) {
    TagManager.dataLayer({
      dataLayer: {
        userId: user.sub,
        page: location.pathname,
      },
      dataLayerName: 'PageDataLayer',
    });
  }
}

export function useGTag() {
  const { user } = useAuth0();
  const history = useHistory();

  React.useEffect(() => {
    if (
      window.location.hostname === 'aida.tools' ||
      window.location.hostname === 'www.aida.tools'
    ) {
      return history.listen((location) => sendDataLayer(location, user));
    }
  }, [history]);

  return null;
}
