import React from 'react';
import Auth0JS from 'auth0-js';
import { Path } from 'app/const/Path';
import { useRecoilState } from 'recoil';
import { useUpdateEffect } from 'react-use';
import Dialog from '@material-ui/core/Dialog';
import { css } from 'styled-components/macro';
import Highlighter from 'react-highlight-words';
import { Link, NavLink } from 'react-router-dom';
import IconClose from '@material-ui/icons/Close';
import { LogoGoogle } from 'app/assets/LogoGoogle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { emailValidation } from 'app/utils/emailValidation';
import { ContinueExploringDialogState } from 'app/state/recoil/atoms';
import { OnboardingTextInput } from 'app/modules/web-shop/sub-modules/onboarding/components/textinput';

const policyLink = css`
  color: #2e4063;
  text-decoration-line: underline;
  :hover {
    text-decoration: underline;
    color: #2e4063;
  }
`;

const iatiStandardLink = css`
  color: #f7f7f7;
  text-decoration-line: underline;

  :hover {
    text-decoration: underline;
    color: #f7f7f7;
  }
`;

const closebtncss = css`
  && {
    padding: 0;
  }
`;

const socialloginbuttoncss = css`
  gap: 20px;
  width: 100%;
  height: 44px;
  display: flex;
  color: #2e4063;
  padding: 6px 0;
  font-size: 14px;
  cursor: pointer;
  font-weight: 700;
  line-height: 20px;
  border-radius: 4px;
  flex-direction: row;
  margin-bottom: 16px;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 1px solid #a1aebd;

  &:hover {
    background: #a1aebd;
  }
`;

const actionbuttoncss = css`
  width: 100%;
  color: #fff;
  cursor: pointer;
  padding: 11px 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  border-style: none;
  border-radius: 50px;
  background: #2e4063;

  :disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover {
    background: #a1aebd;
  }
`;

export const ContinueExploringDialog = () => {
  const [dialogState, setDialogState] = useRecoilState(
    ContinueExploringDialogState
  );

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [signinEnabled, setSigninEnabled] = React.useState(false);

  const webAuth = new Auth0JS.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
    clientID: process.env.REACT_APP_AUTH0_CLIENT as string,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE as string,
    redirectUri: `${window.location.origin}/callback`,
  });

  function doLogin(
    connection:
      | 'google-oauth2'
      | 'linkedin'
      | 'github'
      | 'Username-Password-Authentication'
  ) {
    if (connection === 'Username-Password-Authentication') {
      webAuth.login(
        {
          email,
          password,
          realm: connection,
          responseType: 'token',
        },
        (err2: any, res2: any) => {
          if (err2) {
            console.error('login', err2);
          }
        }
      );
    } else {
      webAuth.authorize({
        connection,
        responseType: 'token',
      });
    }
  }

  useUpdateEffect(() => {
    setSigninEnabled(
      email.length > 0 && emailValidation(email) && password.length > 0
    );
  }, [email, password]);

  useUpdateEffect(() => {
    sessionStorage.setItem('auth-redirect-url', dialogState.redirect);
  }, [dialogState.redirect]);

  const padding = '24px';
  const margin = '20px';

  return (
    <Dialog
      open={dialogState.open}
      aria-labelledby="customized-dialog-title"
      onClose={() => setDialogState({ open: false, redirect: '/' })}
    >
      <div
        css={`
          width: 450px;
          //box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.12);
          border-radius: 16px;
          overflow: hidden;
        `}
      >
        <div
          css={`
            width: 450px;
            background: #f7f7f7;
            padding: ${padding};
          `}
        >
          <div
            css={`
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: ${margin};
            `}
          >
            <div
              css={`
                font-style: normal;
                font-weight: normal;
                font-size: 16px;

                color: #2e4063;
              `}
            >
              Welcome to AIDA
            </div>
            <IconButton
              aria-label="close"
              css={closebtncss}
              onClick={() => setDialogState({ open: false, redirect: '/' })}
              data-cy="landing-dialog-closebtn"
            >
              <IconClose
                css={`
                  fill: #2e4063;
                `}
              />
            </IconButton>
          </div>
          <div
            css={`
              font-style: normal;
              font-weight: 500;
              font-size: 34px;
              line-height: 41px;
              letter-spacing: 0.2px;
              color: #2e4063;
              margin-bottom: 26px;
              mark {
                color: white;
                background: #5accbf;
                padding-left: 5px;
                padding-right: 5px;
              }
            `}
          >
            <Highlighter
              autoEscape
              searchWords={['Log in']}
              textToHighlight="Log in to continue your data exploration journey in AIDA."
            />
          </div>
          <div
            css={`
              width: 100%;
              height: 1px;
              background-color: #d7d8d9;
              margin-bottom: 26px;
            `}
          />
          <div
            css={`
              display: flex;
              align-items: center;
              flex-direction: column;
              justify-content: space-between;
            `}
          >
            <button
              type="button"
              css={socialloginbuttoncss}
              onClick={() => doLogin('google-oauth2')}
            >
              <LogoGoogle /> Log in with Google
            </button>
            <button
              type="button"
              css={socialloginbuttoncss}
              onClick={() => doLogin('linkedin')}
            >
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                css={`
                  margin-left: 10px;
                  transform: scale(1.3);
                `}
              >
                <path
                  fill="#0a66c2"
                  d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z"
                />
              </svg>
              Log in with LinkedIn
            </button>
            <div
              css={`
                margin: 8px 0 20px 0;
              `}
            >
              <div
                css={`
                  display: flex;
                  align-items: center;
                  gap: 5px;
                  justify-content: center;
                `}
              >
                <div
                  css={`
                    border: 0.3px solid #a1aebd;
                    width: 120px;
                    height: 0px;
                  `}
                />
                <div
                  css={`
                    color: #a1aebd;
                    font-size: 12px;
                  `}
                >
                  or log in with email
                </div>
                <div
                  css={`
                    border: 0.3px solid #a1aebd;
                    width: 120px;
                    height: 0px;
                  `}
                />
              </div>
            </div>
            <div
              css={`
                width: 100%;

                > .MuiTextField-root {
                  margin-bottom: 20px;
                }
              `}
            >
              <OnboardingTextInput
                type="email"
                label="Email"
                value={email}
                setValue={setEmail}
              />
              <OnboardingTextInput
                type="password"
                label="Password"
                value={password}
                setValue={setPassword}
              />
              <button
                type="button"
                css={actionbuttoncss}
                disabled={!signinEnabled}
                style={{ marginBottom: 20 }}
                onClick={() => doLogin('Username-Password-Authentication')}
              >
                Log in
              </button>
            </div>
            <div
              css={`
                color: #2e4063;
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 24px;

                > a {
                  text-decoration: underline;
                }
              `}
            >
              Don&apos;t have an account?{' '}
              <Link
                to={Path.webshop.onboarding.replace(':page?', '')}
                onClick={() => setDialogState({ open: false, redirect: '/' })}
              >
                sign up
              </Link>
            </div>
            <Typography variant="caption" align="justify" color="textPrimary">
              by signing in/up you agree with AIDA&apos;s{' '}
              <NavLink to={Path.general.privacy} css={policyLink}>
                terms of services and privacy policy
              </NavLink>
            </Typography>
          </div>
        </div>
        <div
          css={`
            width: 450px;

            background: #2e4063;
            border-radius: 0px 0px 16px 16px;
            padding: ${padding};
          `}
        >
          <div
            css={`
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 16px;
              letter-spacing: 0.4px;
              color: #f7f7f7;
            `}
          >
            Important notice:
            <br /> AIDA is powered by open data that is published in version 2
            <br /> of the{' '}
            <a
              target="_blank"
              rel="noreferrer"
              css={iatiStandardLink}
              href={Path.other.iatiStandardSchema}
            >
              IATI Standard Schema
            </a>{' '}
            and above.
          </div>
        </div>
      </div>
    </Dialog>
  );
};
