import React from 'react';
import get from 'lodash/get';
import { Path } from 'app/const/Path';
import { useHistory } from 'react-router-dom';
import { useDebounce, useUpdateEffect, useUnmount } from 'react-use';
import { useStoreActions, useStoreState } from 'app/state/store/hooks';
import { SearchComponentLayout } from 'app/components/Filter/common/Search/layout';
import {
  datapath,
  countpath,
  searchNavItems,
  SearchComponentProps,
} from 'app/components/Filter/common/Search/data';

export function SearchComponent(props: SearchComponentProps) {
  const history = useHistory();
  const targetRef = React.useRef<HTMLDivElement>(null);
  const [resultType, setResultType] = React.useState(searchNavItems[0]);
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });
  const value = useStoreState((state) => state.syncSearch.value);
  const setValue = useStoreActions((action) => action.syncSearch.setValue);

  const loading = useStoreState(
    (state) =>
      state.searchActivities.loading ||
      state.searchCountries.loading ||
      state.searchOrganisations.loading ||
      state.searchPublishers.loading ||
      state.searchDonors.loading
  );
  const searchData = useStoreState((state) => ({
    Activities: {
      count: get(state.searchActivities, countpath, 0),
      data: get(state.searchActivities, datapath, []),
    },
    Countries: {
      count: get(state.searchCountries, countpath, 0),
      data: get(state.searchCountries, datapath, []),
    },
    Organisations: {
      count: get(state.searchOrganisations, countpath, 0),
      data: get(state.searchOrganisations, datapath, []),
    },
    Publishers: {
      count: get(state.searchPublishers, countpath, 0),
      data: get(state.searchPublishers, datapath, []),
    },
    Donors: {
      count: get(state.searchDonors, countpath, 0),
      data: get(state.searchDonors, datapath, []),
    },
    All: {
      count:
        get(state.searchActivities, countpath, 0) +
        get(state.searchCountries, countpath, 0) +
        get(state.searchOrganisations, countpath, 0) +
        get(state.searchPublishers, countpath, 0) +
        get(state.searchDonors, countpath, 0),
      data: [
        ...get(state.searchActivities, datapath, []),
        ...get(state.searchCountries, datapath, []),
        ...get(state.searchOrganisations, datapath, []),
        ...get(state.searchPublishers, datapath, []),
        ...get(state.searchDonors, datapath, []),
      ],
    },
  }));
  const searchActions = useStoreActions((actions) => ({
    activitiesAction: actions.searchActivities.fetch,
    countriesAction: actions.searchCountries.fetch,
    organisationsAction: actions.searchOrganisations.fetch,
    publishersAction: actions.searchPublishers.fetch,
    donorsAction: actions.searchDonors.fetch,
  }));
  const clearSearchActions = useStoreActions((actions) => ({
    activitiesAction: actions.searchActivities.clear,
    countriesAction: actions.searchCountries.clear,
    organisationsAction: actions.searchOrganisations.clear,
    publishersAction: actions.searchPublishers.clear,
    donorsAction: actions.searchDonors.clear,
  }));

  function clearSearch() {
    setValue('');
    clearSearchActions.activitiesAction();
    clearSearchActions.countriesAction();
    clearSearchActions.organisationsAction();
    clearSearchActions.publishersAction();
    clearSearchActions.donorsAction();
  }

  function callActivitiesAction(q: string) {
    searchActions.activitiesAction({
      values: {
        q,
      },
    });
    searchActions.countriesAction({
      values: {
        q,
      },
    });
    searchActions.organisationsAction({
      values: {
        q,
      },
    });
    searchActions.publishersAction({
      values: {
        q,
      },
    });
    searchActions.donorsAction({
      values: {
        q,
      },
    });
  }

  useDebounce(
    () => {
      if (value.length > 3 && !props.stayOpen) {
        callActivitiesAction(value);
      }
    },
    500,
    [value]
  );

  useUnmount(() => {
    if (props.stayOpen) {
      clearSearch();
    }
  });

  React.useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: get(targetRef, 'current.offsetWidth', 0),
        height: get(targetRef, 'current.offsetHeight', 0),
      });
    }
  }, []);

  // Activities load more items
  const [ActivitiesPage, setActivitiesPage] = React.useState(0);
  useUpdateEffect(() => {
    searchActions.activitiesAction({
      values: {
        q: value,
        page: ActivitiesPage,
      },
      addOnData: true,
    });
  }, [ActivitiesPage]);

  // Countries load more items
  const [CountriesPage, setCountriesPage] = React.useState(0);
  useUpdateEffect(() => {
    searchActions.countriesAction({
      values: {
        q: value,
        page: CountriesPage,
      },
      addOnData: true,
    });
  }, [CountriesPage]);

  // Organisations load more items
  const [OrganisationsPage, setOrganisationsPage] = React.useState(0);
  useUpdateEffect(() => {
    searchActions.organisationsAction({
      values: {
        q: value,
        page: OrganisationsPage,
      },
      addOnData: true,
    });
  }, [OrganisationsPage]);

  // Publishers load more items
  const [PublishersPage, setPublishersPage] = React.useState(0);
  useUpdateEffect(() => {
    searchActions.publishersAction({
      values: {
        q: value,
        page: PublishersPage,
      },
      addOnData: true,
    });
  }, [PublishersPage]);

  // Donors load more items
  const [DonorsPage, setDonorsPage] = React.useState(0);
  useUpdateEffect(() => {
    searchActions.donorsAction({
      values: {
        q: value,
        page: DonorsPage,
      },
      addOnData: true,
    });
  }, [DonorsPage]);

  // load more items of result type selected
  function loadMore() {
    switch (resultType) {
      case searchNavItems[0]:
        setActivitiesPage(ActivitiesPage + 1);
        break;
      case searchNavItems[1]:
        setCountriesPage(CountriesPage + 1);
        break;
      case searchNavItems[2]:
        setOrganisationsPage(OrganisationsPage + 1);
        break;
      case searchNavItems[3]:
        setPublishersPage(PublishersPage + 1);
        break;
      case searchNavItems[4]:
        setDonorsPage(DonorsPage + 1);
        break;
      case searchNavItems[5]:
        setActivitiesPage(ActivitiesPage + 1);
        setCountriesPage(CountriesPage + 1);
        setOrganisationsPage(OrganisationsPage + 1);
        setPublishersPage(PublishersPage + 1);
        setDonorsPage(DonorsPage + 1);
        break;
      default:
        break;
    }
  }

  function onClickAway(event: React.MouseEvent<Document>) {
    if (!props.stayOpen) {
      clearSearch();
      props.close();
    }
  }

  // on enter pressed route changes to /search-results
  function onEnterPress() {
    if (value.length > 3) {
      history.push(`${Path.search}?q=${value}`);
    }
  }

  const renderedResults = get(searchData, `[${resultType}].data`, []);
  const renderedResultsCount = get(searchData, `[${resultType}].count`, 0);

  const hasMoreOfType = renderedResults.length + 10 - renderedResultsCount < 10;

  return (
    <SearchComponentLayout
      value={value}
      loading={loading}
      close={props.close}
      loadMore={loadMore}
      setValue={setValue}
      targetRef={targetRef}
      searchData={searchData}
      resultType={resultType}
      width={dimensions.width}
      onClickAway={onClickAway}
      stayOpen={props.stayOpen}
      setResultType={setResultType}
      hasMoreOfType={hasMoreOfType}
      onEnterPress={!props.stayOpen ? onEnterPress : undefined}
    />
  );
}
