import React from 'react';
import Providers from 'app/Providers';
import { ModuleRoutes } from 'app/Routes';
import { Drawer } from 'app/components/Drawer';
import { AppDialogs } from 'app/components/Dialogs';
import { HelmetProvider } from 'react-helmet-async';
import { CookieDialog } from 'app/components/Feedback/CookieDialog';

export function App() {
  return (
    <HelmetProvider>
      <Providers>
        <Drawer />
        <ModuleRoutes />
        <AppDialogs />
        <CookieDialog open data-testid="cookie-dialog" />
      </Providers>
    </HelmetProvider>
  );
}
