// @ts-nocheck

import { createTheme } from '@material-ui/core/styles';
import { Palette } from '@material-ui/core/styles/createPalette';

import {
  FontStyle,
  TypographyOptions,
} from '@material-ui/core/styles/createTypography';

interface Icon {
  black: string;
}

interface ProjectPaletteProps extends Palette {
  icon: Icon;
}

const ZERO_IMPORTANT = '0!important';

export const TextStyle: FontStyle = {
  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
};

export const ProjectPalette: ProjectPaletteProps = {
  tonalOffset: 0.2,
  contrastThreshold: 3,
  background: {
    paper: '#f7f7f7',
    default: '#ffffff',
  },
  text: {
    primary: '#2e4063',
    secondary: '#30c2b0',
    disabled: 'rgba(0,0,0,0.38)',
    hint: '#2e4063',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  primary: {
    light: '#2e4063',
    main: '#2e4063',
    dark: '#2e4063',
    contrastText: '#fff',
  },
  secondary: {
    light: 'rgba(48, 194, 176, 0.5)',
    main: '#30c2b0',
    dark: '#51dbca',
    contrastText: '#fff',
  },
  common: { black: '#000', white: '#fff' },
  error: {
    light: '#e57373',
    main: '#ED6060',
    dark: '#d32f2f',
    contrastText: '#fff',
  },
  type: 'light',
  action: {
    hoverOpacity: 0.08,
    hover: 'rgba(0, 0, 0, 0.08)',
    selected: 'rgba(0, 0, 0, 0.14)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    active: 'rgba(0, 0, 0, 0.54)',
  },
};

interface ExtendedTypography extends TypographyOptions {
  accordionSummary: FontStyle;
  accordionDetail: FontStyle;
  body3: FontStyle;
}

export const ProjectTypography: ExtendedTypography = {
  h1: {
    fontFamily: TextStyle.fontFamily,
    fontSize: `${4}rem`,
    fontWeight: TextStyle.fontWeightMedium,
    letterSpacing: '-0.5px',
  },
  h2: {
    fontFamily: TextStyle.fontFamily,
    fontSize: `${2.25}rem`,
    fontWeight: TextStyle.fontWeightRegular,
    lineHeight: '1',
    marginBottom: `${3.2857142857142856}rem`,
  },
  h3: {
    fontFamily: TextStyle.fontFamily,
    fontSize: `${2.25}rem`,
    fontWeight: TextStyle.fontWeightMedium,
    lineHeight: 'normal',
    letterSpacing: 'normal',
    fontStyle: 'normal',
  },
  h4: {
    fontFamily: TextStyle.fontFamily,
    fontSize: `${2.125}rem`,
    fontWeight: TextStyle.fontWeightMedium,
    letterSpacing: '0.2px',
  },
  h5: {
    fontFamily: TextStyle.fontFamily,
    fontSize: `${0.875}rem`,
    fontWeight: TextStyle.fontWeightRegular,
    lineHeight: '1.5',
  },
  h6: {
    fontFamily: TextStyle.fontFamily,
    fontSize: `${1.25}rem`,
    fontWeight: TextStyle.fontWeightMedium,
    lineHeight: '1',
    letterSpacing: 'normal',
  },
  subtitle1: {
    fontFamily: TextStyle.fontFamily,
    fontSize: `${0.875}rem`,
    fontWeight: TextStyle.fontWeightRegular,
    lineHeight: '1.2',
    letterSpacing: '0.25px',
  },
  subtitle2: {
    fontFamily: TextStyle.fontFamily,
    fontSize: `${1.1428571428571428}rem`,
    fontWeight: TextStyle.fontWeightMedium,
  },
  button: {
    textTransform: 'uppercase',
    color: ProjectPalette.text.primary,
    fontFamily: TextStyle.fontFamily,
    fontSize: `${0.875}rem`,
    fontWeight: 500,
  },
  caption: {
    color: ProjectPalette.primary.light,
    fontFamily: TextStyle.fontFamily,
    lineHeight: 1.3,
    fontSize: `${0.75}rem`,
    fontWeight: TextStyle.fontWeightRegular,
    font: `${TextStyle.fontWeightRegular} ${0.75}rem/1.3 ${
      TextStyle.fontFamily
    }`,
  },
  body1: {
    color: ProjectPalette.text.primary,
    fontFamily: TextStyle.fontFamily,
    lineHeight: `1.5`,
    fontSize: `${1}rem`,
    letterSpacing: '0.5px',
    fontWeight: 300,
  },
  body2: {
    color: ProjectPalette.text.primary,
    fontFamily: TextStyle.fontFamily,
    lineHeight: `24px`,
    fontSize: `${0.875}rem`,
    fontWeight: TextStyle.fontWeightRegular,
  },
  accordionSummary: {
    color: ProjectPalette.text.primary,
    fontFamily: TextStyle.fontFamily,
    lineHeight: `${1.71429}em`,
    fontSize: `${1}rem`,
    fontWeight: 500,
  },
  accordionDetail: {
    color: ProjectPalette.text.primary,
    fontFamily: TextStyle.fontFamily,
    lineHeight: `${1.71429}em`,
    fontSize: `${14}px`,
    fontWeight: 300,
    letterSpacing: '0.5px',
  },
  body3: {
    color: '#B6B6B6',
    fontFamily: TextStyle.fontFamily,
    lineHeight: `${22}px`,
    fontSize: `${11}px`,
    fontWeight: 300,
    letterSpacing: '0.5px',
  },
};

export default createTheme({
  overrides: {
    // Name of the component ⚛️
    /* MuiCssBaseline: {
      // Name of the rule
      '@global': {
        '*, *::before, *::after': {
          transition: 'none !important',
          animation: 'none !important',
        },
      },
    }, */
    MuiPaper: {
      root: {
        // borderRadius: '16px!important',
      },
      round: {
        // borderRadius: '16px!important',
      },
    },
    MuiTabs: {
      indicator: {
        height: '4px',
      },
    },
    MuiCheckbox: {
      root: {
        padding: '0',
        marginRight: '8px',
        color: '#2E4063',
        fill: '#2E4063',
      },
    },
    overrides: {
      MuiSwitch: {
        switchBase: {
          color: 'grey',
          '&$checked': {
            color: 'white',
          },
          '&$checked + $track': {
            backgroundColor: '#30C2B0',
            input: 'white',
            opacity: 10,
          },
        },
        colorPrimary: { checked: { color: 'white' } },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
      },
    },
    MuiTableHead: {
      root: { borderTop: 'solid 1px #EBEBEB', heigth: '51px' },
      MuiTableRow: { root: { padding: '9px 0' } },
    },
    MuiTableRow: {
      root: {
        height: '10px',
      },
    },
    MuiTableCell: {
      root: { padding: '9px 0' },
    },
    MuiTab: {
      wrapper: {
        color: ProjectPalette.common.black,
        fontWeight: 600,
        fontSize: '14px',
      },

      // textColorInherit: {
      //   color: ProjectPalette.text.secondary,
      //   '&$selected': {
      //     color: ProjectPalette.primary.main,
      //   },
      //   '&$disabled': {
      //     color: ProjectPalette.text.disabled,
      //   },
      // },
    },
    MuiCardHeader: {
      root: {
        paddingBottom: 0,
      },
    },
    MuiAppBar: {
      root: {},
    },
    MuiAccordion: {
      root: {
        backgroundColor: 'transparent',
        width: '100%',
        marginTop: '16px!important',
        marginBottom: '16px!important',
        // boxShadow: 'initial',
        '&:before': {
          display: 'none',
        },

        // note: use this snippet if you want to customize the expanded state of the accordion component
        /* '&$expanded': {
          marginTop: ZERO_IMPORTANT,
          marginBottom: ZERO_IMPORTANT,
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
        }, */
      },
    },
    MuiIconButton: {
      root: {
        padding: 10,
      },
    },
    MuiDialog: {
      root: {
        borderRadius: '16px',
      },
      paper: {
        borderRadius: '16px',
        backgroundColor: 'transparent',
      },
    },
    MuiAccordionSummary: {
      root: {
        height: '48px',
        marginTop: ZERO_IMPORTANT,
        marginBottom: ZERO_IMPORTANT,
        backgroundColor: ProjectPalette.background.paper,
        minHeight: 'initial!important',

        zIndex: 2,
      },
      content: {
        marginTop: ZERO_IMPORTANT,
        marginBottom: ZERO_IMPORTANT,
        width: '100%',
      },
    },
    MuiCollapse: {
      container: {
        overflow: 'hidden',
      },
    },
    MuiBreadcrumbs: {
      separator: {
        marginLeft: 3,
        marginRight: 3,
        color: '#2E4063',
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: '16px',
        overflow: 'hidden',
      },
    },
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: { xs: 0, lg: 1280, sm: 600, xl: 1920, md: 960 },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
      '@media (min-width:0px) and (orientation: landscape)': { minHeight: 48 },
      '@media (min-width:600px)': { minHeight: 48 },
    },
  },
  // shadows: [
  //   'none',
  //   '0px 1px 3px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
  //   '0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
  //   '0px 1px 8px 0px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 3px 3px -2px rgba(0, 0, 0, 0.12)',
  //   '0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
  //   '0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
  //   '0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
  //   '0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
  //   '0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
  // ],
  direction: 'ltr',

  typography: ProjectTypography,
  zIndex: {
    modal: 1300,
    snackbar: 1400,
    drawer: 1200,
    appBar: 1100,
    mobileStepper: 1000,
    tooltip: 1500,
  },
  shape: { borderRadius: 4 },
  spacing: 8,
  palette: ProjectPalette,
});
