import React from 'react';
import { Route } from 'react-router-dom';
import { AppBar } from 'app/components/AppBar';

interface RouteWithAppBarProps {
  path?: string;
  exact?: boolean;
  forceHideAppBarFilter?: boolean;
  children: React.ReactNode | React.ReactNode[];
}

export function RouteWithAppBar(props: RouteWithAppBarProps) {
  return (
    <Route exact={props.exact} path={props.path}>
      <AppBar forceHideFilter={props.forceHideAppBarFilter} />
      {props.children}
    </Route>
  );
}
