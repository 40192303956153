export interface User {
  user_id: string;
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  locale: string;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
  created_at: string;
  last_login: string;
  user_metadata: UserMetaData;
  groups?: GroupModel[];
  role?: GroupModel[];
  app_metadata: {
    publisherID?: string;
    publisherInfo?: {
      title: string;
      publisher_organization_type: string;
      publisher_iati_id: string;
    };
    role?: string;
    pricingPlan?: PricingPlanModel;
    paymentMethod?: string;
    jobTitle?: string;
  };
}

export interface PricingPlanModel {
  name: string;
  licenses: number;
  support: boolean;
  startDate?: Date;
  isFreeTrial?: boolean;
}

export interface UserMetaData {
  birth_date?: string;
  phone_number?: string;
}

export interface GroupModel {
  _id: string;
  name: string;
  description: string;
  members_count: number;
  members: GroupMemberModel[];
}

export const userMock: any = {
  user_id: '',
  email: '',
  email_verified: true,
  family_name: '',
  given_name: '',
  locale: '',
  name: '',
  nickname: '',
  picture: null,
  sub: '',
  updated_at: '',
  created_at: '',
  groups: [],
  last_login: '',
  role: [],
  birth_date: '',
  phone_number: '',
};

export interface GroupMemberModel {
  user_id: string;
  email: string;
  picture: string;
  name: string;
  app_metadata: any;
}
