import React from 'react';
import { format } from 'date-fns';
import Box from '@material-ui/core/Box';
import enGBLocale from 'date-fns/locale/en-GB';
import TextField from '@material-ui/core/TextField';
import { ThemeProvider } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { SimpleInterpolation } from 'styled-components/macro';
import Button, { ButtonProps } from '@material-ui/core/Button';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import RestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { createStyles, theme } from 'app/components/DatePicker/style';
import { StaticDatePicker, LocalizationProvider } from '@material-ui/pickers';

interface DatePickerProps {
  dateRange: { startDate: null | Date; endDate: null | Date }[];
  handleDateChange: Function;
  handleResetClick: Function;
}

// refer to https://material-ui-pickers.dev/localization/date-fns when implementing language switch
export const DatePicker = (props: DatePickerProps) => {
  const [fromActive, setFromActive] = React.useState(true);
  const [toActive, setToActive] = React.useState(false);
  const [tempDateRange, setTempDateRange] = React.useState([
    {
      startDate: props.dateRange[0].startDate,
      endDate: props.dateRange[0].endDate,
    },
  ]);
  const styles = createStyles(fromActive, toActive);

  React.useEffect(() => {
    setTempDateRange([
      {
        startDate: props.dateRange[0].startDate,
        endDate: props.dateRange[0].endDate,
      },
    ]);
  }, [props]);

  React.useEffect(() => {
    handleExpandYearsClick();
  }, [fromActive]);

  function handleFromClick() {
    if (!fromActive) {
      setFromActive(!fromActive);
      setToActive(!toActive);
    }
  }

  function handleToClick() {
    if (!toActive) {
      setToActive(!toActive);
      setFromActive(!fromActive);
    }
  }

  function handleDateTempChange(newDate: Date) {
    setTempDateRange((prevState) => {
      const startDate = fromActive ? newDate : prevState[0].startDate;
      const endDate = toActive ? newDate : prevState[0].endDate;
      return [{ startDate, endDate }];
    });
  }

  function getCurrentYear() {
    if (fromActive && props.dateRange[0].startDate) {
      return props.dateRange[0].startDate.getFullYear();
    }
    if (toActive && props.dateRange[0].endDate) {
      return props.dateRange[0].endDate.getFullYear();
    }
    return null;
  }

  function handleExpandYearsClick() {
    const currentYearElement = document.querySelector(
      `[data-mui-test="year-${getCurrentYear()}"]`
    );
    (currentYearElement as HTMLElement)?.focus();
    currentYearElement?.scrollIntoView({ block: 'end', behavior: 'smooth' });
  }

  const expandButton = document.querySelector(
    '[data-mui-test="calendar-view-switcher"]'
  );

  if (expandButton) {
    expandButton.addEventListener(
      'click',
      () => {
        setTimeout(handleExpandYearsClick, 400);
      },
      false
    );
  }

  return (
    <div css={styles.container}>
      <IconButton
        aria-label="reset"
        onClick={() => props.handleResetClick()}
        css={styles.reset}
      >
        <RestoreIcon color="primary" />
      </IconButton>
      <div css={styles.inputContainer}>
        {/* ------------------------------------------------- */}
        {/* from */}
        <div css={styles.formControl}>
          <Typography variant="body2" css={styles.label}>
            Date (From)
          </Typography>
          <InputButton
            id="from"
            styles={styles.from}
            active={fromActive}
            onClick={() => handleFromClick()}
            variant="contained"
          >
            {tempDateRange[0].startDate &&
              format(tempDateRange[0].startDate, 'MMM d, yyyy')}
          </InputButton>
        </div>
        {/* ------------------------------------------------- */}
        {/* to */}
        <div css={styles.formControl}>
          <Typography variant="body2" css={styles.label}>
            Date (To)
          </Typography>
          <InputButton
            id="to"
            styles={styles.to}
            active={toActive}
            onClick={() => handleToClick()}
            variant="contained"
          >
            {tempDateRange[0].endDate &&
              format(tempDateRange[0].endDate, 'MMM d, yyyy')}
          </InputButton>
        </div>
      </div>
      <LocalizationProvider dateAdapter={DateFnsUtils} locale={enGBLocale}>
        <ThemeProvider theme={theme}>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={
              fromActive ? tempDateRange[0].startDate : tempDateRange[0].endDate
            }
            onChange={(newDate: any) => {
              props.handleDateChange(newDate, fromActive, toActive);
              handleDateTempChange(newDate);
            }}
            renderInput={(textFieldProps: any) => (
              <TextField {...textFieldProps} />
            )}
          />
        </ThemeProvider>
      </LocalizationProvider>
      <Box height="32px" />
    </div>
  );
};

interface InputButtonProps extends ButtonProps {
  styles: readonly SimpleInterpolation[];
  active: boolean;
}

const InputButton = (props: InputButtonProps) => {
  const { styles, active, ...buttonProps } = props;
  return (
    <Button
      color="primary"
      {...buttonProps}
      css={props.styles}
      variant="contained"
    >
      {props.children}
    </Button>
  );
};
