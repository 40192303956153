// @ts-ignore
import { atom, RecoilState } from 'recoil';
import { CountryItemProps, countryList } from 'app/state/data/Countries';

export interface AppState {
  countryList: CountryItemProps[];
}

function LoadAppStateFromLocalStorage(): AppState {
  const stringifiedJSON: string | null = window.localStorage.getItem(
    LocalStorageKey.APP_STATE
  );

  if (typeof stringifiedJSON === 'string') {
    return JSON.parse(stringifiedJSON);
  }

  return {
    countryList: countryList,
  };
}

export enum LocalStorageKey {
  APP_STATE = 'APP_STATE',
}

export const initialAppState: RecoilState<AppState> = atom({
  key: 'initialAppState',
  default: LoadAppStateFromLocalStorage(),
});
