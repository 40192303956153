import React from 'react';
import { css } from 'styled-components/macro';
import { FilledInput, InputAdornment } from '@material-ui/core';

interface AmountInputProps {
  left: boolean;
  value: number;
  endAdornmentText: string;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export const AmountInput = (props: AmountInputProps) => {
  const { value, endAdornmentText, handleChange } = props;

  const styles = (left: boolean) => {
    return {
      input: css`
        width: calc(100% - 7.5px);
        border-radius: 18px;
        height: 36px;
        background-color: #ededf6;
        margin-right: ${left ? 7.5 : 0}px;
        margin-left: ${left ? 0 : 7.5}px;
        padding-right: 16px;

        .MuiFilledInput-input {
          padding: unset;
          padding-left: 16px;
        }

        .MuiInputBase-input {
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.25px;
          color: #2e4063;
        }
      `,
      endAdornment: css`
        p {
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.4px;
          color: #626262;
        }
      `,
    };
  };

  return (
    <FilledInput
      css={styles(props.left).input}
      id="amount-input"
      value={value}
      onChange={handleChange}
      type="number"
      endAdornment={
        <InputAdornment css={styles(props.left).endAdornment} position="end">
          {endAdornmentText}
        </InputAdornment>
      }
      aria-describedby="filled-weight-helper-text"
      disableUnderline
      inputProps={{
        'aria-label': 'weight',
      }}
    />
  );
};
