// A
export const AF_ACTIVITY_DATE_ISO_DATE = 'activity-date.iso-date';
export const AF_ACTIVITY_DATE_TYPE = 'activity-date.type';
export const AF_ACTIVITY_DATE_START_ACTUAL = 'activity-date.start-actual';
export const AF_ACTIVITY_DATE_START_PLANNED = 'activity-date.start-planned';
export const AF_ACTIVITY_DATE_END_ACTUAL = 'activity-date.end-actual';
export const AF_ACTIVITY_DATE_END_PLANNED = 'activity-date.end-planned';
export const AF_ACTIVITY_SCOPE_CODE = 'activity-scope.code';
export const AF_ACTIVITY_STATUS_CODE = 'activity-status.code';

// B
export const AF_BUDGET = 'budget.value-usd';

// C
export const AF_COUNTRY = 'recipient-country.code';
export const AF_COUNTRY_NAME = 'recipient-country.name';
export const AF_COUNTRY_PERC = 'recipient-country.percentage';
export const AF_COLLABORATION_TYPE_CODE = 'collaboration-type.code';

// D
export const AF_DEFAULT_AID_TYPE_CATEGORY_CODE =
  'default_aid_type_category_code';
export const AF_DEFAULT_AID_TYPE_CODE = 'default-aid-type.code';
export const AF_DEFAULT_AID_TYPE_VOCABULARY = 'default-aid-type.vocabulary';
export const AF_DEFAULT_CURRENCY = 'default-currency';
export const AF_DEFAULT_FINANCE_CODE = 'default-finance-type.code';
export const AF_DEFAULT_FLOW_TYPE_CODE = 'default-flow-type.code';
export const AF_DEFAULT_LANG = 'lang';
export const AF_DESCRIPTION_NARRATIVE = 'description.narrative';
export const AF_DESCRIPTION_TYPE = 'description.type';
export const AF_DOCUMENT_LINK_CATEGORY_CODE = 'document-link.category.code';
export const AF_DEFAULT_TIED_STATUS_CODE = 'default-tied-status.code';

// H
export const AF_HUMANITARIAN = 'humanitarian';
export const AF_HUMANITARIAN_SCOPE_NARRATIVE = 'humanitarian-scope.narrative';
export const AF_HUMANITARIAN_SCOPE_TYPE = 'humanitarian-scope.code';
export const AF_HUMANITARIAN_SCOPE_VOCABULARY = 'humanitarian-scope.vocabulary';
export const AF_HUMANITARIAN_SCOPE_VOCABULARY_URI =
  'humanitarian-scope.vocabulary.uri';

// I
export const AF_IATI_IDENTIFIER = 'iati_identifier';
export const AF_IATI_VERSION = 'dataset.extras.iati_version';

// L
export const AF_LAST_UPDATED_DATETIME = 'last-updated-datetime';

// O
export const AF_OTHER_IDENTIFIER_TYPE = 'other-identifier.type';

// P
export const AF_PARTICIPATING_ORG_NARRATIVE = 'participating-org.narrative';
export const AF_PARTICIPATING_ORG_NARRATIVE_LANG =
  'participating-org.narrative.lang';
export const AF_PARTICIPATING_ORG_NARRATIVE_TEXT =
  'participating-org.narrative.text';
export const AF_PARTICIPATING_ORG_REF = 'participating-org.ref';
export const AF_PARTICIPATING_ORG_ROLE = 'participating-org.role';
export const AF_PARTICIPATING_ORG_TYPE = 'participating-org.type';
export const AF_POLICY_MARKER_CODE = 'policy-marker.code';

// R
export const AF_REGION = 'recipient-region.code';
export const AF_REGION_NAME = 'recipient-region.name';
export const AF_REGION_PERC = 'recipient-region.percentage';
export const AF_REGION_VOCABULARY = 'recipient-region.vocabulary';
export const AF_REGION_VOCABULARY_URI = 'recipient-region.vocabulary.uri';
export const AF_REPORTING_ORG_NARRATIVE = 'reporting-org.narrative';
export const AF_REPORTING_ORG_REF = 'reporting-org.ref';
export const AF_REPORTING_ORG_TYPE_CODE = 'reporting-org.type';
export const AF_REPORTING_ORG_TYPE_NAME = 'reporting-org.type.name';
export const AF_REPORTING_ORG_SECONDARY_REPORTER =
  'reporting-org.secondary-reporter';
export const AF_RESULT_TITLE_NARRATIVE = 'result.title.narrative';
export const AF_RESULT_TYPE = 'result.type';

// S
export const AF_SECTOR = 'sector.code';
export const AF_SECTOR_NARRATIVE = 'sector.narrative';
export const AF_SECTOR_PERC = 'sector.percentage';
export const AF_SECTOR_VOCABULARY = 'sector.vocabulary';
export const AF_SECTOR_VOCABULARY_URI = 'sector.vocabulary.uri';

// T
export const AF_TAG_NARRATIVE = 'tag.narrative';
export const AF_TITLE_NARRATIVE = 'title.narrative';
export const AF_TRANSACTION = 'transaction.value-usd';
export const AF_TRANSACTION_AID_TYPE_CODE = 'transaction.aid-type.code';
export const AF_TRANSACTION_AID_TYPE_VOCABULARY =
  'transaction.aid-type.vocabulary';
export const AF_TRANSACTION_COUNTRY = 'transaction.recipient-country.code';
export const AF_TRANSACTION_DESCRIPTION_NARRATIVE =
  'transaction.description.narrative';
export const AF_TRANSACTION_DISBURSEMENT_CHANNEL_CODE =
  'transaction.disbursement-channel.code';
export const AF_TRANSACTION_FINANCE_TYPE_CODE = 'transaction.finance-type.code';
export const AF_TRANSACTION_FLOW_TYPE_CODE = 'transaction.flow-type.code';
export const AF_TRANSACTION_HUMANITARIAN = 'transaction.humanitarian';
export const AF_TRANSACTION_PROVIDER_ORG_REF = 'transaction.provider-org.ref';
export const AF_TRANSACTION_PROVIDER_ORG_TYPE = 'transaction.provider-org.type';
export const AF_TRANSACTION_PROVIDER_ORG_NARRATIVE_TEXT =
  'transaction.provider-org.narrative.text';
export const AF_TRANSACTION_PROVIDER_ORG_PROVIDER_ACTIVITY_ID =
  'transaction.provider-org.provider-activity-id';
export const AF_TRANSACTION_RECEIVER_ORG_REF = 'transaction.receiver-org.ref';
export const AF_TRANSACTION_RECEIVER_ORG_TYPE = 'transaction.receiver-org.type';
export const AF_TRANSACTION_RECEIVER_ORG_NARRATIVE =
  'transaction.receiver-org.narrative';
export const AF_TRANSACTION_RECEIVER_ORG_RECEIVER_ACTIVITY_ID =
  'transaction.receiver-org.receiver-activity-id';
export const AF_TRANSACTION_REF = 'transaction_ref';
export const AF_TRANSACTION_REGION = 'transaction.recipient-region.code';
export const AF_TRANSACTION_SECTOR_CODE = 'transaction.sector.code';
export const AF_TRANSACTION_SECTOR_VOCABULARY = 'transaction.sector.vocabulary';
export const AF_TRANSACTION_TIED_STATUS_CODE = 'transaction.tied-status.code';
export const AF_TRANSACTION_DATE_ISO_DATE =
  'transaction.transaction-date.iso-date';
export const AF_TRANSACTION_TYPE_CODE = 'transaction.transaction-type.code';
export const AF_TRANSACTION_VALUE = 'transaction.value';
export const AF_TRANSACTION_VALUE_CURRENCY = 'transaction.value.currency';
export const AF_TRANSACTION_VALUE_DATE = 'transaction.value.value-date';
export const AF_TRANSACTION_VALUE_USD_CONVERSION_RATE =
  'transaction.value-usd.conversion-rate';

export function getAPIFormattedFilters(filters: any, yearPeriod?: string) {
  let result = {};
  if (filters.countries.length > 0) {
    result = {
      ...result,
      [AF_COUNTRY]: filters.countries,
    };
  }
  if (filters.regions.length > 0) {
    result = {
      ...result,
      [AF_REGION]: filters.regions,
    };
  }
  if (filters.budget.length > 0) {
    result = {
      ...result,
      [AF_BUDGET]: filters.budget,
    };
  }
  if (filters.transactionvalue.length > 0) {
    result = {
      ...result,
      [AF_TRANSACTION]: filters.transactionvalue,
    };
  }
  if (filters.sectors.length > 0) {
    result = {
      ...result,
      [AF_SECTOR]: filters.sectors,
    };
  }
  if (filters.donors.length > 0) {
    result = {
      ...result,
      [AF_TRANSACTION_PROVIDER_ORG_REF]: filters.donors,
    };
  }
  if (filters.organisations.length > 0) {
    result = {
      ...result,
      [AF_PARTICIPATING_ORG_REF]: filters.organisations,
    };
  }
  if (filters.publishers.length > 0) {
    result = {
      ...result,
      [AF_REPORTING_ORG_REF]: filters.publishers,
    };
  }
  if (filters.period.length > 0) {
    const period = { ...filters.period[0] };

    if (typeof period.startDate === 'string') {
      period.startDate = new Date(
        period.startDate.replace(/\//g, '-')
      ).toISOString();
    }
    if (typeof period.endDate === 'string') {
      period.endDate = new Date(
        period.endDate.replace(/\//g, '-')
      ).toISOString();
    }

    result = {
      ...result,
      period: [period],
    };
  }
  if (yearPeriod && yearPeriod.length > 0) {
    result = {
      ...result,
      year_period: yearPeriod,
    };
  }
  if (filters.activitystatus.length > 0) {
    result = {
      ...result,
      [AF_ACTIVITY_STATUS_CODE]: filters.activitystatus,
    };
  }
  if (filters.activityscope.length > 0) {
    result = {
      ...result,
      [AF_ACTIVITY_SCOPE_CODE]: filters.activityscope,
    };
  }
  if (filters.documentlinkcategory.length > 0) {
    result = {
      ...result,
      [AF_DOCUMENT_LINK_CATEGORY_CODE]: filters.documentlinkcategory,
    };
  }
  if (filters.hierarchy.length > 0) {
    result = {
      ...result,
      hierarchy: filters.hierarchy,
    };
  }
  if (filters.humanitarianscopevocab.length > 0) {
    result = {
      ...result,
      [AF_HUMANITARIAN_SCOPE_VOCABULARY]: filters.humanitarianscopevocab,
    };
  }
  if (filters.humanitarian.length > 0) {
    result = {
      ...result,
      humanitarian: filters.humanitarian,
    };
  }
  if (filters.humanitarianscope.length > 0) {
    result = {
      ...result,
      [AF_HUMANITARIAN_SCOPE_TYPE]: filters.humanitarianscope,
    };
  }
  if (filters.iativersion.length > 0) {
    result = {
      ...result,
      [AF_IATI_VERSION]: filters.iativersion,
    };
  }
  if (filters.otheridentifiertype.length > 0) {
    result = {
      ...result,
      [AF_OTHER_IDENTIFIER_TYPE]: filters.otheridentifiertype,
    };
  }
  if (filters.tag.length > 0) {
    result = {
      ...result,
      [AF_TAG_NARRATIVE]: filters.tag,
    };
  }
  if (filters.defaultaidtype.length > 0) {
    result = {
      ...result,
      [AF_DEFAULT_AID_TYPE_CODE]: filters.defaultaidtype,
    };
  }
  if (filters.defaultaidtypecategory.length > 0) {
    result = {
      ...result,
      [AF_DEFAULT_AID_TYPE_CATEGORY_CODE]: filters.defaultaidtypecategory,
    };
  }
  if (filters.defaultaidtypevocabulary.length > 0) {
    result = {
      ...result,
      [AF_DEFAULT_AID_TYPE_VOCABULARY]: filters.defaultaidtypevocabulary,
    };
  }
  if (filters.currency.length > 0) {
    result = {
      ...result,
      [AF_DEFAULT_CURRENCY]: filters.currency,
    };
  }
  if (filters.defaultfinancetype.length > 0) {
    result = {
      ...result,
      [AF_DEFAULT_FINANCE_CODE]: filters.defaultfinancetype,
    };
  }
  if (filters.defaultflowtype.length > 0) {
    result = {
      ...result,
      [AF_DEFAULT_FLOW_TYPE_CODE]: filters.defaultflowtype,
    };
  }
  if (filters.language.length > 0) {
    result = {
      ...result,
      [AF_DEFAULT_LANG]: filters.language,
    };
  }
  if (filters.defaulttiedstatus.length > 0) {
    result = {
      ...result,
      [AF_DEFAULT_TIED_STATUS_CODE]: filters.defaulttiedstatus,
    };
  }
  if (filters.transactionreceiverorg.length > 0) {
    result = {
      ...result,
      [AF_TRANSACTION_RECEIVER_ORG_REF]: filters.transactionreceiverorg,
    };
  }
  if (filters.reportingorgtype.length > 0) {
    result = {
      ...result,
      [AF_REPORTING_ORG_TYPE_CODE]: filters.reportingorgtype,
    };
  }
  if (filters.collaborationtype.length > 0) {
    result = {
      ...result,
      [AF_COLLABORATION_TYPE_CODE]: filters.collaborationtype,
    };
  }
  if (filters.secondaryreporter.length > 0) {
    result = {
      ...result,
      [AF_REPORTING_ORG_SECONDARY_REPORTER]: filters.secondaryreporter,
    };
  }
  if (filters.sectorvocabulary.length > 0) {
    result = {
      ...result,
      [AF_SECTOR_VOCABULARY]: filters.sectorvocabulary,
    };
  }
  if (filters.policymarker.length > 0) {
    result = {
      ...result,
      [AF_POLICY_MARKER_CODE]: filters.policymarker,
    };
  }
  if (filters.transactionflowtype.length > 0) {
    result = {
      ...result,
      [AF_TRANSACTION_FLOW_TYPE_CODE]: filters.transactionflowtype,
    };
  }
  if (filters.transactiontiedstatus.length > 0) {
    result = {
      ...result,
      [AF_TRANSACTION_TIED_STATUS_CODE]: filters.transactiontiedstatus,
    };
  }
  if (filters.transactionhumanitarian.length > 0) {
    result = {
      ...result,
      [AF_TRANSACTION_HUMANITARIAN]: filters.transactionhumanitarian,
    };
  }
  if (filters.transactiontype.length > 0) {
    result = {
      ...result,
      [AF_TRANSACTION_TYPE_CODE]: filters.transactiontype,
    };
  }
  if (filters.transactionvaluecurrency.length > 0) {
    result = {
      ...result,
      [AF_TRANSACTION_VALUE_CURRENCY]: filters.transactionvaluecurrency,
    };
  }
  return result;
}
