import React from 'react';
import { Link } from 'react-router-dom';
import { css } from 'styled-components/macro';

interface LinkCellModel {
  value: string | string[] | number | JSX.Element;
  link: string;
  extLink?: boolean;
  colSpan?: number;
}

export const LinkCell = (props: LinkCellModel) => {
  const style = css`
    color: #2e4063;
    text-decoration: underline;
    //max-width: 767px;
    //white-space: nowrap;
    //text-overflow: ellipsis;
  `;

  if (props.extLink) {
    return (
      <a
        href={props.link}
        target="_blank"
        rel="noopener noreferrer"
        css={style}
      >
        {props.value}
      </a>
    );
  }
  return (
    <Link to={props.link} css={style}>
      {props.value}
    </Link>
  );
};
