import { APIModel } from 'app/state/api';
import { ApiCallModel } from 'app/state/api/interfaces';

const activityDetail: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/activity-detail`),
};

export default activityDetail;

export const activityDetailSectors: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/activity-detail/sunburst`),
};

export const activityDetailFinancial: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/activity-detail/financial`),
};

export const activityDetailFinancialExpand: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/activity-detail/financial/expand`),
};

export const activityDetailFinancialBudgetFlow: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/activity-detail/financial/budget-flow`
  ),
};

export const activityDetailFinancialTransactions: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/activity-detail/financial/transactions`
  ),
};

export const activityDetailFinancialPerformance: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/activity-detail/financial/performance`
  ),
};
