import {
  FilterOutlined as FilterIcon,
  PrintOutlined as PrintIcon,
  SearchOutlined as SearchIcon,
  ViewColumnOutlined as ViewColumnIcon,
} from '@material-ui/icons';
import { FileDownloadOutlined as DownloadIcon } from 'app/assets/FileDownloadOutlined';

export const icons = {
  icons: {
    SearchIcon,
    PrintIcon,
    DownloadIcon,
    ViewColumnIcon,
    FilterIcon,
  },
};
