import isEqual from 'lodash/isEqual';
import { useRecoilState } from 'recoil';
import { useMount, useUpdateEffect } from 'react-use';
import { useHistory, useLocation } from 'react-router-dom';
import { selectedFilterAtom } from 'app/state/recoil/atoms';
import {
  AF_ACTIVITY_SCOPE_CODE,
  AF_ACTIVITY_STATUS_CODE,
  AF_COUNTRY,
  AF_DEFAULT_FINANCE_CODE,
  AF_DEFAULT_AID_TYPE_CODE,
  AF_DEFAULT_AID_TYPE_VOCABULARY,
  AF_DEFAULT_CURRENCY,
  AF_DOCUMENT_LINK_CATEGORY_CODE,
  AF_HUMANITARIAN_SCOPE_TYPE,
  AF_HUMANITARIAN_SCOPE_VOCABULARY,
  AF_IATI_VERSION,
  AF_OTHER_IDENTIFIER_TYPE,
  AF_PARTICIPATING_ORG_REF,
  AF_REGION,
  AF_REPORTING_ORG_REF,
  AF_SECTOR,
  AF_TAG_NARRATIVE,
  AF_TRANSACTION_PROVIDER_ORG_REF,
  AF_DEFAULT_FLOW_TYPE_CODE,
  AF_DEFAULT_LANG,
  AF_DEFAULT_TIED_STATUS_CODE,
  AF_TRANSACTION_RECEIVER_ORG_REF,
  AF_REPORTING_ORG_TYPE_CODE,
  AF_COLLABORATION_TYPE_CODE,
  AF_REPORTING_ORG_SECONDARY_REPORTER,
  AF_SECTOR_VOCABULARY,
  AF_POLICY_MARKER_CODE,
  AF_TRANSACTION_FLOW_TYPE_CODE,
  AF_TRANSACTION_TIED_STATUS_CODE,
  AF_TRANSACTION_HUMANITARIAN,
  AF_TRANSACTION_TYPE_CODE,
  AF_TRANSACTION_VALUE_CURRENCY,
  AF_DEFAULT_AID_TYPE_CATEGORY_CODE,
} from 'app/utils/getAPIFormattedFilters';

export function useUrlFilters() {
  const history = useHistory();
  const location = useLocation();
  const [selectedFilters, setSelectedFilters] =
    useRecoilState(selectedFilterAtom);

  useMount(() => {
    const updatedSelectedFilters = { ...selectedFilters };
    const currentUrlParams = new URLSearchParams(history.location.search);
    const countries = currentUrlParams.get(AF_COUNTRY);
    const regions = currentUrlParams.get(AF_REGION);
    const sectors = currentUrlParams.get(AF_SECTOR);
    const donors = currentUrlParams.get(AF_TRANSACTION_PROVIDER_ORG_REF);
    const organisations = currentUrlParams.get(AF_PARTICIPATING_ORG_REF);
    const publishers = currentUrlParams.get(AF_REPORTING_ORG_REF);
    const activitystatus = currentUrlParams.get(AF_ACTIVITY_STATUS_CODE);
    const activityscope = currentUrlParams.get(AF_ACTIVITY_SCOPE_CODE);
    const documentlinkcategory = currentUrlParams.get(
      AF_DOCUMENT_LINK_CATEGORY_CODE
    );
    const hierarchy = currentUrlParams.get('hierarchy');
    const humanitarianscopevocab = currentUrlParams.get(
      AF_HUMANITARIAN_SCOPE_VOCABULARY
    );
    const humanitarian = currentUrlParams.get('humanitarian');
    const humanitarianscope = currentUrlParams.get(AF_HUMANITARIAN_SCOPE_TYPE);
    const iativersion = currentUrlParams.get('iativersion');
    const otheridentifiertype = currentUrlParams.get(AF_OTHER_IDENTIFIER_TYPE);
    const tag = currentUrlParams.get(AF_TAG_NARRATIVE);
    const defaultaidtype = currentUrlParams.get(AF_DEFAULT_AID_TYPE_CODE);
    const defaultaidtypecategory = currentUrlParams.get(
      AF_DEFAULT_AID_TYPE_CATEGORY_CODE
    );
    const defaultaidtypevocabulary = currentUrlParams.get(
      AF_DEFAULT_AID_TYPE_VOCABULARY
    );
    const defaultfinancetype = currentUrlParams.get(AF_DEFAULT_FINANCE_CODE);
    const defaulttiedstatus = currentUrlParams.get(AF_DEFAULT_TIED_STATUS_CODE);
    const language = currentUrlParams.get(AF_DEFAULT_LANG);
    const defaultflowtype = currentUrlParams.get(AF_DEFAULT_FLOW_TYPE_CODE);
    const currency = currentUrlParams.get(AF_DEFAULT_CURRENCY);
    const transactionreceiverorg = currentUrlParams.get(
      AF_TRANSACTION_RECEIVER_ORG_REF
    );
    const reportingorgtype = currentUrlParams.get(AF_REPORTING_ORG_TYPE_CODE);
    const collaborationtype = currentUrlParams.get(AF_COLLABORATION_TYPE_CODE);
    const secondaryreporter = currentUrlParams.get(
      AF_REPORTING_ORG_SECONDARY_REPORTER
    );
    const sectorvocabulary = currentUrlParams.get(AF_SECTOR_VOCABULARY);
    const policymarker = currentUrlParams.get(AF_POLICY_MARKER_CODE);
    const transactionflowtype = currentUrlParams.get(
      AF_TRANSACTION_FLOW_TYPE_CODE
    );
    const transactiontiedstatus = currentUrlParams.get(
      AF_TRANSACTION_TIED_STATUS_CODE
    );
    const transactionhumanitarian = currentUrlParams.get(
      AF_TRANSACTION_HUMANITARIAN
    );
    const transactionvaluecurrency = currentUrlParams.get(
      AF_TRANSACTION_VALUE_CURRENCY
    );
    const transactiontype = currentUrlParams.get(AF_TRANSACTION_TYPE_CODE);
    const period = currentUrlParams.get('period');
    const budget = currentUrlParams.get('budget');
    const transactionvalue = currentUrlParams.get('transaction_value');

    if (countries) {
      updatedSelectedFilters.countries = countries.split(',');
    }
    if (regions) {
      updatedSelectedFilters.regions = regions.split(',');
    }
    if (sectors) {
      updatedSelectedFilters.sectors = sectors.split(',');
    }
    if (donors) {
      updatedSelectedFilters.donors = donors.split(',');
    }
    if (organisations) {
      updatedSelectedFilters.organisations = organisations.split(',');
    }
    if (publishers) {
      updatedSelectedFilters.publishers = publishers.split(',');
    }
    if (activitystatus) {
      updatedSelectedFilters.activitystatus = activitystatus.split(',');
    }
    if (activityscope) {
      updatedSelectedFilters.activityscope = activityscope.split(',');
    }
    if (documentlinkcategory) {
      updatedSelectedFilters.documentlinkcategory =
        documentlinkcategory.split(',');
    }
    if (hierarchy) {
      updatedSelectedFilters.hierarchy = hierarchy.split(',');
    }
    if (humanitarianscopevocab) {
      updatedSelectedFilters.humanitarianscopevocab =
        humanitarianscopevocab.split(',');
    }
    if (humanitarian) {
      updatedSelectedFilters.humanitarian = humanitarian.split(',');
    }
    if (humanitarianscope) {
      updatedSelectedFilters.humanitarianscope = humanitarianscope.split(',');
    }
    if (iativersion) {
      updatedSelectedFilters.iativersion = iativersion.split(',');
    }
    if (otheridentifiertype) {
      updatedSelectedFilters.otheridentifiertype =
        otheridentifiertype.split(',');
    }
    if (tag) {
      updatedSelectedFilters.tag = tag.split(',');
    }
    if (defaultaidtype) {
      updatedSelectedFilters.defaultaidtype = defaultaidtype.split(',');
    }
    if (defaultaidtypecategory) {
      updatedSelectedFilters.defaultaidtypecategory =
        defaultaidtypecategory.split(',');
    }
    if (defaultaidtypevocabulary) {
      updatedSelectedFilters.defaultaidtypevocabulary =
        defaultaidtypevocabulary.split(',');
    }
    if (defaultfinancetype) {
      updatedSelectedFilters.defaultfinancetype = defaultfinancetype.split(',');
    }
    if (currency) {
      updatedSelectedFilters.currency = currency.split(',');
    }
    if (defaultflowtype) {
      updatedSelectedFilters.defaultflowtype = defaultflowtype.split(',');
    }
    if (language) {
      updatedSelectedFilters.language = language.split(',');
    }
    if (defaulttiedstatus) {
      updatedSelectedFilters.defaulttiedstatus = defaulttiedstatus.split(',');
    }
    if (transactionreceiverorg) {
      updatedSelectedFilters.transactionreceiverorg =
        transactionreceiverorg.split(',');
    }
    if (reportingorgtype) {
      updatedSelectedFilters.reportingorgtype = reportingorgtype.split(',');
    }
    if (collaborationtype) {
      updatedSelectedFilters.collaborationtype = collaborationtype.split(',');
    }
    if (secondaryreporter) {
      updatedSelectedFilters.secondaryreporter = secondaryreporter.split(',');
    }
    if (sectorvocabulary) {
      updatedSelectedFilters.sectorvocabulary = sectorvocabulary.split(',');
    }
    if (policymarker) {
      updatedSelectedFilters.policymarker = policymarker.split(',');
    }
    if (transactionflowtype) {
      updatedSelectedFilters.transactionflowtype =
        transactionflowtype.split(',');
    }
    if (transactiontiedstatus) {
      updatedSelectedFilters.transactiontiedstatus =
        transactiontiedstatus.split(',');
    }
    if (transactionhumanitarian) {
      updatedSelectedFilters.transactionhumanitarian =
        transactionhumanitarian.split(',');
    }
    if (transactiontype) {
      updatedSelectedFilters.transactiontype = transactiontype.split(',');
    }
    if (transactionvaluecurrency) {
      updatedSelectedFilters.transactionvaluecurrency =
        transactionvaluecurrency.split(',');
    }
    if (period) {
      const dates = period.split('-');
      updatedSelectedFilters.period = [
        // @ts-ignore
        {
          key: 'selection',
          endDate: dates[1],
          startDate: dates[0],
        },
      ];
    }
    if (budget) {
      const values = budget.split(',');
      updatedSelectedFilters.budget = values;
    }
    if (transactionvalue) {
      const values = transactionvalue.split(',');
      updatedSelectedFilters.transactionvalue = values;
    }

    setSelectedFilters(updatedSelectedFilters);
  });

  useUpdateEffect(() => {
    const currentUrlParams = new URLSearchParams(history.location.search);
    if (selectedFilters.countries.length > 0) {
      currentUrlParams.set(
        AF_COUNTRY,
        selectedFilters.countries.join(',')
      );
    } else {
      currentUrlParams.delete(AF_COUNTRY);
    }
    if (selectedFilters.regions.length > 0) {
      currentUrlParams.set(AF_REGION, selectedFilters.regions.join(','));
    } else {
      currentUrlParams.delete(AF_REGION);
    }
    if (selectedFilters.sectors.length > 0) {
      currentUrlParams.set(AF_SECTOR, selectedFilters.sectors.join(','));
    } else {
      currentUrlParams.delete(AF_SECTOR);
    }
    if (selectedFilters.donors.length > 0) {
      currentUrlParams.set(
        AF_TRANSACTION_PROVIDER_ORG_REF,
        selectedFilters.donors.join(',')
      );
    } else {
      currentUrlParams.delete(AF_TRANSACTION_PROVIDER_ORG_REF);
    }
    if (selectedFilters.organisations.length > 0) {
      currentUrlParams.set(
        AF_PARTICIPATING_ORG_REF,
        selectedFilters.organisations.join(',')
      );
    } else {
      currentUrlParams.delete(AF_PARTICIPATING_ORG_REF);
    }
    if (selectedFilters.publishers.length > 0) {
      currentUrlParams.set(
        AF_REPORTING_ORG_REF,
        selectedFilters.publishers.join(',')
      );
    } else {
      currentUrlParams.delete(AF_REPORTING_ORG_REF);
    }
    if (selectedFilters.activitystatus.length > 0) {
      currentUrlParams.set(
        AF_ACTIVITY_STATUS_CODE,
        selectedFilters.activitystatus.join(',')
      );
    } else {
      currentUrlParams.delete(AF_ACTIVITY_STATUS_CODE);
    }
    if (selectedFilters.activityscope.length > 0) {
      currentUrlParams.set(
        AF_ACTIVITY_SCOPE_CODE,
        selectedFilters.activityscope.join(',')
      );
    } else {
      currentUrlParams.delete(AF_ACTIVITY_SCOPE_CODE);
    }
    if (selectedFilters.documentlinkcategory.length > 0) {
      currentUrlParams.set(
        AF_DOCUMENT_LINK_CATEGORY_CODE,
        selectedFilters.documentlinkcategory.join(',')
      );
    } else {
      currentUrlParams.delete(AF_DOCUMENT_LINK_CATEGORY_CODE);
    }
    if (selectedFilters.hierarchy.length > 0) {
      currentUrlParams.set('hierarchy', selectedFilters.hierarchy.join(','));
    } else {
      currentUrlParams.delete('hierarchy');
    }
    if (selectedFilters.humanitarianscopevocab.length > 0) {
      currentUrlParams.set(
        AF_HUMANITARIAN_SCOPE_VOCABULARY,
        selectedFilters.humanitarianscopevocab.join(',')
      );
    } else {
      currentUrlParams.delete(AF_HUMANITARIAN_SCOPE_VOCABULARY);
    }
    if (selectedFilters.humanitarian.length > 0) {
      currentUrlParams.set(
        'humanitarian',
        selectedFilters.humanitarian.join(',')
      );
    } else {
      currentUrlParams.delete('humanitarian');
    }
    if (selectedFilters.humanitarianscope.length > 0) {
      currentUrlParams.set(
        AF_HUMANITARIAN_SCOPE_TYPE,
        selectedFilters.humanitarianscope.join(',')
      );
    } else {
      currentUrlParams.delete(AF_HUMANITARIAN_SCOPE_TYPE);
    }
    if (selectedFilters.iativersion.length > 0) {
      currentUrlParams.set(
        AF_IATI_VERSION,
        selectedFilters.iativersion.join(',')
      );
    } else {
      currentUrlParams.delete(AF_IATI_VERSION);
    }
    if (selectedFilters.otheridentifiertype.length > 0) {
      currentUrlParams.set(
        AF_OTHER_IDENTIFIER_TYPE,
        selectedFilters.otheridentifiertype.join(',')
      );
    } else {
      currentUrlParams.delete(AF_OTHER_IDENTIFIER_TYPE);
    }
    if (selectedFilters.tag.length > 0) {
      currentUrlParams.set(AF_TAG_NARRATIVE, selectedFilters.tag.join(','));
    } else {
      currentUrlParams.delete(AF_TAG_NARRATIVE);
    }
    if (selectedFilters.defaultaidtype.length > 0) {
      currentUrlParams.set(
        AF_DEFAULT_AID_TYPE_CODE,
        selectedFilters.defaultaidtype.join(',')
      );
    } else {
      currentUrlParams.delete(AF_DEFAULT_AID_TYPE_CODE);
    }
    if (selectedFilters.defaultaidtypecategory.length > 0) {
      currentUrlParams.set(
        AF_DEFAULT_AID_TYPE_CATEGORY_CODE,
        selectedFilters.defaultaidtypecategory.join(',')
      );
    } else {
      currentUrlParams.delete(AF_DEFAULT_AID_TYPE_CATEGORY_CODE);
    }
    if (selectedFilters.defaultaidtypevocabulary.length > 0) {
      currentUrlParams.set(
        AF_DEFAULT_AID_TYPE_VOCABULARY,
        selectedFilters.defaultaidtypevocabulary.join(',')
      );
    } else {
      currentUrlParams.delete(AF_DEFAULT_AID_TYPE_VOCABULARY);
    }
    if (selectedFilters.currency.length > 0) {
      currentUrlParams.set(
        AF_DEFAULT_CURRENCY,
        selectedFilters.currency.join(',')
      );
    } else {
      currentUrlParams.delete(AF_DEFAULT_CURRENCY);
    }
    if (selectedFilters.defaultfinancetype.length > 0) {
      currentUrlParams.set(
        AF_DEFAULT_FINANCE_CODE,
        selectedFilters.defaultfinancetype.join(',')
      );
    } else {
      currentUrlParams.delete(AF_DEFAULT_FINANCE_CODE);
    }
    if (selectedFilters.defaultflowtype.length > 0) {
      currentUrlParams.set(
        AF_DEFAULT_FLOW_TYPE_CODE,
        selectedFilters.defaultflowtype.join(',')
      );
    } else {
      currentUrlParams.delete(AF_DEFAULT_FLOW_TYPE_CODE);
    }
    if (selectedFilters.language.length > 0) {
      currentUrlParams.set(AF_DEFAULT_LANG, selectedFilters.language.join(','));
    } else {
      currentUrlParams.delete(AF_DEFAULT_LANG);
    }
    if (selectedFilters.defaulttiedstatus.length > 0) {
      currentUrlParams.set(
        AF_DEFAULT_TIED_STATUS_CODE,
        selectedFilters.defaulttiedstatus.join(',')
      );
    } else {
      currentUrlParams.delete(AF_DEFAULT_TIED_STATUS_CODE);
    }
    if (selectedFilters.transactionreceiverorg.length > 0) {
      currentUrlParams.set(
        AF_TRANSACTION_RECEIVER_ORG_REF,
        selectedFilters.transactionreceiverorg.join(',')
      );
    } else {
      currentUrlParams.delete(AF_TRANSACTION_RECEIVER_ORG_REF);
    }
    if (selectedFilters.reportingorgtype.length > 0) {
      currentUrlParams.set(
        AF_REPORTING_ORG_TYPE_CODE,
        selectedFilters.reportingorgtype.join(',')
      );
    } else {
      currentUrlParams.delete(AF_REPORTING_ORG_TYPE_CODE);
    }
    if (selectedFilters.collaborationtype.length > 0) {
      currentUrlParams.set(
        AF_COLLABORATION_TYPE_CODE,
        selectedFilters.collaborationtype.join(',')
      );
    } else {
      currentUrlParams.delete(AF_COLLABORATION_TYPE_CODE);
    }
    if (selectedFilters.secondaryreporter.length > 0) {
      currentUrlParams.set(
        AF_REPORTING_ORG_SECONDARY_REPORTER,
        selectedFilters.secondaryreporter.join(',')
      );
    } else {
      currentUrlParams.delete(AF_REPORTING_ORG_SECONDARY_REPORTER);
    }
    if (selectedFilters.sectorvocabulary.length > 0) {
      currentUrlParams.set(
        AF_SECTOR_VOCABULARY,
        selectedFilters.sectorvocabulary.join(',')
      );
    } else {
      currentUrlParams.delete(AF_SECTOR_VOCABULARY);
    }
    if (selectedFilters.policymarker.length > 0) {
      currentUrlParams.set(
        AF_POLICY_MARKER_CODE,
        selectedFilters.policymarker.join(',')
      );
    } else {
      currentUrlParams.delete(AF_POLICY_MARKER_CODE);
    }
    if (selectedFilters.transactionflowtype.length > 0) {
      currentUrlParams.set(
        AF_TRANSACTION_FLOW_TYPE_CODE,
        selectedFilters.transactionflowtype.join(',')
      );
    } else {
      currentUrlParams.delete(AF_TRANSACTION_FLOW_TYPE_CODE);
    }
    if (selectedFilters.transactiontiedstatus.length > 0) {
      currentUrlParams.set(
        AF_TRANSACTION_TIED_STATUS_CODE,
        selectedFilters.transactiontiedstatus.join(',')
      );
    } else {
      currentUrlParams.delete(AF_TRANSACTION_TIED_STATUS_CODE);
    }
    if (selectedFilters.transactionhumanitarian.length > 0) {
      currentUrlParams.set(
        AF_TRANSACTION_HUMANITARIAN,
        selectedFilters.transactionhumanitarian.join(',')
      );
    } else {
      currentUrlParams.delete(AF_TRANSACTION_HUMANITARIAN);
    }
    if (selectedFilters.transactiontype.length > 0) {
      currentUrlParams.set(
        AF_TRANSACTION_TYPE_CODE,
        selectedFilters.transactiontype.join(',')
      );
    } else {
      currentUrlParams.delete(AF_TRANSACTION_TYPE_CODE);
    }
    if (selectedFilters.transactionvaluecurrency.length > 0) {
      currentUrlParams.set(
        AF_TRANSACTION_VALUE_CURRENCY,
        selectedFilters.transactionvaluecurrency.join(',')
      );
    } else {
      currentUrlParams.delete(AF_TRANSACTION_VALUE_CURRENCY);
    }
    if (selectedFilters.period.length > 0) {
      currentUrlParams.set(
        'period',
        // @ts-ignore
        `${selectedFilters.period[0].startDate}-${selectedFilters.period[0].endDate}`
      );
    } else {
      currentUrlParams.delete('period');
    }
    if (selectedFilters.budget.length > 0) {
      currentUrlParams.set('budget', selectedFilters.budget.join(','));
    } else {
      currentUrlParams.delete('budget');
    }
    if (selectedFilters.transactionvalue.length > 0) {
      currentUrlParams.set(
        'transaction_value',
        selectedFilters.transactionvalue.join(',')
      );
    } else {
      currentUrlParams.delete('transaction_value');
    }
    const queryString = decodeURIComponent(currentUrlParams.toString());
    history.push({
      pathname: history.location.pathname,
      search: queryString,
    });
  }, [selectedFilters]);

  useUpdateEffect(() => {
    const updatedSelectedFilters = { ...selectedFilters };
    const currentUrlParams = new URLSearchParams(location.search);
    const countries = currentUrlParams.get(AF_COUNTRY);
    const regions = currentUrlParams.get(AF_REGION);
    const sectors = currentUrlParams.get(AF_SECTOR);
    const donors = currentUrlParams.get(AF_TRANSACTION_PROVIDER_ORG_REF);
    const organisations = currentUrlParams.get(AF_PARTICIPATING_ORG_REF);
    const publishers = currentUrlParams.get(AF_REPORTING_ORG_REF);
    const activitystatus = currentUrlParams.get(AF_ACTIVITY_STATUS_CODE);
    const activityscope = currentUrlParams.get(AF_ACTIVITY_SCOPE_CODE);
    const documentlinkcategory = currentUrlParams.get(
      AF_DOCUMENT_LINK_CATEGORY_CODE
    );
    const hierarchy = currentUrlParams.get('hierarchy');
    const humanitarianscopevocab = currentUrlParams.get(
      AF_HUMANITARIAN_SCOPE_VOCABULARY
    );
    const humanitarian = currentUrlParams.get('humanitarian');
    const humanitarianscope = currentUrlParams.get(AF_HUMANITARIAN_SCOPE_TYPE);
    const iativersion = currentUrlParams.get(AF_IATI_VERSION);
    const otheridentifiertype = currentUrlParams.get(AF_OTHER_IDENTIFIER_TYPE);
    const tag = currentUrlParams.get(AF_TAG_NARRATIVE);
    const defaultaidtype = currentUrlParams.get(AF_DEFAULT_AID_TYPE_CODE);
    const defaultaidtypecategory = currentUrlParams.get(
      AF_DEFAULT_AID_TYPE_CATEGORY_CODE
    );
    const defaultaidtypevocabulary = currentUrlParams.get(
      AF_DEFAULT_AID_TYPE_VOCABULARY
    );
    const defaultfinancetype = currentUrlParams.get(AF_DEFAULT_FINANCE_CODE);
    const currency = currentUrlParams.get(AF_DEFAULT_CURRENCY);
    const defaultflowtype = currentUrlParams.get(AF_DEFAULT_FLOW_TYPE_CODE);
    const language = currentUrlParams.get(AF_DEFAULT_LANG);
    const defaulttiedstatus = currentUrlParams.get(AF_DEFAULT_TIED_STATUS_CODE);
    const transactionreceiverorg = currentUrlParams.get(
      AF_TRANSACTION_RECEIVER_ORG_REF
    );
    const reportingorgtype = currentUrlParams.get(AF_REPORTING_ORG_TYPE_CODE);
    const collaborationtype = currentUrlParams.get(AF_COLLABORATION_TYPE_CODE);
    const secondaryreporter = currentUrlParams.get(
      AF_REPORTING_ORG_SECONDARY_REPORTER
    );
    const sectorvocabulary = currentUrlParams.get(AF_SECTOR_VOCABULARY);
    const policymarker = currentUrlParams.get(AF_POLICY_MARKER_CODE);
    const transactionflowtype = currentUrlParams.get(
      AF_TRANSACTION_FLOW_TYPE_CODE
    );
    const transactiontiedstatus = currentUrlParams.get(
      AF_TRANSACTION_TIED_STATUS_CODE
    );
    const transactionhumanitarian = currentUrlParams.get(
      AF_TRANSACTION_HUMANITARIAN
    );
    const transactionvaluecurrency = currentUrlParams.get(
      AF_TRANSACTION_VALUE_CURRENCY
    );
    const transactiontype = currentUrlParams.get(AF_TRANSACTION_TYPE_CODE);
    const period = currentUrlParams.get('period');
    const budget = currentUrlParams.get('budget');
    const transactionvalue = currentUrlParams.get('transaction_value');

    if (countries) {
      updatedSelectedFilters.countries = countries.split(',');
    } else if (updatedSelectedFilters.countries.length > 0) {
      updatedSelectedFilters.countries = [];
    }
    if (regions) {
      updatedSelectedFilters.regions = regions.split(',');
    } else if (updatedSelectedFilters.regions.length > 0) {
      updatedSelectedFilters.regions = [];
    }
    if (sectors) {
      updatedSelectedFilters.sectors = sectors.split(',');
    } else if (updatedSelectedFilters.sectors.length > 0) {
      updatedSelectedFilters.sectors = [];
    }
    if (donors) {
      updatedSelectedFilters.donors = donors.split(',');
    } else if (updatedSelectedFilters.donors.length > 0) {
      updatedSelectedFilters.donors = [];
    }
    if (organisations) {
      updatedSelectedFilters.organisations = organisations.split(',');
    } else if (updatedSelectedFilters.organisations.length > 0) {
      updatedSelectedFilters.organisations = [];
    }
    if (publishers) {
      updatedSelectedFilters.publishers = publishers.split(',');
    } else if (updatedSelectedFilters.publishers.length > 0) {
      updatedSelectedFilters.publishers = [];
    }
    if (activitystatus) {
      updatedSelectedFilters.activitystatus = activitystatus.split(',');
    } else if (updatedSelectedFilters.activitystatus.length > 0) {
      updatedSelectedFilters.activitystatus = [];
    }
    if (activityscope) {
      updatedSelectedFilters.activityscope = activityscope.split(',');
    } else if (updatedSelectedFilters.activityscope.length > 0) {
      updatedSelectedFilters.activityscope = [];
    }
    if (documentlinkcategory) {
      updatedSelectedFilters.documentlinkcategory =
        documentlinkcategory.split(',');
    } else if (updatedSelectedFilters.documentlinkcategory.length > 0) {
      updatedSelectedFilters.documentlinkcategory = [];
    }
    if (hierarchy) {
      updatedSelectedFilters.hierarchy = hierarchy.split(',');
    } else if (updatedSelectedFilters.hierarchy.length > 0) {
      updatedSelectedFilters.hierarchy = [];
    }
    if (humanitarianscopevocab) {
      updatedSelectedFilters.humanitarianscopevocab =
        humanitarianscopevocab.split(',');
    } else if (updatedSelectedFilters.humanitarianscopevocab.length > 0) {
      updatedSelectedFilters.humanitarianscopevocab = [];
    }
    if (humanitarian) {
      updatedSelectedFilters.humanitarian = humanitarian.split(',');
    } else if (updatedSelectedFilters.humanitarian.length > 0) {
      updatedSelectedFilters.humanitarian = [];
    }
    if (humanitarianscope) {
      updatedSelectedFilters.humanitarianscope = humanitarianscope.split(',');
    } else if (updatedSelectedFilters.humanitarianscope.length > 0) {
      updatedSelectedFilters.humanitarianscope = [];
    }
    if (iativersion) {
      updatedSelectedFilters.iativersion = iativersion.split(',');
    } else if (updatedSelectedFilters.iativersion.length > 0) {
      updatedSelectedFilters.iativersion = [];
    }
    if (otheridentifiertype) {
      updatedSelectedFilters.otheridentifiertype =
        otheridentifiertype.split(',');
    } else if (updatedSelectedFilters.otheridentifiertype.length > 0) {
      updatedSelectedFilters.otheridentifiertype = [];
    }
    if (tag) {
      updatedSelectedFilters.tag = tag.split(',');
    } else if (updatedSelectedFilters.tag.length > 0) {
      updatedSelectedFilters.tag = [];
    }
    if (defaultaidtype) {
      updatedSelectedFilters.defaultaidtype = defaultaidtype.split(',');
    } else if (updatedSelectedFilters.defaultaidtype.length > 0) {
      updatedSelectedFilters.defaultaidtype = [];
    }
    if (defaultaidtypecategory) {
      updatedSelectedFilters.defaultaidtypecategory =
        defaultaidtypecategory.split(',');
    } else if (updatedSelectedFilters.defaultaidtypecategory.length > 0) {
      updatedSelectedFilters.defaultaidtypecategory = [];
    }
    if (defaultaidtypevocabulary) {
      updatedSelectedFilters.defaultaidtypevocabulary =
        defaultaidtypevocabulary.split(',');
    } else if (updatedSelectedFilters.defaultaidtypevocabulary.length > 0) {
      updatedSelectedFilters.defaultaidtypevocabulary = [];
    }
    if (currency) {
      updatedSelectedFilters.currency = currency.split(',');
    } else if (updatedSelectedFilters.currency.length > 0) {
      updatedSelectedFilters.currency = [];
    }
    if (defaultfinancetype) {
      updatedSelectedFilters.defaultfinancetype = defaultfinancetype.split(',');
    } else if (updatedSelectedFilters.defaultfinancetype.length > 0) {
      updatedSelectedFilters.defaultfinancetype = [];
    }
    if (defaultflowtype) {
      updatedSelectedFilters.defaultflowtype = defaultflowtype.split(',');
    } else if (updatedSelectedFilters.defaultflowtype.length > 0) {
      updatedSelectedFilters.defaultflowtype = [];
    }
    if (language) {
      updatedSelectedFilters.language = language.split(',');
    } else if (updatedSelectedFilters.language.length > 0) {
      updatedSelectedFilters.language = [];
    }
    if (defaulttiedstatus) {
      updatedSelectedFilters.defaulttiedstatus = defaulttiedstatus.split(',');
    } else if (updatedSelectedFilters.defaulttiedstatus.length > 0) {
      updatedSelectedFilters.defaulttiedstatus = [];
    }
    if (transactionreceiverorg) {
      updatedSelectedFilters.transactionreceiverorg =
        transactionreceiverorg.split(',');
    } else if (updatedSelectedFilters.transactionreceiverorg.length > 0) {
      updatedSelectedFilters.transactionreceiverorg = [];
    }
    if (reportingorgtype) {
      updatedSelectedFilters.reportingorgtype = reportingorgtype.split(',');
    } else if (updatedSelectedFilters.reportingorgtype.length > 0) {
      updatedSelectedFilters.reportingorgtype = [];
    }
    if (collaborationtype) {
      updatedSelectedFilters.collaborationtype = collaborationtype.split(',');
    } else if (updatedSelectedFilters.collaborationtype.length > 0) {
      updatedSelectedFilters.collaborationtype = [];
    }
    if (secondaryreporter) {
      updatedSelectedFilters.secondaryreporter = secondaryreporter.split(',');
    } else if (updatedSelectedFilters.secondaryreporter.length > 0) {
      updatedSelectedFilters.secondaryreporter = [];
    }
    if (sectorvocabulary) {
      updatedSelectedFilters.sectorvocabulary = sectorvocabulary.split(',');
    } else if (updatedSelectedFilters.sectorvocabulary.length > 0) {
      updatedSelectedFilters.sectorvocabulary = [];
    }
    if (policymarker) {
      updatedSelectedFilters.policymarker = policymarker.split(',');
    } else if (updatedSelectedFilters.policymarker.length > 0) {
      updatedSelectedFilters.policymarker = [];
    }
    if (transactionflowtype) {
      updatedSelectedFilters.transactionflowtype =
        transactionflowtype.split(',');
    } else if (updatedSelectedFilters.transactionflowtype.length > 0) {
      updatedSelectedFilters.transactionflowtype = [];
    }
    if (transactiontiedstatus) {
      updatedSelectedFilters.transactiontiedstatus =
        transactiontiedstatus.split(',');
    } else if (updatedSelectedFilters.transactiontiedstatus.length > 0) {
      updatedSelectedFilters.transactiontiedstatus = [];
    }
    if (transactionhumanitarian) {
      updatedSelectedFilters.transactionhumanitarian =
        transactionhumanitarian.split(',');
    } else if (updatedSelectedFilters.transactionhumanitarian.length > 0) {
      updatedSelectedFilters.transactionhumanitarian = [];
    }
    if (transactiontype) {
      updatedSelectedFilters.transactiontype = transactiontype.split(',');
    } else if (updatedSelectedFilters.transactiontype.length > 0) {
      updatedSelectedFilters.transactiontype = [];
    }
    if (transactionvaluecurrency) {
      updatedSelectedFilters.transactionvaluecurrency =
        transactionvaluecurrency.split(',');
    } else if (updatedSelectedFilters.transactionvaluecurrency.length > 0) {
      updatedSelectedFilters.transactionvaluecurrency = [];
    }
    if (period) {
      const dates = period.split('-');
      const periodFilter = [
        {
          key: 'selection',
          endDate: dates[1],
          startDate: dates[0],
        },
      ];
      // @ts-ignore
      updatedSelectedFilters.period = periodFilter;
    } else if (updatedSelectedFilters.period.length > 0) {
      updatedSelectedFilters.period = [];
    }
    if (budget) {
      updatedSelectedFilters.budget = budget.split(',');
    } else if (updatedSelectedFilters.budget.length > 0) {
      updatedSelectedFilters.budget = [];
    }
    if (transactionvalue) {
      updatedSelectedFilters.transactionvalue = transactionvalue.split(',');
    } else if (updatedSelectedFilters.transactionvalue.length > 0) {
      updatedSelectedFilters.transactionvalue = [];
    }

    if (!isEqual(selectedFilters, updatedSelectedFilters)) {
      setSelectedFilters(updatedSelectedFilters);
    }
  }, [location.search]);

  return null;
}
