import { APIModel } from 'app/state/api';
import { ApiCallModel } from 'app/state/api/interfaces';

export const createDataAlert: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_AUTH_API}/create-data-alert`),
};

export const deleteDataAlert: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_AUTH_API}/delete-data-alert`),
};
