import { Box, Typography, Dialog } from '@material-ui/core';
import { Paper } from 'app/components/Paper';
import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import { FilledButton } from 'app/components/Buttons/FilledButton';
import theme from 'app/theme';
import { css } from 'styled-components/macro';
import { ChangeEmailDialogState } from 'app/state/recoil/atoms';
import { useRecoilState } from 'recoil';

const InputField = css`
  box-shadow: 0 1px 14px rgba(0, 0, 0, 0.2);
  border-radius: 18.9489px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 16px;
`;
const ChangeSection = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ChangeEmailDialog = () => {
  const [open, setOpen] = useRecoilState(ChangeEmailDialogState);

  return (
    <Dialog
      onClose={() => setOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      {/* ---------------------------- */}
      {/* delete account 1 */}
      <Paper centerContent>
        <Box margin="40px">
          <Typography gutterBottom variant="h4">
            Change email
          </Typography>

          <Typography paragraph variant="body2">
            Proceed with caution! <br />
            No turning back after this action
          </Typography>
          <Typography
            paragraph
            variant="body2"
            css={`
              color: ${theme.palette.secondary.main};
            `}
          >
            Are you going to change your email? You will see this change in your
            profile.
          </Typography>
          <div css={ChangeSection}>
            <InputBase
              placeholder="Annette_Black@gmail.com"
              inputProps={{ 'aria-label': 'Change email' }}
              css={InputField}
            />

            <FilledButton
              aria-label="change email"
              label="Change"
              backgroundColor={theme.palette.secondary.main}
            />
          </div>
        </Box>
      </Paper>
    </Dialog>
  );
};
