import { OptionsModel } from './FilterOptionItem';

export function formatCountryOptions(options: OptionsModel[]) {
  const regex = /^[a-zA-Z]*/;

  return options.reduce(
    (accumulator: any, option, index) => {
      const char = option.name[0];

      const isSpecial = !regex.test(option.name[0]);

      if (isSpecial) {
        accumulator.other.push(option);
        // eslint-disable-next-line no-prototype-builtins
      } else if (accumulator.hasOwnProperty(char)) {
        accumulator[char].push(option);
      } else {
        accumulator[char] = [];
        accumulator[char].push(option);
      }
      return accumulator;
    },
    {
      other: [],
    }
  );
}

export function formatRegionOptions(options: OptionsModel[]) {
  // src: https://iatistandard.org/en/iati-standard/202/codelists/region/
  const africaCodes = ['298', '1027', '1028', '1029', '1030'];
  const americaCodes = ['389', '498', '489', '1031'];
  const asiaCodes = ['619', '679', '689', '789', '798'];
  const europeCodes = ['89'];
  const oceaniaCodes = ['1033', '1034', '1035'];

  return options.reduce(
    (accumulator: any, option, index) => {
      const isAfrica = africaCodes.includes(option.code);
      const isAmericas = americaCodes.includes(option.code);
      const isAsia = asiaCodes.includes(option.code);
      const isEurope = europeCodes.includes(option.code);
      const isOceania = oceaniaCodes.includes(option.code);
      const niceName = option.name.split(', regional')[0];

      switch (true) {
        case isAfrica:
          accumulator.Africa.push({ ...option, name: niceName });
          break;
        case isAmericas:
          accumulator.Americas.push({ ...option, name: niceName });
          break;
        case isAsia:
          accumulator.Asia.push({ ...option, name: niceName });
          break;
        case isEurope:
          accumulator.Europe.push({ ...option, name: niceName });
          break;
        case isOceania:
          accumulator.Oceania.push({ ...option, name: niceName });
          break;
        default:
          accumulator.Other.push(option);
      }
      return accumulator;
    },
    {
      // Africa: [{ _id: 'all_Africa', code: 'all_Africa', name: 'All' }],
      // Americas: [{ _id: 'all_Americas', code: 'all_Americas', name: 'All' }],
      // Asia: [{ _id: 'all_Asia', code: 'all_Asia', name: 'All' }],
      // Europe: [{ _id: 'all_Europe', code: 'all_Europe', name: 'All' }],
      // Oceania: [{ _id: 'all_Oceania', code: 'all_Oceania', name: 'All' }],
      // Other: [{ _id: 'all_Other', code: 'all_Other', name: 'All' }],
      Africa: [],
      Americas: [],
      Asia: [],
      Europe: [],
      Oceania: [],
      Other: [],
    }
  );
}

export function splitOnCodeLength(options: any) {
  const index = 0;
  const sectors = [...options];
  const sectorDAC3 = [];

  if (sectors.length > 1) {
    while (sectors[index].code.length < 5) {
      sectorDAC3.push(sectors[index]);
      sectors.shift();
    }
  }

  const sectorDAC5 = sectors;
  return {
    sectorDAC3,
    sectorDAC5,
  };
}
