import React from 'react';
import { useHistory } from 'react-router-dom';

export function useViewsCount() {
  const history = useHistory();

  React.useEffect(() => {
    let viewCount = 0;
    const viewCountStr = localStorage.getItem('view-counts');
    if (viewCountStr) {
      viewCount = parseInt(viewCountStr, 10);
    }
    localStorage.setItem('view-counts', (viewCount + 1).toString());
  }, [history.location.pathname]);

  return null;
}
