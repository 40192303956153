import React from 'react';
import Grid from '@material-ui/core/Grid';
import { css } from 'styled-components/macro';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import RestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { SearchField } from 'app/components/Filter/common/SearchField';
import { Tabs } from 'app/components/FilterCard/MainFilters/common/Tabs';

interface TopControlsProps {
  handleResetClick: Function;
  handleSelectAllClick: Function;
  shouldSelectAll: boolean;
  handleSearch: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  sector?: boolean;
  locations?: boolean;
  organisations?: boolean;
}

const SelectTypographyStyle = css`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: '#2e4063';
  font-size: 12px;
`;

const ResetFilters = ({ handleResetClick }: TopControlsProps) => (
  <IconButton
    color="primary"
    aria-label="reset"
    onClick={() => handleResetClick()}
    css={`
      padding: 0px 6px 0px 2px;
    `}
  >
    <RestoreIcon />
  </IconButton>
);

const SelectAllOptions = ({
  handleSelectAllClick,
  shouldSelectAll,
}: TopControlsProps) => (
  <>
    <Checkbox
      color="default"
      onChange={(e) => handleSelectAllClick(e)}
      checked={shouldSelectAll}
      inputProps={{ 'aria-label': 'secondary checkbox' }}
      css={`
        margin-right: 2px;
      `}
    />
    <div css={SelectTypographyStyle}>Select all</div>
  </>
);

const Search = ({ handleSearch }: TopControlsProps) => (
  <Grid
    item
    xs={12}
    css={`
      display: flex;
      padding-top: 12px !important;
    `}
  >
    <SearchField setValue={handleSearch} />
  </Grid>
);

export const TopControls = (props: TopControlsProps) => (
  <Grid container item spacing={0}>
    <Grid item sm={8} container alignItems="center" justifyContent="flex-start">
      <Tabs
        sector={props.sector}
        locations={props.locations}
        organisations={props.organisations}
      />
    </Grid>
    <Grid item sm={4} container alignItems="center" justifyContent="flex-end">
      <ResetFilters {...props} />
      <SelectAllOptions {...props} />
    </Grid>
    <Search {...props} />
  </Grid>
);
