import * as React from 'react';

export function LogoGoogle(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) {
  return (
    <svg width={30} height={31} viewBox="0 0 30 31" fill="none" {...props}>
      <path
        d="M27.257 13.052H26.25V13H15v5h7.064c-1.03 2.91-3.8 5-7.064 5a7.5 7.5 0 010-15 7.46 7.46 0 014.976 1.9l3.535-3.536A12.442 12.442 0 0015 3C8.097 3 2.5 8.597 2.5 15.5S8.097 28 15 28s12.5-5.597 12.5-12.5c0-.838-.086-1.656-.243-2.448z"
        fill="#FFC107"
      />
      <path
        d="M3.941 9.682l4.107 3.012A7.497 7.497 0 0115 8a7.46 7.46 0 014.976 1.9l3.535-3.536A12.442 12.442 0 0015 3C10.199 3 6.035 5.71 3.94 9.682z"
        fill="#FF3D00"
      />
      <path
        d="M15 28c3.229 0 6.163-1.236 8.38-3.245l-3.868-3.274A7.443 7.443 0 0115 23c-3.251 0-6.012-2.073-7.052-4.966l-4.076 3.14C5.94 25.223 10.142 28 15 28z"
        fill="#4CAF50"
      />
      <path
        d="M27.257 13.052H26.25V13H15v5h7.064a7.526 7.526 0 01-2.554 3.482l.002-.001 3.869 3.273c-.274.25 4.119-3.004 4.119-9.254 0-.838-.086-1.656-.243-2.448z"
        fill="#1976D2"
      />
    </svg>
  );
}
