import React from 'react';

export function AddBookmarkIcon() {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24">
      <g clipPath="url(#clip0_4722_17665)">
        <path
          d="M17.8242 11V17.97L12.8242 15.83L7.82422 17.97V5H13.8242V3H7.82422C6.72422 3 5.82422 3.9 5.82422 5V21L12.8242 18L19.8242 21V11H17.8242ZM21.8242 7H19.8242V9H17.8242V7H15.8242V5H17.8242V3H19.8242V5H21.8242V7Z"
          fill="#2E4063"
        />
      </g>
      <defs>
        <clipPath id="clip0_4722_17665">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.824219)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
