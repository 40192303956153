import React from 'react';
import find from 'lodash/find';
import filter from 'lodash/filter';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import { FilterProps } from 'app/components/FilterCard';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { secondaryReporter } from 'app/components/FilterCard/MoreFilters/common/data';
import { Label } from 'app/components/FilterCard/MoreFilters/common/FilterSelectStyles';
import { FilterSelect } from 'app/components/FilterCard/MoreFilters/common/FilterSelect';
import { MoreFiltersCard } from 'app/components/FilterCard/MoreFilters/common/MoreFilterCard';

interface OrganisationFilterProps extends FilterProps {
  filterOptionsDataLoading: any;
  loadFilterOptions: (arg0: string, arg1: boolean) => void;
}

export const OrganisationFilter = (props: OrganisationFilterProps) => {
  function onChange(actionMeta: any, type: string) {
    const options = { ...props.selectedOptions };
    if (actionMeta.action === 'select-option') {
      options[type] = [...options[type], actionMeta.option.value];
    }
    if (
      actionMeta.action === 'remove-value' ||
      actionMeta.action === 'pop-value'
    ) {
      options[type] = filter(
        options[type],
        (v: any) => v !== actionMeta.removedValue.value
      );
    }
    if (actionMeta.action === 'deselect-option') {
      options[type] = filter(
        options[type],
        (v: any) => v !== actionMeta.option.value
      );
    }
    if (actionMeta.action === 'clear') {
      options[type] = [];
    }
    props.setSelectedOptions(options);
  }

  function handleCheckboxChange(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    const options = { ...props.selectedOptions };
    if (checked) {
      options.secondaryreporter = [
        ...options.secondaryreporter,
        event.target.value,
      ];
    } else {
      options.secondaryreporter = filter(
        options.secondaryreporter,
        (v: any) => v !== event.target.value
      );
    }
    props.setSelectedOptions(options);
  }

  return (
    <MoreFiltersCard
      header="Organisation filter"
      selectedOptions={props.selectedOptions}
    >
      {/* -------------------------------------- */}
      {/* organisation filter */}
      {/* -------------------------------------- */}
      {/* transaction receiver org */}
      <Grid item xs={12} md={12} lg={12}>
        <Label>Transaction receiver org</Label>
        <FilterSelect
          options={props.options.transactionreceiverorg.map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          placeholder="All"
          onMenuOpen={() => {
            props.loadFilterOptions('transactionreceiverorg', true);
          }}
          defaultValue={filter(
            props.options.transactionreceiverorg,
            (item: any) =>
              find(
                props.selectedFilters.transactionreceiverorg,
                (f: any) => f === item.code
              )
          ).map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          onChange={(value: any, actionMeta: any) => {
            onChange(actionMeta, 'transactionreceiverorg');
          }}
          isLoading={props.filterOptionsDataLoading.transactionreceiverorg}
        />
      </Grid>
      {/* -------------------------------------- */}
      {/* type of reporting organisation */}
      <Grid item xs={12} md={12} lg={12}>
        <Label>Type of reporting organisation</Label>
        <FilterSelect
          options={props.options.reportingorgtype.map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          placeholder="All"
          onMenuOpen={() => {
            props.loadFilterOptions('reportingorgtype', true);
          }}
          defaultValue={filter(props.options.reportingorgtype, (item: any) =>
            find(
              props.selectedFilters.reportingorgtype,
              (f: any) => f === item.code
            )
          ).map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          onChange={(value: any, actionMeta: any) => {
            onChange(actionMeta, 'reportingorgtype');
          }}
          isLoading={props.filterOptionsDataLoading.reportingorgtype}
        />
      </Grid>
      {/* -------------------------------------- */}
      {/* collaboration type */}
      <Grid item xs={12} md={12} lg={12}>
        <Label>Collaboration type</Label>
        <FilterSelect
          options={props.options.collaborationtype.map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          placeholder="All"
          onMenuOpen={() => {
            props.loadFilterOptions('collaborationtype', true);
          }}
          defaultValue={filter(props.options.collaborationtype, (item: any) =>
            find(
              props.selectedFilters.collaborationtype,
              (f: any) => f === item.code
            )
          ).map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          onChange={(value: any, actionMeta: any) => {
            onChange(actionMeta, 'collaborationtype');
          }}
          isLoading={props.filterOptionsDataLoading.collaborationtype}
        />
      </Grid>
      {/* -------------------------------------- */}
      {/* secondary reporter */}

      {/* todo: Need to fix these checkboxes inside an component */}
      <Grid item xs={12} md={12} lg={12}>
        <Label>Secondary reporter</Label>
        <Grid item>
          {secondaryReporter.map((item: any) => (
            <FormControlLabel
              key={item.label}
              css={`
                margin-left: 0;
              `}
              control={
                <Checkbox
                  color="default"
                  checked={find(
                    props.selectedOptions.secondaryreporter,
                    (f: any) => f === item.value
                  )}
                  value={item.value}
                  onChange={handleCheckboxChange}
                />
              }
              label={item.label}
            />
          ))}
        </Grid>
      </Grid>
    </MoreFiltersCard>
  );
};
