import React from 'react';
import { Grid } from '@material-ui/core';
import { css } from 'styled-components/macro';
import RangeSlider from 'app/components/Slider';
import { FilterProps } from 'app/components/FilterCard';
import { AmountInput } from 'app/components/FilterCard/MainFilters/common/AmountInput';
import { Label } from 'app/components/FilterCard/MoreFilters/common/FilterSelectStyles';
import { MainFiltersCard } from 'app/components/FilterCard/MainFilters/common/MainFilterCard';

export const BudgetFilter = (props: FilterProps) => {
  const [budgetRange, setBudgetRange] = React.useState<number[]>(
    getInitialRange()
  );

  const handleChange = (event: any, newValue: number | number[]) => {
    setBudgetRange(newValue as number[]);
  };

  function getInitialRange(): number[] {
    if (props.selectedFilters.budget.length > 0) {
      return formatBudget(props.selectedFilters.budget, 1000000, true);
    }
    return [0, 1600];
  }

  function formatBudget(range: any, multiplier = 1000000, revert = false) {
    let budgetMin;
    let budgetMax;
    if (revert) {
      budgetMin = range[0] / multiplier;
      budgetMax = range[1] / multiplier;
    } else {
      budgetMin = range[0] * multiplier;
      budgetMax = range[1] * multiplier;
    }
    return [budgetMin, budgetMax];
  }

  React.useEffect(() => {
    props.setSelectedOptions(formatBudget(budgetRange, 1000000));
  }, [budgetRange]);

  React.useEffect(() => {
    if (props.selectedOptions <= 0) {
      setBudgetRange([0, 1600]);
    }
  }, [props.selectedOptions]);

  const handleChangeAmountInputFrom = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setBudgetRange([parseInt(event.target.value, 10), budgetRange[1]]);
  };

  const handleChangeAmountInputTo = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setBudgetRange([budgetRange[0], parseInt(event.target.value, 10)]);
  };

  return (
    <MainFiltersCard
      title="Budget"
      {...props}
      removeTopControls
      dontDisplaySelected
    >
      <Grid item container lg={12}>
        {/* USE THIS WHEN WE DECIDE WHAT TRANSACTION TYPES TO FILTER ON */}
        {/* <Grid
          item
          lg={12}
          css={css`
            margin-bottom: 16px;
          `}
        >
          <Label>Budget or transaction type</Label>
          <FilterSelect
            isSingle
            defaultValue={{ value: 'Disbursed', label: 'Disbursed' }}
            options={[
              { value: 'All', label: 'All' },
              { value: 'Disbursed', label: 'Disbursed' },
            ]}
            placeholder="All"
          />
        </Grid> */}
        <Grid item container>
          <Grid
            item
            lg={6}
            css={css`
              margin-bottom: 68px;
            `}
          >
            <Label>US$ Amount (from)</Label>
            <AmountInput
              left
              value={budgetRange[0]}
              endAdornmentText="US$ M"
              handleChange={handleChangeAmountInputFrom}
            />
          </Grid>
          <Grid
            item
            lg={6}
            css={css`
              margin-bottom: 30px;
            `}
          >
            <Label marginLeft={7.5}>US$ Amount (to)</Label>
            <AmountInput
              left={false}
              value={budgetRange[1]}
              endAdornmentText="US$ M"
              handleChange={handleChangeAmountInputTo}
            />
          </Grid>
        </Grid>
        <Grid
          item
          lg={12}
          css={css`
            margin-bottom: 24px;
          `}
        >
          <RangeSlider value={budgetRange} handleChange={handleChange} />
        </Grid>
      </Grid>
    </MainFiltersCard>
  );
};
