import React from 'react';
import find from 'lodash/find';
import filter from 'lodash/filter';
import { Grid } from '@material-ui/core';
import { FilterSelect } from './common/FilterSelect';
import { MoreFiltersCard } from './common/MoreFilterCard';
import { Label } from './common/FilterSelectStyles';
import { humanitarian } from './common/data';
import { FilterProps } from '../index';

interface ActivityFilterProps extends FilterProps {
  filterOptionsDataLoading: any;
  loadFilterOptions: (arg0: string, arg1: boolean) => void;
}

export const ActivityFilter = (props: ActivityFilterProps) => {
  function onChange(actionMeta: any, type: string) {
    const options = { ...props.selectedOptions };
    if (actionMeta.action === 'select-option') {
      options[type] = [...options[type], actionMeta.option.value];
    }
    if (
      actionMeta.action === 'remove-value' ||
      actionMeta.action === 'pop-value'
    ) {
      options[type] = filter(
        options[type],
        (v: any) => v !== actionMeta.removedValue.value
      );
    }
    if (actionMeta.action === 'deselect-option') {
      options[type] = filter(
        options[type],
        (v: any) => v !== actionMeta.option.value
      );
    }
    if (actionMeta.action === 'clear') {
      options[type] = [];
    }
    props.setSelectedOptions(options);
  }

  return (
    <MoreFiltersCard
      header="Activity filter"
      selectedOptions={props.selectedOptions}
    >
      {/* -------------------------------------- */}
      {/* activity scope */}
      <Grid item xs={12} md={12} lg={12}>
        <Label>Activity scope</Label>
        <FilterSelect
          options={props.options.activityscope.map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          placeholder="All"
          onMenuOpen={() => {
            props.loadFilterOptions('activityscope', true);
          }}
          defaultValue={filter(props.options.activityscope, (item: any) =>
            find(
              props.selectedFilters.activityscope,
              (f: any) => f === item.code
            )
          ).map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          onChange={(value: any, actionMeta: any) => {
            onChange(actionMeta, 'activityscope');
          }}
          isLoading={props.filterOptionsDataLoading.activityscope}
        />
      </Grid>
      {/* -------------------------------------- */}
      {/* document link category */}
      <Grid item xs={12} md={12} lg={12}>
        <Label>Document link category</Label>
        <FilterSelect
          options={props.options.documentlinkcategory.map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          placeholder="All"
          onMenuOpen={() => {
            props.loadFilterOptions('documentlinkcategory', true);
          }}
          defaultValue={filter(
            props.options.documentlinkcategory,
            (item: any) =>
              find(
                props.selectedFilters.documentlinkcategory,
                (f: any) => f === item.code
              )
          ).map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          onChange={(value: any, actionMeta: any) => {
            onChange(actionMeta, 'documentlinkcategory');
          }}
          isLoading={props.filterOptionsDataLoading.documentlinkcategory}
        />
      </Grid>
      {/* -------------------------------------- */}
      {/* hierarchy */}
      <Grid item xs={6} md={6} lg={6}>
        <Label>Hierarchy</Label>
        <FilterSelect
          options={props.options.hierarchy.map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          placeholder="All"
          onMenuOpen={() => {
            props.loadFilterOptions('hierarchy', true);
          }}
          defaultValue={filter(props.options.hierarchy, (item: any) =>
            find(props.selectedFilters.hierarchy, (f: any) => f === item.code)
          ).map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          onChange={(value: any, actionMeta: any) => {
            onChange(actionMeta, 'hierarchy');
          }}
          isLoading={props.filterOptionsDataLoading.hierarchy}
        />
      </Grid>
      {/* -------------------------------------- */}
      {/* humanitairian scope vocabulary */}
      <Grid item xs={6} md={6} lg={6}>
        <Label>Humanitarian scope vocabulary</Label>
        <FilterSelect
          options={props.options.humanitarianscopevocab.map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          placeholder="All"
          onMenuOpen={() => {
            props.loadFilterOptions('humanitarianscopevocab', true);
          }}
          defaultValue={filter(
            props.options.humanitarianscopevocab,
            (item: any) =>
              find(
                props.selectedFilters.humanitarianscopevocab,
                (f: any) => f === item.code
              )
          ).map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          onChange={(value: any, actionMeta: any) => {
            onChange(actionMeta, 'humanitarianscopevocab');
          }}
          isLoading={props.filterOptionsDataLoading.humanitarianscopevocab}
        />
      </Grid>
      {/* -------------------------------------- */}
      {/* humanitarian */}
      <Grid item xs={6} md={6} lg={6}>
        <Label>Humanitarian</Label>
        <FilterSelect
          placeholder="All"
          options={humanitarian}
          defaultValue={filter(humanitarian, (item: any) =>
            find(
              props.selectedFilters.humanitarian,
              (f: any) => f === item.value
            )
          ).map((item: any) => ({
            label: item.label,
            value: item.value,
          }))}
          onChange={(value: any, actionMeta: any) => {
            onChange(actionMeta, 'humanitarian');
          }}
          isLoading={props.filterOptionsDataLoading.humanitarian}
        />
      </Grid>
      {/* -------------------------------------- */}
      {/* humanitarian scope */}
      <Grid item xs={6} md={6} lg={6}>
        <Label>Humanitarian scope</Label>
        <FilterSelect
          options={props.options.humanitarianscope.map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          placeholder="All"
          onMenuOpen={() => {
            props.loadFilterOptions('humanitarianscope', true);
          }}
          defaultValue={filter(props.options.humanitarianscope, (item: any) =>
            find(
              props.selectedFilters.humanitarianscope,
              (f: any) => f === item.code
            )
          ).map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          onChange={(value: any, actionMeta: any) => {
            onChange(actionMeta, 'humanitarianscope');
          }}
          isLoading={props.filterOptionsDataLoading.humanitarianscope}
        />
      </Grid>
      {/* -------------------------------------- */}
      {/* iati version */}
      <Grid item xs={6} md={6} lg={6}>
        <Label>IATI version</Label>
        <FilterSelect
          options={props.options.iativersion.map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          placeholder="All"
          onMenuOpen={() => {
            props.loadFilterOptions('iativersion', true);
          }}
          defaultValue={filter(props.options.iativersion, (item: any) =>
            find(props.selectedFilters.iativersion, (f: any) => f === item.code)
          ).map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          onChange={(value: any, actionMeta: any) => {
            onChange(actionMeta, 'iativersion');
          }}
          isLoading={props.filterOptionsDataLoading.iativersion}
        />
      </Grid>
      {/* -------------------------------------- */}
      {/* other identifier type */}
      <Grid item xs={6} md={6} lg={6}>
        <Label>Other identifier type</Label>
        <FilterSelect
          options={props.options.otheridentifiertype.map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          placeholder="All"
          onMenuOpen={() => {
            props.loadFilterOptions('otheridentifiertype', true);
          }}
          defaultValue={filter(props.options.otheridentifiertype, (item: any) =>
            find(
              props.selectedFilters.otheridentifiertype,
              (f: any) => f === item.code
            )
          ).map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          onChange={(value: any, actionMeta: any) => {
            onChange(actionMeta, 'otheridentifiertype');
          }}
          isLoading={props.filterOptionsDataLoading.otheridentifiertype}
        />
      </Grid>
      {/* -------------------------------------- */}
      {/* tag */}
      <Grid item xs={12} md={12} lg={12}>
        <Label>Tag</Label>
        <FilterSelect
          options={props.options.tag.map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          placeholder="All"
          onMenuOpen={() => {
            props.loadFilterOptions('tag', true);
          }}
          defaultValue={filter(props.options.tag, (item: any) =>
            find(props.selectedFilters.tag, (f: any) => f === item.code)
          ).map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          onChange={(value: any, actionMeta: any) => {
            onChange(actionMeta, 'tag');
          }}
          isLoading={props.filterOptionsDataLoading.tag}
        />
      </Grid>
    </MoreFiltersCard>
  );
};
