/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import get from 'lodash/get';
import { useRecoilState } from 'recoil';
import SearchIcon from '@material-ui/icons/Search';
import { useLocation, useParams } from 'react-router-dom';
import { getDetailPageFilter } from 'app/utils/getDetailPageFilter';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { SearchComponent } from 'app/components/Filter/common/Search';
import { useStoreState, useStoreActions } from 'app/state/store/hooks';
import { getAPIFormattedFilters } from 'app/utils/getAPIFormattedFilters';
import { FilterProps, getFilterGroups } from 'app/components/Filter/data';
import {
  filterPopupAtom,
  selectedFilterAtom,
  transactionTypeAtom,
} from 'app/state/recoil/atoms';
import { BookmarkButton } from 'app/components/Filter/common/BookmarkButton';
import { MoreFiltersDropdown } from 'app/components/Filter/common/MoreFilters';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import {
  FilterItemProps,
  FilterItem,
} from 'app/components/Filter/common/FilterItem';
import {
  IconButton,
  Divider,
  Popover,
  ClickAwayListener,
} from '@material-ui/core';
import {
  FilterBaseStyle,
  FilterItemContainer,
  FilterDividerStyle,
} from 'app/components/Filter/style';
import { FilterCard } from 'app/components/FilterCard';
import {
  tabHasActiveMainFilter,
  tabHasActiveMoreFilter,
  tabHasFilterActive,
} from 'app/components/Filter/common/utils';

const useStyles = makeStyles(() =>
  createStyles({
    popover: {
      // width: '75%',
      height: '100%',
      top: '7px !important',
      '@media (max-width: 768px)': {
        width: '100%',
      },
    },
    paper: {
      borderRadius: '16px',
      boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.12)',
      overflow: 'visible',
    },
  })
);

export const Filter = (props: FilterProps) => {
  const params = useParams();
  const classes = useStyles();
  const location = useLocation();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [transactionType] = useRecoilState(transactionTypeAtom);
  const [currentFilterOpen, setCurrentFilterOpen] =
    useRecoilState(filterPopupAtom);
  const [isSearchOpen, setIsSearchOpen] = React.useState(props.openSearch);
  const [selectedFilters, setSelectedFilters] =
    useRecoilState(selectedFilterAtom);
  const [mainTabHasActiveFilter, setMainTabHasActiveFilter] = React.useState(
    tabHasActiveMainFilter(selectedFilters)
  );
  const [moreTabHasActiveFilter, setMoreTabHasActiveFilter] = React.useState(
    tabHasActiveMoreFilter(selectedFilters)
  );
  const [filterGroups, setFilterGroups] = React.useState(
    getFilterGroups(location.pathname, true, transactionType.name)
  );

  const filterOptionsData = useStoreState((peasystate) => ({
    locations: {
      countries: get(peasystate.filterOptions.countries, 'data.data', []),
      regions: get(peasystate.filterOptions.regions, 'data.data', []),
    },
    countries: get(peasystate.filterOptions.countries, 'data.data', []),
    regions: get(peasystate.filterOptions.regions, 'data.data', []),
    budget: get(peasystate.filterOptions, 'budget', []),
    transactionvalue: get(peasystate.filterOptions, 'transactionvalue', []),
    sectors: [
      ...get(peasystate.filterOptions.sectors, 'data.data.dac3', []),
      ...get(peasystate.filterOptions.sectors, 'data.data.dac5', []),
    ],
    donors: get(peasystate.filterOptions.donors, 'data.data', []),
    // organisations: get(peasystate.filterOptions.organisations, 'data.data', []),
    publishers: get(peasystate.filterOptions.publishers, 'data.data', []),
    organisations: {
      donors: get(peasystate.filterOptions.donors, 'data.data', []),
      organisations: get(
        peasystate.filterOptions.organisations,
        'data.data',
        []
      ),
      publishers: get(peasystate.filterOptions.publishers, 'data.data', []),
    },
    period: get(peasystate.filterOptions, 'period', []),
    activitystatus: get(
      peasystate.filterOptions.activitystatus,
      'data.data',
      []
    ),
    activity: {
      activityscope: get(
        peasystate.filterOptions.activityscope,
        'data.data',
        []
      ),
      documentlinkcategory: get(
        peasystate.filterOptions.documentlinkcategory,
        'data.data',
        []
      ),
      hierarchy: get(peasystate.filterOptions.hierarchy, 'data.data', []),
      humanitarianscopevocab: get(
        peasystate.filterOptions.humanitarianscopevocab,
        'data.data',
        []
      ),
      humanitarianscope: get(
        peasystate.filterOptions.humanitarianscope,
        'data.data',
        []
      ),
      iativersion: get(peasystate.filterOptions.iativersion, 'data.data', []),
      otheridentifiertype: get(
        peasystate.filterOptions.otheridentifiertype,
        'data.data',
        []
      ),
      tag: get(peasystate.filterOptions.tag, 'data.data', []),
    },
    default: {
      defaultaidtype: get(
        peasystate.filterOptions.defaultaidtype,
        'data.data',
        []
      ),
      defaultaidtypecategory: get(
        peasystate.filterOptions.defaultaidtypecategory,
        'data.data',
        []
      ),
      defaultaidtypevocabulary: get(
        peasystate.filterOptions.defaultaidtypevocabulary,
        'data.data',
        []
      ),
      currency: get(peasystate.filterOptions.currency, 'data.data', []),
      defaultfinancetype: get(
        peasystate.filterOptions.defaultfinancetype,
        'data.data',
        []
      ),
      defaultflowtype: get(
        peasystate.filterOptions.defaultflowtype,
        'data.data',
        []
      ),
      language: get(peasystate.filterOptions.language, 'data.data', []),
      defaulttiedstatus: get(
        peasystate.filterOptions.defaulttiedstatus,
        'data.data',
        []
      ),
    },
    organisation: {
      transactionreceiverorg: get(
        peasystate.filterOptions.transactionreceiverorg,
        'data.data',
        []
      ),
      reportingorgtype: get(
        peasystate.filterOptions.reportingorgtype,
        'data.data',
        []
      ),
      collaborationtype: get(
        peasystate.filterOptions.collaborationtype,
        'data.data',
        []
      ),
    },
    sector: {
      sectorvocabulary: get(
        peasystate.filterOptions.sectorvocabulary,
        'data.data',
        []
      ),
      policymarker: get(peasystate.filterOptions.policymarker, 'data.data', []),
    },
    transaction: {
      transactionflowtype: get(
        peasystate.filterOptions.transactionflowtype,
        'data.data',
        []
      ),
      transactiontiedstatus: get(
        peasystate.filterOptions.transactiontiedstatus,
        'data.data',
        []
      ),
      transactiontype: get(
        peasystate.filterOptions.transactiontype,
        'data.data',
        []
      ),
      transactionvaluecurrency: get(
        peasystate.filterOptions.transactionvaluecurrency,
        'data.data',
        []
      ),
    },
  }));
  const filterOptionsDataLoading = useStoreState((peasystate) => ({
    countries: peasystate.filterOptions.countries.loading,
    regions: peasystate.filterOptions.regions.loading,
    budget: false,
    sectors: peasystate.filterOptions.sectors.loading,
    donors: peasystate.filterOptions.donors.loading,
    organisations: peasystate.filterOptions.organisations.loading,
    publishers: peasystate.filterOptions.publishers.loading,
    period: false,
    activitystatus: peasystate.filterOptions.activitystatus.loading,
    activityscope: peasystate.filterOptions.activityscope.loading,
    documentlinkcategory: peasystate.filterOptions.documentlinkcategory.loading,
    hierarchy: peasystate.filterOptions.hierarchy.loading,
    humanitarianscopevocab:
      peasystate.filterOptions.humanitarianscopevocab.loading,
    humanitarianscope: peasystate.filterOptions.humanitarianscope.loading,
    iativersion: peasystate.filterOptions.iativersion.loading,
    otheridentifiertype: peasystate.filterOptions.otheridentifiertype.loading,
    tag: peasystate.filterOptions.tag.loading,
    defaultaidtype: peasystate.filterOptions.defaultaidtype.loading,
    defaultaidtypecategory:
      peasystate.filterOptions.defaultaidtypecategory.loading,
    defaultaidtypevocabulary:
      peasystate.filterOptions.defaultaidtypevocabulary.loading,
    currency: peasystate.filterOptions.currency.loading,
    defaultfinancetype: peasystate.filterOptions.defaultfinancetype.loading,
    defaultflowtype: peasystate.filterOptions.defaultflowtype.loading,
    language: peasystate.filterOptions.language.loading,
    defaulttiedstatus: peasystate.filterOptions.defaulttiedstatus.loading,
    transactionreceiverorg:
      peasystate.filterOptions.transactionreceiverorg.loading,
    reportingorgtype: peasystate.filterOptions.reportingorgtype.loading,
    collaborationtype: peasystate.filterOptions.collaborationtype.loading,
    sectorvocabulary: peasystate.filterOptions.sectorvocabulary.loading,
    policymarker: peasystate.filterOptions.policymarker.loading,
    transactionflowtype: peasystate.filterOptions.transactionflowtype.loading,
    transactiontiedstatus:
      peasystate.filterOptions.transactiontiedstatus.loading,
    transactiontype: peasystate.filterOptions.transactiontype.loading,
    transactionvaluecurrency:
      peasystate.filterOptions.transactionvaluecurrency.loading,
  }));
  const filterGroupDataActions = useStoreActions((actions) => ({
    // main filters
    countries: actions.filterOptions.countries.fetch,
    regions: actions.filterOptions.regions.fetch,
    sectors: actions.filterOptions.sectors.fetch,
    donors: actions.filterOptions.donors.fetch,
    organisations: actions.filterOptions.organisations.fetch,
    publishers: actions.filterOptions.publishers.fetch,
    activitystatus: actions.filterOptions.activitystatus.fetch,
    // more filters
    activityscope: actions.filterOptions.activityscope.fetch,
    documentlinkcategory: actions.filterOptions.documentlinkcategory.fetch,
    hierarchy: actions.filterOptions.hierarchy.fetch,
    humanitarianscopevocab: actions.filterOptions.humanitarianscopevocab.fetch,
    humanitarianscope: actions.filterOptions.humanitarianscope.fetch,
    iativersion: actions.filterOptions.iativersion.fetch,
    otheridentifiertype: actions.filterOptions.otheridentifiertype.fetch,
    tag: actions.filterOptions.tag.fetch,
    defaultaidtype: actions.filterOptions.defaultaidtype.fetch,
    defaultaidtypecategory: actions.filterOptions.defaultaidtypecategory.fetch,
    defaultaidtypevocabulary:
      actions.filterOptions.defaultaidtypevocabulary.fetch,
    currency: actions.filterOptions.currency.fetch,
    defaultfinancetype: actions.filterOptions.defaultfinancetype.fetch,
    defaultflowtype: actions.filterOptions.defaultflowtype.fetch,
    language: actions.filterOptions.language.fetch,
    defaulttiedstatus: actions.filterOptions.defaulttiedstatus.fetch,
    transactionreceiverorg: actions.filterOptions.transactionreceiverorg.fetch,
    reportingorgtype: actions.filterOptions.reportingorgtype.fetch,
    collaborationtype: actions.filterOptions.collaborationtype.fetch,
    sectorvocabulary: actions.filterOptions.sectorvocabulary.fetch,
    policymarker: actions.filterOptions.policymarker.fetch,
    transactionflowtype: actions.filterOptions.transactionflowtype.fetch,
    transactiontiedstatus: actions.filterOptions.transactiontiedstatus.fetch,
    transactiontype: actions.filterOptions.transactiontype.fetch,
    transactionvaluecurrency:
      actions.filterOptions.transactionvaluecurrency.fetch,
  }));
  const filtersUpdatedData = useStoreState(
    (rstate) => rstate.filtersUpdated.value
  );
  const filtersUpdatedAction = useStoreActions(
    (actions) => actions.filtersUpdated.setValue
  );

  function resetFilters() {
    setSelectedFilters({
      countries: [],
      regions: [],
      budget: [],
      sectors: [],
      donors: [],
      organisations: [],
      publishers: [],
      activitystatus: [],
      period: [],
      activityscope: [],
      documentlinkcategory: [],
      hierarchy: [],
      humanitarianscopevocab: [],
      humanitarian: [],
      humanitarianscope: [],
      iativersion: [],
      otheridentifiertype: [],
      tag: [],
      defaultaidtype: [],
      defaultaidtypecategory: [],
      defaultaidtypevocabulary: [],
      currency: [],
      defaultfinancetype: [],
      defaultflowtype: [],
      language: [],
      defaulttiedstatus: [],
      transactionreceiverorg: [],
      reportingorgtype: [],
      collaborationtype: [],
      secondaryreporter: [],
      sectorvocabulary: [],
      policymarker: [],
      transactionflowtype: [],
      transactiontiedstatus: [],
      transactionhumanitarian: [],
      transactiontype: [],
      transactionvaluecurrency: [],
      transactionvalue: [],
    });
  }

  React.useEffect(() => {
    const newFilterGroups = getFilterGroups(
      location.pathname,
      true,
      transactionType.name
    );
    newFilterGroups.forEach((filterGroup: any, index: number) => {
      newFilterGroups[index].locked = false;
    });
    setFilterGroups(newFilterGroups);
  }, [location, transactionType.name]);

  React.useEffect(() => {
    setMainTabHasActiveFilter(tabHasActiveMainFilter(selectedFilters));
    setMoreTabHasActiveFilter(tabHasActiveMoreFilter(selectedFilters));
  }, [selectedFilters]);

  function onFilterGroupClick(group: string, isAdditionalFilter = false) {
    if (
      get(filterGroupDataActions, group, undefined) &&
      !get(filtersUpdatedData, group, false)
    ) {
      get(
        filterGroupDataActions,
        group,
        console.log
      )({
        values: {
          filter_type: group,
          filters: {
            ...getAPIFormattedFilters(selectedFilters),
            ...getDetailPageFilter(location, params),
          },
        },
      });
      filtersUpdatedAction({
        key: group,
        value: true,
      });
    }
    if (!isAdditionalFilter) {
      setCurrentFilterOpen(group);
    }
  }

  const filterCardoptions = get(filterOptionsData, currentFilterOpen, []);

  const transform = () => {
    if (mainTabHasActiveFilter || moreTabHasActiveFilter) {
      if (mainTabHasActiveFilter && moreTabHasActiveFilter) {
        return -12;
      }
      return -8;
    }
    return 0;
  };

  const showOnlySearch =
    location.pathname.indexOf('/data-manager') > -1 ||
    location.pathname.indexOf('/about') > -1 ||
    location.pathname.indexOf('/faq') > -1 ||
    location.pathname.indexOf('/contact') > -1 ||
    location.pathname.indexOf('/user-settings') > -1 ||
    location.pathname.indexOf('/activity') > -1 ||
    location.pathname.indexOf('/validation-report') > -1 ||
    location.pathname.indexOf('/xml-file') > -1 ||
    location.pathname.indexOf('/search-results') > -1 ||
    location.pathname.indexOf('/bookmarks') > -1 ||
    props.forceHide;

  return (
    <React.Fragment>
      <section css={FilterBaseStyle} ref={containerRef}>
        <div
          css={`
            z-index: 10;
            height: 100%;
            display: flex;
            width: calc(100% - 100px);
            white-space: nowrap;
            transform: translateX(${transform()}px);
          `}
        >
          {!showOnlySearch && !isSearchOpen && (
            <div
              css={`
                display: flex;
                overflow: auto;
              `}
            >
              {filterGroups.map((item: FilterItemProps) => {
                const filterGroupOpen =
                  item.label === transactionType.name.toLowerCase()
                    ? 'transactionvalue'
                    : item.label;
                return (
                  <div
                    aria-label={item.label}
                    css={FilterItemContainer(
                      filterGroupOpen === currentFilterOpen,
                      tabHasFilterActive(filterGroupOpen, selectedFilters)
                    )}
                    style={
                      item.locked || item.disabled
                        ? {
                            opacity: 0.5,
                            pointerEvents: 'none',
                          }
                        : {}
                    }
                    key={`filter-item-${filterGroupOpen}`}
                    onClick={() => {
                      onFilterGroupClick(filterGroupOpen);
                    }}
                  >
                    <FilterItem
                      label={
                        item.label === 'activitystatus'
                          ? 'activity status'
                          : item.label
                      }
                    />
                    <Divider css={FilterDividerStyle} orientation="vertical" />
                  </div>
                );
              })}
              <MoreFiltersDropdown />
            </div>
          )}
          {isSearchOpen && (
            <SearchComponent
              stayOpen={props.openSearch}
              close={() => setIsSearchOpen(false)}
            />
          )}
        </div>
        <div
          css={`
            display: flex;
            background-color: white;
            align-items: center;
            @media (max-width: 960px) {
              padding-right: 16px;
              border-radius: 20px;
            }
          `}
        >
          <IconButton
            aria-label="Open search"
            onClick={() => setIsSearchOpen(true)}
            css="padding: 6px; * { fill: #2E4063; }"
          >
            <SearchIcon />
          </IconButton>
          {(!showOnlySearch || location.pathname.indexOf('/activity') > -1) && (
            <BookmarkButton />
          )}
          {!showOnlySearch && (
            <IconButton
              aria-label="reset"
              onClick={resetFilters}
              css="padding: 6px; * { fill: #2E4063; }"
            >
              <SettingsBackupRestoreIcon />
            </IconButton>
          )}
        </div>
        <Popover
          css="overflow: visible;"
          classes={{ root: classes.popover, paper: classes.paper }}
          open={
            currentFilterOpen !== '' &&
            currentFilterOpen !== 'activity' &&
            currentFilterOpen !== 'default' &&
            currentFilterOpen !== 'organisation' &&
            currentFilterOpen !== 'sector' &&
            currentFilterOpen !== 'transaction'
          }
          anchorEl={containerRef.current}
          container={containerRef.current}
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={() => setCurrentFilterOpen('')}
          >
            <div
              css={`
                overflow: visible;
              `}
            >
              <FilterCard
                options={filterCardoptions}
                loading={get(
                  filterOptionsDataLoading,
                  currentFilterOpen,
                  false
                )}
                loadFilterOptions={onFilterGroupClick}
                filterOptionsDataLoading={filterOptionsDataLoading}
              />
            </div>
          </ClickAwayListener>
        </Popover>
      </section>
    </React.Fragment>
  );
};
