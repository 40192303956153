import React from 'react';
import get from 'lodash/get';
import { useStoreState } from 'app/state/store/hooks';
import { TabNavigationProps } from 'app/modules/search-results-module/data';
import { datapath, countpath } from 'app/components/Filter/common/Search/data';
import { SearchResultNavigation } from 'app/components/Filter/common/Search/common/SearchResultNavigation';

export function TabNavigation(props: TabNavigationProps) {
  const tabsData = useStoreState((state) => ({
    Activities: {
      count: get(state.activities, countpath, 0),
      data: get(state.activities, datapath, []),
    },
    Countries: {
      count: get(state.countries, countpath, 0),
      data: get(state.countries, datapath, []),
    },
    Organisations: {
      count: get(state.organisations, countpath, 0),
      data: get(state.organisations, datapath, []),
    },
    Publishers: {
      count: get(state.publishers, countpath, 0),
      data: get(state.publishers, datapath, []),
    },
    Donors: {
      count: get(state.donors, countpath, 0),
      data: get(state.donors, datapath, []),
    },
    All: {
      count: 0,
      data: [],
    },
  }));

  return (
    <div
      css={`
        > div {
          padding: 15px 0;
        }
      `}
    >
      <SearchResultNavigation
        noAll
        results={tabsData}
        activeTab={props.resultType}
        onChange={props.setResultType}
      />
    </div>
  );
}
