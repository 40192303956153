import React from 'react';
import get from 'lodash/get';
import Auth0JS from 'auth0-js';
import { Path } from 'app/const/Path';
import { css } from 'styled-components/macro';
import { Typography } from '@material-ui/core';
import { LogoGoogle } from 'app/assets/LogoGoogle';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { emailValidation } from 'app/utils/emailValidation';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { OnboardingTextInput } from 'app/modules/web-shop/sub-modules/onboarding/components/textinput';

interface Prop {
  splitForm?: boolean;
  setSplitForm?: React.Dispatch<React.SetStateAction<boolean>>;
}

const policyLink = css`
  color: #2e4063;
  text-decoration-line: underline;
  :hover {
    text-decoration: underline;
    color: #2e4063;
  }
`;

const socialloginbuttoncss = css`
  gap: 20px;
  width: 100%;
  height: 44px;
  display: flex;
  color: #2e4063;
  padding: 6px 0;
  font-size: 14px;
  cursor: pointer;
  font-weight: 700;
  line-height: 20px;
  border-radius: 8px;
  flex-direction: row;
  margin-bottom: 16px;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 1px solid #a1aebd;

  &:hover {
    background: #a1aebd;
  }
`;

const actionbuttoncss = (splitForm: boolean) => css`
  width: 100%;
  color: #fff;
  padding: 6px 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  border-style: none;
  border-radius: 50px;
  background: #2e4063;
  height: ${splitForm ? '40px' : 'auto'};
  margin-top: ${splitForm ? '1.4rem' : ''};
  margin-bottom: 24px;

  :disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover {
    background: #a1aebd;
  }
`;

export function SignInCard(props: Prop) {
  const history = useHistory();
  const [error, setError] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [email2, setEmail2] = React.useState('');
  const [error2, setError2] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [success, setSuccess] = React.useState(false);
  const [signinEnabled, setSigninEnabled] = React.useState(false);
  const [forgetPassEnabled, setForgetPassEnabled] = React.useState(false);
  const [showForgotPassword, setShowForgotPassword] = React.useState(
    get(history.location, 'state.forgetpassword', false)
  );

  const webAuth = new Auth0JS.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
    clientID: process.env.REACT_APP_AUTH0_CLIENT as string,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE as string,
    redirectUri: `${window.location.origin}/callback`,
  });

  function doLogin(
    connection:
      | 'google-oauth2'
      | 'linkedin'
      | 'github'
      | 'Username-Password-Authentication'
  ) {
    if (!sessionStorage.getItem('auth-redirect-url')) {
      sessionStorage.setItem(
        'auth-redirect-url',
        `${history.location.pathname}${history.location.search}`
      );
    }
    if (connection === 'Username-Password-Authentication') {
      webAuth.login(
        {
          email,
          password,
          realm: connection,
          responseType: 'token',
        },
        (err2: any, res2: any) => {
          if (err2) {
            setError(err2.description);
            setTimeout(() => {
              setError('');
            }, 10000);
          }
        }
      );
    } else {
      webAuth.authorize({
        connection,
        responseType: 'token',
      });
    }
  }

  function doSendForgetPassword() {
    setError2('');
    axios
      .post(`${process.env.REACT_APP_API}/forget-password`, {
        email: email2,
      })
      .then((res: AxiosResponse) => {
        if (res.status === 200 && get(res, 'data.statusCode', 200) === 200) {
          setSuccess(true);
        } else {
          setError2(get(res, 'data.message', 'Something went wrong'));
        }
      })
      .catch((err: AxiosError) => {
        setError2(get(err, 'response.data.message', 'Something went wrong'));
      });
  }

  React.useEffect(() => {
    setSigninEnabled(
      email.length > 0 && emailValidation(email) && password.length > 0
    );
  }, [email, password]);

  React.useEffect(() => {
    setForgetPassEnabled(email2.length > 0 && emailValidation(email2));
  }, [email2]);

  React.useEffect(() => {
    setError('');
    setEmail('');
    setEmail2('');
    setError2('');
    setPassword('');
    setSuccess(false);
  }, [showForgotPassword]);

  return (
    <div
      css={`
        display: flex;
        padding: 16px 0;
        align-items: center;
        flex-direction: column;
        justify-content: center;
      `}
    >
      {!showForgotPassword && (
        <React.Fragment>
          <button
            type="button"
            css={socialloginbuttoncss}
            onClick={() => doLogin('google-oauth2')}
          >
            <LogoGoogle /> Log in with Google
          </button>
          <button
            type="button"
            css={socialloginbuttoncss}
            onClick={() => doLogin('linkedin')}
          >
            <svg
              viewBox="0 0 24 24"
              width="24"
              height="24"
              css={`
                margin-left: 10px;
                transform: scale(1.3);
              `}
            >
              <path
                fill="#0a66c2"
                d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z"
              />
            </svg>
            Log in with LinkedIn
          </button>
          <div
            css={`
              margin: 9px 0 25px 0;
            `}
          >
            {props.splitForm ? (
              <div
                css={`
                  display: flex;
                  align-items: center;
                  gap: 14px;
                  justify-content: center;
                `}
              >
                <div
                  css={`
                    border: 0.3px solid #a1aebd;
                    width: 124px;
                    height: 0px;
                  `}
                />
                <div
                  css={`
                    color: #a1aebd;
                    font-size: 12px;
                  `}
                >
                  or log in with email
                </div>
                <div
                  css={`
                    border: 0.3px solid #a1aebd;
                    width: 124px;
                    height: 0px;
                  `}
                />
              </div>
            ) : (
              <div
                css={`
                  display: flex;
                  align-items: center;
                  gap: 5px;
                  justify-content: center;
                `}
              >
                <div
                  css={`
                    border: 0.3px solid #a1aebd;
                    width: 70px;
                    height: 0px;
                  `}
                />
                <div
                  css={`
                    color: #a1aebd;
                    font-size: 12px;
                  `}
                >
                  or log in with email
                </div>
                <div
                  css={`
                    border: 0.3px solid #a1aebd;
                    width: 70px;
                    height: 0px;
                  `}
                />
              </div>
            )}
          </div>
          <div
            css={`
              width: 100%;

              > .MuiTextField-root {
                margin-bottom: 16px;
              }
            `}
          >
            <OnboardingTextInput
              type="email"
              label="Email"
              value={email}
              setValue={setEmail}
            />
            <OnboardingTextInput
              type="password"
              label="Password"
              value={password}
              setValue={setPassword}
            />
            <button
              type="button"
              css={actionbuttoncss(props.splitForm as boolean)}
              disabled={!signinEnabled}
              onClick={() => doLogin('Username-Password-Authentication')}
            >
              Log in
            </button>
          </div>
          {error && (
            <div
              css={`
                width: 100%;
                color: #f00;
                margin-bottom: 16px;
              `}
            >
              {error}
            </div>
          )}
          <button
            type="button"
            onClick={() => setShowForgotPassword(true)}
            css={`
              padding: 0;
              width: 100%;
              color: #2e4063;
              cursor: pointer;
              font-size: 14px;
              line-height: 20px;
              text-align: start;
              border-style: none;
              margin-bottom: 12px;
              background: transparent;
              text-decoration: underline;
            `}
          >
            Forgot password?
          </button>
          {!props.splitForm && (
            <>
              <div
                css={`
                  width: 100%;
                  color: #2e4063;
                  font-size: 14px;
                  line-height: 20px;
                  margin-bottom: 24px;

                  > a {
                    text-decoration: underline;
                  }
                `}
              >
                Don&apos;t have an account?{' '}
                <Link to={Path.webshop.onboarding.replace(':page?', '')}>
                  sign up
                </Link>
              </div>

              <Typography variant="caption" align="justify" color="textPrimary">
                by signing in/up you agree with AIDA&apos;s{' '}
                <NavLink to={Path.general.privacy} css={policyLink}>
                  terms of services and privacy policy
                </NavLink>
              </Typography>
            </>
          )}
        </React.Fragment>
      )}
      {showForgotPassword && (
        <React.Fragment>
          <h4
            css={`
              width: 100%;
              font-size: 18px;
              font-weight: 700;
              margin: 0 0 8px 0;
              text-align: start;
            `}
          >
            {success ? 'Email has been sent!' : 'Forgot your password?'}
          </h4>
          <p
            css={`
              width: 100%;
              font-size: 14px;
              text-align: start;
              margin: 0 0 24px 0;
            `}
          >
            {success
              ? 'We have sent you an email to reset your password.'
              : 'Enter your registered email below to confirm your password reset.'}
          </p>
          {success && (
            <p
              css={`
                margin: 0;
                width: 100%;
                font-size: 14px;
                text-align: start;
              `}
            >
              Didn’t receive the email?
            </p>
          )}
          {!success && (
            <OnboardingTextInput
              type="email"
              label="Email"
              value={email2}
              setValue={setEmail2}
            />
          )}
          {error2 && (
            <div
              css={`
                width: 100%;
                color: #f00;
                margin-bottom: 16px;
              `}
            >
              {error2}
            </div>
          )}
          <button
            type="button"
            css={actionbuttoncss(props.splitForm as boolean)}
            style={{ marginTop: 12 }}
            disabled={!forgetPassEnabled}
            onClick={doSendForgetPassword}
          >
            {success ? 'Resend Email' : 'Send Email'}
          </button>
          <button
            type="button"
            onClick={() => setShowForgotPassword(false)}
            css={`
              padding: 0;
              width: 100%;
              color: #2e4063;
              cursor: pointer;
              font-size: 14px;
              line-height: 20px;
              text-align: start;
              border-style: none;
              margin-bottom: 12px;
              background: transparent;
              text-decoration: underline;
            `}
          >
            Back to log in
          </button>
        </React.Fragment>
      )}
    </div>
  );
}
