import { atom, RecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const drawerAtom = atom({
  key: 'drawerAtom',
  default: false,
});

export const planUserCountAtom = atom({
  key: 'planUserCountAtom',
  default: 1,
  effects_UNSTABLE: [persistAtom],
});

export const planNameAtom: RecoilState<'Free' | 'Standard' | 'Premium'> = atom({
  key: 'planNameAtom',
  default: 'Free',
  effects_UNSTABLE: [persistAtom],
});

export const planSupportAtom = atom({
  key: 'planSupportAtom',
  default: true,
  effects_UNSTABLE: [persistAtom],
});

export const freeTrialAtom = atom({
  key: 'freeTrialAtom',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const installmentPaymentIdAtom = atom({
  key: 'installmentPaymentIdAtom',
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const testPaymentIdAtom = atom({
  key: 'testPaymentIdAtom',
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const filterPopupAtom = atom({
  key: 'filterPopupAtom',
  default: '',
});

export const driveAutomationNameAtom = atom({
  key: 'driveAutomationNameAtom',
  default: '',
  effects_UNSTABLE: [persistAtom],
});

export interface SelectedFilterAtomModel {
  countries: string[];
  regions: string[];
  budget: string[];
  sectors: string[];
  donors: string[];
  organisations: string[];
  publishers: string[];
  period: string[];
  activitystatus: string[];
  activityscope: string[];
  documentlinkcategory: string[];
  hierarchy: string[];
  humanitarianscopevocab: string[];
  humanitarian: string[];
  humanitarianscope: string[];
  iativersion: string[];
  otheridentifiertype: string[];
  tag: string[];
  defaultaidtype: string[];
  defaultaidtypecategory: string[];
  defaultaidtypevocabulary: string[];
  currency: string[];
  defaultfinancetype: string[];
  defaultflowtype: string[];
  language: string[];
  defaulttiedstatus: string[];
  transactionreceiverorg: string[];
  reportingorgtype: string[];
  collaborationtype: string[];
  secondaryreporter: string[];
  sectorvocabulary: string[];
  policymarker: string[];
  transactionflowtype: string[];
  transactiontiedstatus: string[];
  transactionhumanitarian: string[];
  transactiontype: string[];
  transactionvaluecurrency: string[];
  transactionvalue: string[];
}

export const selectedFilterAtom: RecoilState<SelectedFilterAtomModel> = atom({
  key: 'selectedFilterAtom',
  default: {
    countries: [] as string[],
    regions: [] as string[],
    budget: [] as string[],
    sectors: [] as string[],
    donors: [] as string[],
    organisations: [] as string[],
    publishers: [] as string[],
    period: [] as string[],
    activitystatus: [] as string[],
    activityscope: [] as string[],
    documentlinkcategory: [] as string[],
    hierarchy: [] as string[],
    humanitarianscopevocab: [] as string[],
    humanitarian: [] as string[],
    humanitarianscope: [] as string[],
    iativersion: [] as string[],
    otheridentifiertype: [] as string[],
    tag: [] as string[],
    defaultaidtype: [] as string[],
    defaultaidtypecategory: [] as string[],
    defaultaidtypevocabulary: [] as string[],
    currency: [] as string[],
    defaultfinancetype: [] as string[],
    defaultflowtype: [] as string[],
    language: [] as string[],
    defaulttiedstatus: [] as string[],
    transactionreceiverorg: [] as string[],
    reportingorgtype: [] as string[],
    collaborationtype: [] as string[],
    secondaryreporter: [] as string[],
    sectorvocabulary: [] as string[],
    policymarker: [] as string[],
    transactionflowtype: [] as string[],
    transactiontiedstatus: [] as string[],
    transactionhumanitarian: [] as string[],
    transactiontype: [] as string[],
    transactionvaluecurrency: [] as string[],
    transactionvalue: [] as string[],
  },
});

export const filterCardTabState = atom({
  key: 'filterCardTabState',
  default: [
    {
      index: 0,
      active: false,
      disabled: true,
      label: 'Recent',
      handleClick: () => {
        // do nothing
      },
    },
    {
      index: 1,
      active: true,
      disabled: false,
      label: 'All',
      handleClick: () => {
        // do nothing
      },
    },
  ],
  dangerouslyAllowMutability: true,
});

export const uploadedFilesAtom = atom({
  key: 'uploadedFilesAtom',
  default: [] as File[],
});

export const financialRouteTabsAtom = atom({
  key: 'financialRouteTabsAtom',
  default: 'Summary',
});

export const lineYearPeriodFilter = atom({
  key: 'lineYearPeriodFilter',
  default: '',
});

export const exploreLinksState = atom({
  key: 'exploreLinksState',
  default: 0,
});

export const detailLinksState = atom({
  key: 'detailLinksState',
  default: 0,
});

export const SwitchMapState = atom({
  key: 'switchMapState',
  default: true,
});

export const ChangeEmailDialogState = atom({
  key: 'changeEmailDialogState',
  default: false,
});

export const ChangePhotoDialogState = atom({
  key: 'changePhotoDialogState',
  default: false,
});

export const LandingDialogState = atom({
  key: 'changeLandingDialogState',
  default: false,
});

export const ExploreTooltipState = atom({
  key: 'exploreTooltipState',
  default: true,
});

export const editFileAtom = atom({
  key: 'editFileAtom',
  default: {
    fileName: '',
    type: '',
    published: false,
  },
});

export interface TransactionTypeModel {
  code: string;
  name: string;
  language: string;
  description: string;
}

export const transactionTypeAtom: RecoilState<TransactionTypeModel> = atom({
  key: 'transactionType',
  default: {
    code: '2',
    name: 'Outgoing Commitment',
    language: 'en',
    description:
      'A firm, written obligation from a donor or provider to provide a specified amount of funds, under particular terms and conditions, for specific purposes, for the benefit of the recipient. ',
  },
  effects_UNSTABLE: [persistAtom],
});

export const ChangePasswordDialogState = atom({
  key: 'changePasswordDialogState',
  default: false,
});

export const PasswordSecurityDialogState = atom({
  key: 'passwordSecurityDialogState',
  default: false,
});

export const ContinueExploringDialogState = atom({
  key: 'continueExploringDialogState',
  default: {
    open: false,
    redirect: '/',
  },
});

export const NewsLetterDialogState = atom({
  key: 'newsLetterDialogState',
  default: {
    open: false,
    // redirect: '/',
  },
});
