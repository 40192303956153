import React from 'react';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { useUpdateEffect } from 'react-use';
import { DataTable } from 'app/components/Charts/table';
import { useStoreActions, useStoreState } from 'app/state/store/hooks';
import {
  CountriesDataTableColumns,
  CountriesDataTableOptions,
} from 'app/components/Charts/table/data';
import { MUIDataTableOptions } from 'mui-datatables';
import { useRecoilValue } from 'recoil';
import { transactionTypeAtom } from 'app/state/recoil/atoms';

interface CountriesTableModuleProps {
  tableOptions?: MUIDataTableOptions;
}

export function CountriesTableModule(props: CountriesTableModuleProps) {
  const action = useStoreActions((actions) => actions.countries.fetch);
  const actionData = useStoreState((actions) => actions.countries.data);
  const transactionType = useRecoilValue(transactionTypeAtom);

  const tableData = get(actionData, 'data', []);
  const tableCount = get(actionData, 'count', 0);
  const searchTerm = useStoreState((state) => state.syncSearch.value);
  const tableOptions = props.tableOptions
    ? props.tableOptions
    : CountriesDataTableOptions;

  function callAction(q?: string) {
    action({
      values: {
        filters: q && q.length > 0 ? q : {},
        transactionTypeCode: transactionType.code,
      },
    });
  }

  React.useEffect(() => {
    if (tableData.length === 0) {
      callAction(searchTerm);
    }
  }, [transactionType]);

  useUpdateEffect(() => {
    if (searchTerm && searchTerm.length > 3) {
      callAction(searchTerm);
    }
  }, [searchTerm, transactionType]);

  return (
    <DataTable
      data={tableData}
      columns={CountriesDataTableColumns(transactionType.name)}
      options={{
        ...tableOptions,
        count: tableCount,
        sortOrder: {
          name: transactionType.name,
          direction: 'desc',
        },
        customSort: (data: any[], colIndex: number, order: string) => {
          if (colIndex === 1) {
            return orderBy(
              data,
              (item: any) => item.data[colIndex][1],
              order === 'asc' ? 'asc' : 'desc'
            );
          }
          return orderBy(
            data,
            (item: any) => item.data[colIndex],
            order === 'asc' ? 'asc' : 'desc'
          );
        },
      }}
      title={`AIDA has found ${new Intl.NumberFormat('nl-NL').format(
        tableCount
      )} countries for you`}
    />
  );
}
