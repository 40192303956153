/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import get from 'lodash/get';
import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { TableRow, TableCell } from '@material-ui/core';
import { selectedFilterAtom } from 'app/state/recoil/atoms';
import { ExpandableRows } from 'app/components/Charts/table/common/rows/ExpandableRows';
import { ExpandButton } from 'app/components/Charts/table/common/rows/ExpandableRows/common/ExpandBtn';
import { formatLocale } from 'app/utils/formatLocale';

const tableCellColors = [
  'rgba(182, 182, 182, 0)',
  'rgba(182, 182, 182, 0.2)',
  'rgba(182, 182, 182, 0.4)',
  'rgba(182, 182, 182, 0.6)',
];

function isExpanded(selected: string, name: string) {
  if (selected === name) {
    return true;
  }
  return false;
}

export const ExpandableRow = (props: any) => {
  const history = useHistory();
  const [selectedFilters, setSelectedFilters] =
    useRecoilState(selectedFilterAtom);
  const [expanded, setExpanded] = React.useState(
    isExpanded(props.selected, props.child.title)
  );

  function onLinkClick(code: string) {
    setSelectedFilters({
      ...selectedFilters,
      sectors: [...selectedFilters.sectors, code],
    });
    setTimeout(() => {
      history.push(
        `${history.location.pathname.replace('sectors', 'projects')}${
          history.location.search
        }`
      );
    }, 100);
  }

  React.useEffect(() => {
    setExpanded(isExpanded(props.selected, props.child.title));
  }, [props.selected]);

  const children = get(props, 'child.children', []);

  return (
    <React.Fragment>
      <TableRow
        onClick={() => {
          if (children.length > 0) {
            setExpanded(!expanded);
          }
        }}
        css={`
          && {
            background-color: ${tableCellColors[props.level]};

            ${children.length > 0
              ? `
                &:hover {
                  cursor: pointer;
                }
              `
              : ''}
          }
        `}
      >
        <TableCell colSpan={1}>
          {children.length > 0 && (
            <ExpandButton
              expanded={expanded}
              onExpand={() => {
                const updatedPrevSelections = [...props.prevSelections];
                if (expanded) {
                  updatedPrevSelections.pop();
                  props.setSelected(
                    updatedPrevSelections[updatedPrevSelections.length - 1]
                  );
                } else {
                  updatedPrevSelections.push(props.child.title);
                  props.setSelected(props.child.title);
                }
                props.setPrevSelections(updatedPrevSelections);
                setExpanded(!expanded);
              }}
            />
          )}
        </TableCell>
        <TableCell key={props.child.code} colSpan={1}>
          {props.child.code}
        </TableCell>
        <TableCell key={props.child.title} colSpan={1}>
          <span
            css="color: #2E4063;cursor: pointer;"
            onClick={() => onLinkClick(props.child.code)}
          >
            {props.child.title}
          </span>
        </TableCell>
        <TableCell key={props.child.size} colSpan={1}>
          {props.level === 1 &&
            props.child.size &&
            formatLocale(props.child.size)}
          {props.level !== 1 && formatLocale(props.child.size)}
        </TableCell>
        <TableCell key={props.child.sum} colSpan={1}>
          {formatLocale(props.child.sum)}
        </TableCell>
      </TableRow>
      {expanded && children.length > 0 && (
        <ExpandableRows
          data={children}
          level={props.level + 1}
          selected={props.selected}
          setSelected={props.setSelected}
          key={`${props.child.title}-children`}
        />
      )}
    </React.Fragment>
  );
};
