import React from 'react';
import { Path } from 'app/const/Path';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useWindowScroll } from 'react-use';
import { useAuth0 } from '@auth0/auth0-react';
import { LogoAida } from 'app/assets/LogoAida';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import { NavLink, useLocation } from 'react-router-dom';
import {
  AppBarPageModel,
  pages,
} from 'app/modules/web-shop/components/appbar/data';
import {
  appbarcss,
  logolinkcss,
  appbarlinkcss,
  appbartoolbarcss,
  linksContainerCss,
  buttonFilledCss,
  buttonOutlineCss,
} from 'app/modules/web-shop/components/appbar/style';

export function Appbar() {
  const location = useLocation();
  const { y } = useWindowScroll();
  const { user, isAuthenticated } = useAuth0();

  return (
    <Box
      component="nav"
      css={appbarcss}
      style={{
        backgroundColor: y > 78 ? '#def2f0' : 'transparent',
        display:
          location.pathname.indexOf(
            Path.webshop.onboarding.replace('/:page?', '')
          ) > -1 ||
          location.pathname.indexOf(
            Path.webshop.payment.replace('/:page?', '')
          ) > -1
            ? 'none'
            : 'block',
      }}
    >
      <Container maxWidth="lg" css={appbartoolbarcss}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xl={4} lg={4} sm={2}>
            <NavLink to={Path.webshop.home} css={logolinkcss}>
              <IconButton edge="start" color="inherit" aria-label="Go to home">
                <LogoAida />
              </IconButton>
              <div>AIDA</div>
            </NavLink>
          </Grid>

          <Grid
            container
            xl={8}
            lg={8}
            sm={10}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid css={linksContainerCss}>
              {pages.map((page: AppBarPageModel) => (
                <NavLink
                  key={page.title}
                  to={page.path}
                  css={appbarlinkcss(location.pathname === page.path)}
                >
                  {page.title}
                </NavLink>
              ))}
            </Grid>

            <Grid
              css={`
                display: flex;
                gap: 0.8rem;
              `}
            >
              <NavLink to={Path.basePath}>
                <button type="button" css={buttonOutlineCss}>
                  Explorer
                </button>
              </NavLink>
              <NavLink
                to={
                  isAuthenticated && user
                    ? Path.home
                    : Path.webshop.onboarding.replace(':page?', '')
                }
              >
                <button type="button" css={buttonFilledCss}>
                  My AIDA
                </button>
              </NavLink>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
