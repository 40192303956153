/* eslint-disable react/require-default-props */
import React from 'react';
import Select from 'react-select';
import {
  customStyles,
  Option,
  DropdownIndicator,
  MultiValueRemove,
} from './FilterSelectStyles';

interface FilterSelectProps {
  options: { value: string; label: string }[];
  isSingle?: boolean;
  placeholder: string;
  defaultValue?:
    | { value: string; label: string }
    | { value: string; label: string }[];
  value?:
    | { value: string; label: string }
    | { value: string; label: string }[]
    | null;
  onMenuOpen?: () => void;
  onChange?: (v: any, a: any) => void;
  isLoading?: boolean;
}
export const FilterSelect = (props: FilterSelectProps) => {
  return (
    <Select
      // @ts-ignore
      styles={customStyles}
      isMulti={!props.isSingle}
      defaultValue={props.defaultValue ? props.defaultValue : null}
      value={props.value ? props.value : undefined}
      hideSelectedOptions={false}
      components={{ Option, DropdownIndicator, MultiValueRemove }}
      {...props}
    />
  );
};
