import React from 'react';
import theme from 'app/theme';

interface GeneralButtonProps {
  label: string;
  backgroundColor?: string;
}

export const GeneralButton = (props: GeneralButtonProps) => {
  // tidy up and make re-useable
  return (
    <div
      css={`
        /* width: 148px; */
        padding-left: 20px;
        padding-right: 20px;
        height: 32px;
        right: 16px;
        background: ${props.backgroundColor
          ? props.backgroundColor
          : theme.palette.primary.main};
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
      `}
    >
      <div
        css={`
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 1px;
          color: ${theme.palette.common.white};
        `}
      >
        {props.label}
      </div>
    </div>
  );
};
