import React from 'react';
import { Path } from 'app/const/Path';
import Box from '@material-ui/core/Box';
import { NavLink } from 'react-router-dom';
import { css } from 'styled-components/macro';
import AchtergrondMap from 'app/modules/common/no-match-page/map.svg';
import { GeneralButton } from 'app/modules/explore-module/common/CountryPopupButton';

const MapContainer = css`
  background-image: url(${AchtergrondMap});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  height: calc(100vh - 48px);
  width: 100vw;
  left: 0;
  position: absolute;
  overflow: hidden;
`;

const Container404 = css`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TextOne = css`
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.1px;
  color: rgba(1, 1, 10, 0.6); ;
`;

const TextTwo = css`
  font-family: Inter, sans-serif;
  font-size: 120px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 2.15px;
  color: #525252;
`;

const TextThree = css`
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 1.25px;
  text-align: center;
  color: #525252;
  margin-bottom: 40px;
`;

export const NoMatchPage = () => {
  return (
    <React.Fragment>
      <div css={MapContainer}>
        <Box height="140px" />
        <div
          css={`
            display: flex;
            align-self: center;
          `}
        >
          <div css={Container404}>
            <Box height="20px" />
            <div css={TextOne}>Oops! Page not found</div>
            <Box height="10px" />
            <div css={TextTwo}>404</div>
            <Box height="40px" />
            <div css={TextThree}>
              We are sorry, but the page you requested was not found
            </div>
            <NavLink to={Path.home}>
              <GeneralButton label="See Activities" />
            </NavLink>
            <Box height="10px" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
