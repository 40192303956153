import { Slider as MuiSlider, Tooltip as MuiTooltip } from '@material-ui/core';
import styled from 'styled-components';
import { ProjectPalette, TextStyle } from 'app/theme';
import withStyles from '@material-ui/core/styles/withStyles';
import { css } from 'styled-components/macro';

export const Slider = styled(MuiSlider)`
  width: 100%;
  margin: 0 16px;

  && .MuiSlider-root {
    //color: ${ProjectPalette.text.primary};
    height: 3px;
    color: #d7d8d9;
  }

  .MuiSlider-rail {
    height: 3px;
    opacity: unset;
    background-color: #ededf6;
  }

  .MuiSlider-track {
    height: 5px;
    transform: translateY(-1px);
    background-color: ${ProjectPalette.primary.main};
  }

  .MuiSlider-thumb {
    width: 16px;
    height: 16px;
    margin-top: -6px;
    background-color: ${ProjectPalette.primary.main};
  }
`;

export const Tooltip = withStyles({
  tooltip: {
    margin: '13px -1px 13px -1px',
    backgroundColor: '#C4C4C4',
    fontFamily: TextStyle.fontFamily,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.25px',
    padding: '9px 7px',
  },
  arrow: {
    color: '#C4C4C4',
  },
})(MuiTooltip);

export const styles = {
  container: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
  `,
  label: css`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    color: #626262;
  `,
};
