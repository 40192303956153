import { Path } from 'app/const/Path';

export interface AppBarPageModel {
  title: string;
  path: string;
}

export const pages: AppBarPageModel[] = [
  {
    title: 'Data services',
    path: Path.webshop.services,
  },
  {
    title: 'Pricing',
    path: Path.webshop.plans,
  },
  {
    title: 'Support',
    path: Path.webshop.support,
  },
  {
    title: 'About',
    path: Path.webshop.about,
  },
];
