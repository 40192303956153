import { APIModel } from 'app/state/api';
import { ApiCallModel } from 'app/state/api/interfaces';

const updateUserNewsletterSubscription: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_AUTH_API}/update-user-newsletter-subscription`
  ),
};

export default updateUserNewsletterSubscription;
