// base
import { createStore, persist } from 'easy-peasy';
import { StoreModel } from 'app/state/api/interfaces';

// global search actions-reducers
import searchDonors from 'app/state/api/actions-reducers/global-search/donors';
import searchCountries from 'app/state/api/actions-reducers/global-search/countries';
import searchActivities from 'app/state/api/actions-reducers/global-search/activities';
import searchPublishers from 'app/state/api/actions-reducers/global-search/publishers';
import searchOrganisations from 'app/state/api/actions-reducers/global-search/organisations';

// viz actions-reducers
import treemap from 'app/state/api/actions-reducers/treemap';
import sunburst from 'app/state/api/actions-reducers/sunburst';
import linechart from 'app/state/api/actions-reducers/linechart';
import donorsTreemap from 'app/state/api/actions-reducers/donorsTreemap';
import geomapPublishers from 'app/state/api/actions-reducers/geomapPublishers';
import geomapDonors from 'app/state/api/actions-reducers/geomapDonors';
import geomapActivities from 'app/state/api/actions-reducers/geomapActivities';
import activitiesTable from 'app/state/api/actions-reducers/activitiesTable';
import donorsTable from 'app/state/api/actions-reducers/donorsTable';
import publishersTable from 'app/state/api/actions-reducers/publishersTable';

// table actions-reducers
import donors from 'app/state/api/actions-reducers/donors';
import sectors from 'app/state/api/actions-reducers/sectors';
import countries from 'app/state/api/actions-reducers/countries';
import activities from 'app/state/api/actions-reducers/activities';
import publishers from 'app/state/api/actions-reducers/publishers';
import organisations from 'app/state/api/actions-reducers/organisations';

// detail pages
import countryDetail from 'app/state/api/actions-reducers/detail-pages/country';
import donorDetail from 'app/state/api/actions-reducers/detail-pages/donor';
import publisherDetail from 'app/state/api/actions-reducers/detail-pages/publisher';
import organisationDetail from 'app/state/api/actions-reducers/detail-pages/organisation';
import detailActivities from 'app/state/api/actions-reducers/detail-pages/detailActivities';
import organisationsTable from 'app/state/api/actions-reducers/detail-pages/organisationsTable';
import detailPublishersTable from 'app/state/api/actions-reducers/detail-pages/detailPublishersTable';
import detailDonorsTable from 'app/state/api/actions-reducers/detail-pages/detailDonorsTable';
import sectorsTable from 'app/state/api/actions-reducers/detail-pages/sectorsTable';
import activityDetail, {
  activityDetailSectors,
  activityDetailFinancial,
  activityDetailFinancialExpand,
  activityDetailFinancialBudgetFlow,
  activityDetailFinancialPerformance,
  activityDetailFinancialTransactions,
} from 'app/state/api/actions-reducers/detail-pages/activityDetail';

// filtering
import {
  countries as fcountries,
  regions,
  sectors as fsectors,
  donors as fdonors,
  organisations as forganisations,
  publishers as fpublishers,
  activitystatus,
  activityscope,
  documentlinkcategory,
  hierarchy,
  humanitarianscopevocab,
  humanitarianscope,
  iativersion,
  otheridentifiertype,
  tag,
  defaultaidtype,
  defaultaidtypecategory,
  defaultaidtypevocabulary,
  currency,
  defaultfinancetype,
  defaultflowtype,
  language,
  defaulttiedstatus,
  transactionreceiverorg,
  reportingorgtype,
  collaborationtype,
  sectorvocabulary,
  policymarker,
  transactionflowtype,
  transactiontiedstatus,
  transactiontype,
  transactionvaluecurrency,
} from 'app/state/api/actions-reducers/filterOptions';

// sync variables
import syncSearch from 'app/state/api/actions-reducers/sync/search';
import filtersUpdated from 'app/state/api/actions-reducers/sync/filtersUpdated';

// auth
import user from 'app/state/api/actions-reducers/auth/user';
import users from 'app/state/api/actions-reducers/auth/users';
import updateUser from 'app/state/api/actions-reducers/auth/updateUser';
import deleteUser from 'app/state/api/actions-reducers/auth/deleteUser';
import updateOrg from 'app/state/api/actions-reducers/auth/updateOrg';
import inviteUsers from 'app/state/api/actions-reducers/auth/inviteUsers';
import roles from 'app/state/api/actions-reducers/auth/roles';
import removeOrgUsers from 'app/state/api/actions-reducers/auth/removeOrgUsers';
import changeUserRole from 'app/state/api/actions-reducers/auth/changeUserRole';
import updateUserNewsletterSubscription from 'app/state/api/actions-reducers/auth/updateUserNewsletterSubscription';

// iati registry
import registryOrganisation from 'app/state/api/actions-reducers/registry/registryOrganisation';
import organisationsTreemap from 'app/state/api/actions-reducers/organisationsTreemap';

// data manager
import convertedFiles from 'app/state/api/actions-reducers/data-manager/convertedFiles';

// bookmarks
import {
  createBookmark,
  deleteBookmark,
  getBookmarks,
  editBookmark,
} from 'app/state/api/actions-reducers/auth/bookmarks';
import moneybirdContactDetails from '../api/actions-reducers/auth/moneybirdContactDetails';
import {
  createDataAlert,
  deleteDataAlert,
} from '../api/actions-reducers/auth/dataAlerts';
import molliePaymentMethod from '../api/actions-reducers/auth/molliePaymentMethod';
import googleDriveAutomations from '../api/actions-reducers/data-manager/googleDriveAutomations';

const storeContent: StoreModel = {
  // filtering
  filterOptions: {
    countries: persist(fcountries, {
      storage: 'localStorage',
    }),
    regions: persist(regions, {
      storage: 'localStorage',
    }),
    sectors: persist(fsectors, {
      storage: 'localStorage',
    }),
    donors: persist(fdonors, {
      storage: 'localStorage',
    }),
    organisations: persist(forganisations, {
      storage: 'localStorage',
    }),
    publishers: persist(fpublishers, {
      storage: 'localStorage',
    }),
    activitystatus: persist(activitystatus, {
      storage: 'localStorage',
    }),
    activityscope: persist(activityscope, {
      storage: 'localStorage',
    }),
    documentlinkcategory: persist(documentlinkcategory, {
      storage: 'localStorage',
    }),
    hierarchy: persist(hierarchy, {
      storage: 'localStorage',
    }),
    humanitarianscopevocab: persist(humanitarianscopevocab, {
      storage: 'localStorage',
    }),
    humanitarianscope: persist(humanitarianscope, { storage: 'localStorage' }),
    iativersion: persist(iativersion, { storage: 'localStorage' }),
    otheridentifiertype: persist(otheridentifiertype, {
      storage: 'localStorage',
    }),
    tag: persist(tag, {
      storage: 'localStorage',
    }),
    defaultaidtype: persist(defaultaidtype, {
      storage: 'localStorage',
    }),
    defaultaidtypecategory: persist(defaultaidtypecategory, {
      storage: 'localStorage',
    }),
    defaultaidtypevocabulary: persist(defaultaidtypevocabulary, {
      storage: 'localStorage',
    }),
    currency: persist(currency, {
      storage: 'localStorage',
    }),
    defaultfinancetype: persist(defaultfinancetype, {
      storage: 'localStorage',
    }),
    defaultflowtype: persist(defaultflowtype, {
      storage: 'localStorage',
    }),
    language: persist(language, {
      storage: 'localStorage',
    }),
    defaulttiedstatus: persist(defaulttiedstatus, { storage: 'localStorage' }),
    transactionreceiverorg: persist(transactionreceiverorg, {
      storage: 'localStorage',
    }),
    reportingorgtype: persist(reportingorgtype, { storage: 'localStorage' }),
    collaborationtype: persist(collaborationtype, { storage: 'localStorage' }),
    sectorvocabulary: persist(sectorvocabulary, { storage: 'localStorage' }),
    policymarker: persist(policymarker, { storage: 'localStorage' }),
    transactionflowtype: persist(transactionflowtype, {
      storage: 'localStorage',
    }),
    transactiontiedstatus: persist(transactiontiedstatus, {
      storage: 'localStorage',
    }),
    transactiontype: persist(transactiontype, {
      storage: 'localStorage',
    }),
    transactionvaluecurrency: persist(transactionvaluecurrency, {
      storage: 'localStorage',
    }),
  },
  // global search
  searchDonors,
  searchCountries,
  searchActivities,
  searchPublishers,
  searchOrganisations,
  // viz
  donors: persist(donors),
  sectors: persist(sectors),
  treemap: persist(treemap),
  sunburst: persist(sunburst),
  donorsTreemap: persist(donorsTreemap),
  organisationsTreemap: persist(organisationsTreemap),
  // table
  countries: persist(countries),
  linechart: persist(linechart),
  activities: persist(activities),
  publishers: persist(publishers),
  organisations: persist(organisations),
  activitiesTable: persist(activitiesTable),
  geomapActivities: persist(geomapActivities),
  geomapDonors: persist(geomapDonors),
  donorsTable: persist(donorsTable),
  geomapPublishers: persist(geomapPublishers),
  publishersTable: persist(publishersTable),
  // detail pages
  countryDetail: persist(countryDetail),
  donorDetail: persist(donorDetail),
  publisherDetail: persist(publisherDetail),
  organisationDetail: persist(organisationDetail),
  detailActivities: persist(detailActivities),
  organisationsTable: persist(organisationsTable),
  sectorsTable: persist(sectorsTable),
  activityDetail: persist(activityDetail),
  activityDetailSectors: persist(activityDetailSectors),
  activityDetailFinancial: persist(activityDetailFinancial),
  activityDetailFinancialExpand: persist(activityDetailFinancialExpand),
  activityDetailFinancialBudgetFlow: persist(activityDetailFinancialBudgetFlow),
  activityDetailFinancialTransactions: persist(
    activityDetailFinancialTransactions
  ),
  activityDetailFinancialPerformance: persist(
    activityDetailFinancialPerformance
  ),
  detailPublishersTable: persist(detailPublishersTable),
  detailDonorsTable: persist(detailDonorsTable),
  // sync variables
  syncSearch: persist(syncSearch),
  filtersUpdated: persist(filtersUpdated),
  // auth
  users: persist(users),
  user: persist(user),
  updateUser: persist(updateUser),
  deleteUser: persist(deleteUser),
  updateOrg: persist(updateOrg),
  inviteUsers: persist(inviteUsers),
  roles: persist(roles),
  removeOrgUsers: persist(removeOrgUsers),
  changeUserRole: persist(changeUserRole),
  updateUserNewsletterSubscription: persist(updateUserNewsletterSubscription),
  moneybirdContactDetails: persist(moneybirdContactDetails),
  molliePaymentMethod: persist(molliePaymentMethod),
  // iati registry
  registryOrganisation: persist(registryOrganisation),
  // data manager
  convertedFiles: persist(convertedFiles),
  googleDriveAutomations: persist(googleDriveAutomations),
  // bookmarks
  getBookmarks: persist(getBookmarks),
  createBookmark,
  deleteBookmark,
  editBookmark,
  createDataAlert,
  deleteDataAlert,
};

export const store = createStore(storeContent);
