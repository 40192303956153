import React from 'react';
import find from 'lodash/find';
import filter from 'lodash/filter';
import Grid from '@material-ui/core/Grid';
import { FilterProps } from 'app/components/FilterCard';
import { Label } from 'app/components/FilterCard/MoreFilters/common/FilterSelectStyles';
import { FilterSelect } from 'app/components/FilterCard/MoreFilters/common/FilterSelect';
import { MoreFiltersCard } from 'app/components/FilterCard/MoreFilters/common/MoreFilterCard';

interface SectorFilterProps extends FilterProps {
  filterOptionsDataLoading: any;
  loadFilterOptions: (arg0: string, arg1: boolean) => void;
}

export const SectorFilter = (props: SectorFilterProps) => {
  function onChange(actionMeta: any, type: string) {
    const options = { ...props.selectedOptions };
    if (actionMeta.action === 'select-option') {
      options[type] = [...options[type], actionMeta.option.value];
    }
    if (
      actionMeta.action === 'remove-value' ||
      actionMeta.action === 'pop-value'
    ) {
      options[type] = filter(
        options[type],
        (v: any) => v !== actionMeta.removedValue.value
      );
    }
    if (actionMeta.action === 'deselect-option') {
      options[type] = filter(
        options[type],
        (v: any) => v !== actionMeta.option.value
      );
    }
    if (actionMeta.action === 'clear') {
      options[type] = [];
    }
    props.setSelectedOptions(options);
  }

  return (
    <MoreFiltersCard
      header="Sector filter"
      selectedOptions={props.selectedOptions}
    >
      {/* -------------------------------------- */}
      {/* sector filter */}
      {/* -------------------------------------- */}
      {/* sector vocabulary */}
      <Grid item xs={12} md={12} lg={12}>
        <Label>Sector vocabulary</Label>
        <FilterSelect
          options={props.options.sectorvocabulary.map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          placeholder="All"
          onMenuOpen={() => {
            props.loadFilterOptions('sectorvocabulary', true);
          }}
          defaultValue={filter(props.options.sectorvocabulary, (item: any) =>
            find(
              props.selectedFilters.sectorvocabulary,
              (f: any) => f === item.code
            )
          ).map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          onChange={(value: any, actionMeta: any) => {
            onChange(actionMeta, 'sectorvocabulary');
          }}
          isLoading={props.filterOptionsDataLoading.sectorvocabulary}
        />
      </Grid>
      {/* -------------------------------------- */}
      {/* policy marker */}
      <Grid item xs={12} md={12} lg={12}>
        <Label>Policy marker</Label>
        <FilterSelect
          options={props.options.policymarker.map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          placeholder="All"
          onMenuOpen={() => {
            props.loadFilterOptions('policymarker', true);
          }}
          defaultValue={filter(props.options.policymarker, (item: any) =>
            find(
              props.selectedFilters.policymarker,
              (f: any) => f === item.code
            )
          ).map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          onChange={(value: any, actionMeta: any) => {
            onChange(actionMeta, 'policymarker');
          }}
          isLoading={props.filterOptionsDataLoading.policymarker}
        />
      </Grid>
    </MoreFiltersCard>
  );
};
