import React from 'react';
import { FilterProps } from '../index';
import { MainFiltersCard } from './common/MainFilterCard';
import { AlphabeticallyGroupedOptions } from './common/AlphabeticallyGroupedOptions';

export const DonorsFilter = (props: FilterProps) => (
  <MainFiltersCard title="Donors filter" {...props}>
    <AlphabeticallyGroupedOptions {...props} key="CountriesFilter-container" />
  </MainFiltersCard>
);
