import React from 'react';
import find from 'lodash/find';
import { useRecoilState } from 'recoil';
import { FilterProps } from 'app/components/FilterCard';
import { filterCardTabState } from 'app/state/recoil/atoms';
import { splitOnCodeLength } from 'app/components/FilterCard/MainFilters/common/utils';
import { MainFiltersCard } from 'app/components/FilterCard/MainFilters/common/MainFilterCard';
import { AlphabeticallyGroupedOptions } from 'app/components/FilterCard/MainFilters/common/AlphabeticallyGroupedOptions';

export const SectorsFilter = (props: FilterProps) => {
  const [tabs] = useRecoilState(filterCardTabState);
  const currentTabIndex = find(tabs, 'active')?.index;

  function renderOptions() {
    const options = splitOnCodeLength(props.shownOptions);
    switch (currentTabIndex) {
      case 0:
        return {}; // TODO: recent
      case 1:
        return (
          <AlphabeticallyGroupedOptions
            {...props}
            shownOptions={options.sectorDAC3}
            key="SectorsFilter-DAC3"
          />
        );
      case 2:
        return (
          <AlphabeticallyGroupedOptions
            {...props}
            shownOptions={options.sectorDAC5}
            key="SectorsFilter-DAC5"
          />
        );
      default:
        return {};
    }
  }

  return (
    <MainFiltersCard title="Sectors" {...props} sector>
      {renderOptions()}
    </MainFiltersCard>
  );
};
