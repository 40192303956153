import React from 'react';
import findIndex from 'lodash/findIndex';
import Grid from '@material-ui/core/Grid';
import { css } from 'styled-components/macro';
import { FilterProps } from 'app/components/FilterCard';
import {
  FilterOptionItem,
  OptionsModel,
} from 'app/components/FilterCard/MainFilters/common/FilterOptionItem';
import {
  MainFiltersCard,
  MainFiltersCardProps,
} from 'app/components/FilterCard/MainFilters/common/MainFilterCard';

const AcivityStatusFilterContainerStyle = css`
  padding: 30px 0px 20px 0px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  > div {
    max-width: 33%;
    flex-basis: 33%;
  }
`;

export const AcivityStatusFilter = (props: FilterProps) => (
  <MainFiltersCard title="Activity Status" {...props}>
    <FilterOptions {...props} key="AcivityStatusFilter-container" />
  </MainFiltersCard>
);

const FilterOptions = (props: MainFiltersCardProps) => {
  return (
    <Grid container item spacing={0}>
      <div css={AcivityStatusFilterContainerStyle}>
        {props.shownOptions.map((item: OptionsModel) => (
          <FilterOptionItem
            {...props}
            key={`${item.code}-${item.name}`}
            value={item.code}
            label={item.name}
            selected={
              findIndex(
                props.selectedOptions,
                (option: string) => option === item.code
              ) > -1
            }
          />
        ))}
      </div>
    </Grid>
  );
};
