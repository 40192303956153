import React from 'react';
import find from 'lodash/find';
import filter from 'lodash/filter';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import { FilterProps } from 'app/components/FilterCard';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { secondaryReporter } from 'app/components/FilterCard/MoreFilters/common/data';
import { Label } from 'app/components/FilterCard/MoreFilters/common/FilterSelectStyles';
import { FilterSelect } from 'app/components/FilterCard/MoreFilters/common/FilterSelect';
import { MoreFiltersCard } from 'app/components/FilterCard/MoreFilters/common/MoreFilterCard';

interface TransactionFilterProps extends FilterProps {
  filterOptionsDataLoading: any;
  loadFilterOptions: (arg0: string, arg1: boolean) => void;
}

export const TransactionFilter = (props: TransactionFilterProps) => {
  function handleCheckboxChange(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    const options = { ...props.selectedOptions };
    if (checked) {
      options.transactionhumanitarian = [
        ...options.transactionhumanitarian,
        event.target.value,
      ];
    } else {
      options.transactionhumanitarian = filter(
        options.transactionhumanitarian,
        (v: any) => v !== event.target.value
      );
    }
    props.setSelectedOptions(options);
  }

  function onChange(actionMeta: any, type: string) {
    const options = { ...props.selectedOptions };
    if (actionMeta.action === 'select-option') {
      options[type] = [...options[type], actionMeta.option.value];
    }
    if (
      actionMeta.action === 'remove-value' ||
      actionMeta.action === 'pop-value'
    ) {
      options[type] = filter(
        options[type],
        (v: any) => v !== actionMeta.removedValue.value
      );
    }
    if (actionMeta.action === 'deselect-option') {
      options[type] = filter(
        options[type],
        (v: any) => v !== actionMeta.option.value
      );
    }
    if (actionMeta.action === 'clear') {
      options[type] = [];
    }
    props.setSelectedOptions(options);
  }

  return (
    <MoreFiltersCard
      header="Transaction filter"
      selectedOptions={props.selectedOptions}
    >
      {/* -------------------------------------- */}
      {/* transaction flow type */}
      <Grid item xs={12} md={12} lg={12}>
        <Label>Transaction flow type</Label>
        <FilterSelect
          options={props.options.transactionflowtype.map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          placeholder="All"
          onMenuOpen={() => {
            props.loadFilterOptions('transactionflowtype', true);
          }}
          defaultValue={filter(props.options.transactionflowtype, (item: any) =>
            find(
              props.selectedFilters.transactionflowtype,
              (f: any) => f === item.code
            )
          ).map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          onChange={(value: any, actionMeta: any) => {
            onChange(actionMeta, 'transactionflowtype');
          }}
          isLoading={props.filterOptionsDataLoading.transactionflowtype}
        />
      </Grid>
      {/* -------------------------------------- */}
      {/* transaction tied status */}
      <Grid item xs={6} md={6} lg={6}>
        <Label>Transaction tied status</Label>
        <FilterSelect
          options={props.options.transactiontiedstatus.map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          placeholder="All"
          onMenuOpen={() => {
            props.loadFilterOptions('transactiontiedstatus', true);
          }}
          defaultValue={filter(
            props.options.transactiontiedstatus,
            (item: any) =>
              find(
                props.selectedFilters.transactiontiedstatus,
                (f: any) => f === item.code
              )
          ).map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          onChange={(value: any, actionMeta: any) => {
            onChange(actionMeta, 'transactiontiedstatus');
          }}
          isLoading={props.filterOptionsDataLoading.transactiontiedstatus}
        />
      </Grid>
      {/* -------------------------------------- */}
      {/* transaction humanitarian */}

      {/* todo: Need to fix these checkboxes inside an component */}
      <Grid item xs={6} md={6} lg={6}>
        <Label>Transaction humanitarian</Label>
        <Grid item>
          {secondaryReporter.map((item: any) => (
            <FormControlLabel
              key={item.label}
              css={`
                margin-left: 0;
              `}
              control={
                <Checkbox
                  color="default"
                  checked={find(
                    props.selectedOptions.transactionhumanitarian,
                    (f: any) => f === item.value
                  )}
                  value={item.value}
                  onChange={handleCheckboxChange}
                />
              }
              label={item.label}
            />
          ))}
        </Grid>
      </Grid>
      {/* -------------------------------------- */}
      {/* transaction type */}
      <Grid item xs={12} md={12} lg={12}>
        <Label>Transaction type</Label>
        <FilterSelect
          options={props.options.transactiontype.map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          placeholder="All"
          onMenuOpen={() => {
            props.loadFilterOptions('transactiontype', true);
          }}
          defaultValue={filter(props.options.transactiontype, (item: any) =>
            find(
              props.selectedFilters.transactiontype,
              (f: any) => f === item.code
            )
          ).map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          onChange={(value: any, actionMeta: any) => {
            onChange(actionMeta, 'transactiontype');
          }}
          isLoading={props.filterOptionsDataLoading.transactiontype}
        />
      </Grid>
      {/* -------------------------------------- */}
      {/* transaction value currency */}
      <Grid item xs={12} md={12} lg={12}>
        <Label>Transaction value currency</Label>
        <FilterSelect
          options={props.options.transactionvaluecurrency.map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          placeholder="All"
          onMenuOpen={() => {
            props.loadFilterOptions('transactionvaluecurrency', true);
          }}
          defaultValue={filter(
            props.options.transactionvaluecurrency,
            (item: any) =>
              find(
                props.selectedFilters.transactionvaluecurrency,
                (f: any) => f === item.code
              )
          ).map((item: any) => ({
            label: item.name,
            value: item.code,
          }))}
          onChange={(value: any, actionMeta: any) => {
            onChange(actionMeta, 'transactionvaluecurrency');
          }}
          isLoading={props.filterOptionsDataLoading.transactionvaluecurrency}
        />
      </Grid>
    </MoreFiltersCard>
  );
};
