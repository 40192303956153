import get from 'lodash/get';

// MAIN FILTERS
const mainfilters: string[] = [
  'countries',
  'regions',
  'budget',
  'sectors',
  'donors',
  'organisations',
  'publishers',
  'period',
  'activitystatus',
];

// MORE FILTERS
const activityOptions: string[] = [
  'activityscope',
  'documentlinkcategory',
  'hierachy',
  'humanitarianscopevocab',
  'humanitarian',
  'humanitarianscope',
  'iativersion',
  'otheridentifiertype',
  'tag',
];

const defaultOptions: string[] = [
  'defaultaidtype',
  'defaultaidtypevocabulary',
  'currency',
  'defaultfinancetype',
  'defaultflowtype',
  'language',
  'defaulttiedstatus',
];

const organisationOptions: string[] = [
  'transactionreceiverorg',
  'reportingorgtype',
  'collaborationtype',
  'secondaryreporter',
];

const sectorOptions: string[] = ['sectorvocabulary', 'policymarker'];

const transactionOptions: string[] = [
  'transactionflowtype',
  'transactiontiedstatus',
  'transactionhumanitarian',
  'transactiontype',
  'transactionvaluecurrency',
];

const moreFilters: string[] = activityOptions.concat(
  defaultOptions,
  organisationOptions,
  sectorOptions,
  transactionOptions
);

// Function used to check if a tab in either the main or more filters is an active tab.
export function tabHasFilterActive(
  label: string,
  selectedFilters: any
): boolean {
  const foundFilter = get(selectedFilters, label, []);
  let isActive;

  switch (label) {
    case 'activity':
      isActive = activityOptions.some((option: string, index) => {
        return get(selectedFilters, [option], []).length > 0;
      });
      break;
    case 'default':
      isActive = defaultOptions.some((option: string, index) => {
        return get(selectedFilters, [option], []).length > 0;
      });
      break;
    case 'organisation':
      isActive = organisationOptions.some((option: string, index) => {
        return get(selectedFilters, [option], []).length > 0;
      });
      break;
    case 'sector':
      isActive = sectorOptions.some((option: string, index) => {
        return get(selectedFilters, [option], []).length > 0;
      });
      break;
    case 'transaction':
      isActive = transactionOptions.some((option: string, index) => {
        return get(selectedFilters, [option], []).length > 0;
      });
      break;
    case 'locations':
      isActive =
        selectedFilters.countries.length > 0 ||
        selectedFilters.regions.length > 0;
      break;
    case 'organisations':
      isActive =
        selectedFilters.donors.length > 0 ||
        selectedFilters.organisations.length > 0 ||
        selectedFilters.publishers.length > 0;
      break;
    default:
      isActive = foundFilter.length > 0;
  }

  return isActive;
}

// Function used to check if the main filters has any active tab.
export function tabHasActiveMainFilter(selectedFilters: any): boolean {
  const keys = Object.keys(selectedFilters);
  return keys.some((key: string) => {
    return selectedFilters[key].length > 0 && mainfilters.includes(key);
  });
}

// Function used to check if the more filters has any active tab.
export function tabHasActiveMoreFilter(selectedFilters: any): boolean {
  const keys = Object.keys(selectedFilters);
  return keys.some((key: string) => {
    return selectedFilters[key].length > 0 && moreFilters.includes(key);
  });
}
