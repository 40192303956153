import React from 'react';

export interface FilterItemProps {
  label: string;
  disabled?: boolean;
  locked?: boolean;
}

export const FilterItem = (props: FilterItemProps) => (
  <div
    css={`
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-transform: capitalize;
      user-select: none;
      position: relative;
    `}
  >
    {props.label}
  </div>
);
