import React from 'react';
import {
  MUIDataTableOptions,
} from 'mui-datatables';

export const ActivitiesDataTableOptions: MUIDataTableOptions = {
  print: true,
  elevation: 0,
  search: false,
  filter: false,
  download: true,
  rowHover: false,
  pagination: true,
  viewColumns: true,
  responsive: 'standard',
  selectableRows: 'none',
  selectableRowsHeader: false,
};

export const CountriesDataTableOptions: MUIDataTableOptions = {
  print: true,
  elevation: 0,
  search: false,
  filter: false,
  download: true,
  rowHover: false,
  pagination: true,
  viewColumns: true,
  responsive: 'standard',
  selectableRows: 'none',
  selectableRowsHeader: false,
};

export const OrganisationsDataTableOptions: MUIDataTableOptions = {
  print: true,
  elevation: 0,
  search: false,
  filter: false,
  download: true,
  rowHover: false,
  pagination: true,
  viewColumns: true,
  responsive: 'standard',
  selectableRows: 'none',
  selectableRowsHeader: false,
};

export const DonorsDataTableOptions: MUIDataTableOptions = {
  print: true,
  elevation: 0,
  search: false,
  filter: false,
  download: true,
  rowHover: false,
  pagination: true,
  viewColumns: true,
  responsive: 'standard',
  selectableRows: 'none',
  selectableRowsHeader: false,
};


export const PublishersDataTableOptions: MUIDataTableOptions = {
  print: true,
  elevation: 0,
  search: false,
  filter: false,
  download: true,
  rowHover: false,
  pagination: true,
  viewColumns: true,
  responsive: 'standard',
  selectableRows: 'none',
  selectableRowsHeader: false,
};

