import React from 'react';
import { TabContentProps } from 'app/modules/search-results-module/data';
import { searchNavItems } from 'app/components/Filter/common/Search/data';
import { ActivitiesTableModule } from 'app/components/Charts/table/modules/activities';
import { CountriesTableModule } from 'app/components/Charts/table/modules/countries';
import { OrganisationsTableModule } from 'app/components/Charts/table/modules/organisations';
import { PublishersTableModule } from 'app/components/Charts/table/modules/publishers';
import { DonorsTableModule } from 'app/components/Charts/table/modules/donors';
import {
  ActivitiesDataTableOptions,
  CountriesDataTableOptions,
  DonorsDataTableOptions,
  OrganisationsDataTableOptions,
  PublishersDataTableOptions,
} from './TableOptions';

export function TabContent(props: TabContentProps) {
  return (
    <React.Fragment>
      <div
        style={{
          display: props.selected === searchNavItems[0] ? 'initial' : 'none',
        }}
      >
        <ActivitiesTableModule tableOptions={ActivitiesDataTableOptions} />
      </div>
      <div
        style={{
          display: props.selected === searchNavItems[1] ? 'initial' : 'none',
        }}
      >
        <CountriesTableModule tableOptions={CountriesDataTableOptions} />
      </div>
      <div
        style={{
          display: props.selected === searchNavItems[2] ? 'initial' : 'none',
        }}
      >
        <OrganisationsTableModule
          tableOptions={OrganisationsDataTableOptions}
        />
      </div>
      <div
        style={{
          display: props.selected === searchNavItems[3] ? 'initial' : 'none',
        }}
      >
        <PublishersTableModule tableOptions={PublishersDataTableOptions} />
      </div>
      <div
        style={{
          display: props.selected === searchNavItems[4] ? 'initial' : 'none',
        }}
      >
        <DonorsTableModule tableOptions={DonorsDataTableOptions} />
      </div>
    </React.Fragment>
  );
}
